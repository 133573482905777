import Calendar from "src/components/Icons/Calendar";
import {
  AlignItems,
  Color,
  Display,
  FireText,
  JustifyContent,
  Layout,
  TextType,
} from "src/ui";
import { useRecurringModel } from "../../hooks/use-recurring";
import { RecurringModel } from "src/gql/generated/client-cached.graphql";

interface Props {
  recurringModel?: RecurringModel;
}
export const SeriesRecurringText: React.FC<Props> = ({ recurringModel }) => {
  const recurring = useRecurringModel(recurringModel);

  if (!recurring) {
    return null;
  }

  return (
    <Layout display={Display.Flex} alignItems={AlignItems.Center}>
      <Layout margin={{ r: 4 }}>
        <Calendar />
      </Layout>
      <FireText type={TextType.Micro} color={Color.Alt}>
        {recurring?.repeat} &bull; {recurring?.time}
      </FireText>
    </Layout>
  );
};
