import { GetHorizontalFeedEntitiesQuery, GetVerticalFeedEntitiesQuery } from "../../gql/generated/client-fresh.graphql";

import { GetSeriesQuery, RecurringModel } from "../../gql/generated/client-cached.graphql";
import { Entitlements, EntityRole as EntityRoleEnum, EntityType, ShowPublishedState, ShowState } from "./enums";

// needed to avoid serialization errors
type Maybe<T> = T | null;

export type EntityOverrideData = {
  ulid: string;
  coverImageUrl: string;
  videoTrailerUrl: string;
  title: string;
  description: string;
};

export type Emoji = {
  ulid: string;
  staticUrl: string;
  animatedUrl: Maybe<string>;
  animated: boolean;
  name: string;
};

export type User = {
  uid: string;
  slug: string;
  displayName: string;
  avatarUrl: string;
  agoraId: Maybe<number>;
  bio: string;
};

export type EntityRole = {
  role: EntityRoleEnum;
  user: User;
};

export type Series = {
  coverImageUrl: Maybe<string>;
  displayName: string;
  slug: string;
  roles: EntityRole[];
  description: string;
  recurringModel: Maybe<RecurringModel>;
  format: Maybe<NonNullable<NonNullable<GetSeriesQuery["series_by_pk"]>["format"]>>;
  categoryUlids: Maybe<NonNullable<NonNullable<GetSeriesQuery["series_by_pk"]>["category_ulids"]>>;
  networks: Network[];
  contentRestriction: Entitlements;
};

export enum ChatMembershipStatus {
  ACTIVE = "active",
  LEAVING = "leaving",

  KICKED = "kicked",
  ENDED = "ended",
  LEFT = "left"
}

export type ChatMembership = {
  status: ChatMembershipStatus;
  role: EntityRoleEnum;
  muted: boolean;
  onStage: boolean;
  sharingVideoAt: Maybe<string>;
  user: User;
  emoji: Maybe<Emoji>;
  chatUlid: string;
};

export type Show = {
  ulid: string;
  title: string;
  description: string;
  scheduledAt: Maybe<string>;
  startedAt: Maybe<string>;
  coverImageUrl: Maybe<string>;
  state: ShowState;
  audioTrailerUrl: Maybe<string>;
  videoTrailerUrl: Maybe<string>;
  publishedState: ShowPublishedState;
  roles: EntityRole[];
  series: Series[];
  creator: User;
  externalShowId: Maybe<string>;
  networks: Network[];
  slug: string;
  contentRestriction: Entitlements;
};

export type Network = {
  description: string;
  imageUrl: string;
  coverImageUrl: string;
  learnMoreUrl: Maybe<string>;
  name: string;
  orderPriority: number;
  slug: string;
};

export type Entity = {
  entityType: EntityType;
  entity: Show;
};

export type SharedImageProps = {
  className: string;
  fontSizeClassName: string;
  /**
   * set position classes
   */
  networkImageClassName?: string;
  /**
   * size in %
   */
  networkImageSize?: number;
  showLock?: boolean;
};

type AbstractFeedEntity = {
  ulid: string;
  audioUrl: string;
  landscapePlaybackUrl: string;
  portraitPlaybackUrl: string;
  networkSlug: Maybe<string>;
};

export type ClipFeedEntity = AbstractFeedEntity & {
  type: "clip";
  startSeconds: number;
  endSeconds: number;
  chatUlid: string;
  hasVideo: boolean;
};

type LiveShowFeedEntity = AbstractFeedEntity & {
  type: "chat";
};

export type VideoFeedEntity = ClipFeedEntity | LiveShowFeedEntity;

type Item = NonNullable<GetHorizontalFeedEntitiesQuery["feedGetRelatedClips"]>[number] | NonNullable<GetVerticalFeedEntitiesQuery["feedGetClips"]>[number];

export const mapQueryItemToFeedEntity = (item: Item): VideoFeedEntity | null => {
  if (!item?.type) {
    return null;
  }

  const networks = item?.networks?.map((network) => (network ? network.slug : "")).filter(Boolean) || [];

  const hasNetwork = networks.length > 0;

  const networkSlug = hasNetwork ? networks[0] : null;

  if (item.type === "clip" && item.clip && item.hls_links) {
    const landscapePlaybackUrl = item.hls_links.landscape_cropped || item.hls_links.landscape;

    const portraitPlaybackUrl = item.hls_links.portrait_cropped || item.hls_links.portrait;

    return {
      type: item.type,
      chatUlid: item.clip.chat_ulid,
      startSeconds: item.clip.start_ms / 1000,
      endSeconds: item.clip.end_ms / 1000,
      ulid: item.clip.ulid,
      networkSlug,
      audioUrl: item.clip.audio_url,
      landscapePlaybackUrl,
      portraitPlaybackUrl: portraitPlaybackUrl || "",
      hasVideo: item.clip.has_video
    };
  }

  if (item.type === "chat" && item.chat && item.hls_links) {
    const portraitPlaybackUrl = item.hls_links.portrait_cropped || item.hls_links.portrait;

    const landscapePlaybackUrl = item.hls_links.landscape_cropped || item.hls_links.landscape;

    if (!landscapePlaybackUrl && !portraitPlaybackUrl) {
      return null;
    }

    return {
      type: item.type,
      audioUrl: "",
      landscapePlaybackUrl,
      networkSlug,
      portraitPlaybackUrl,
      ulid: item.chat.ulid
    };
  }

  return null;
};

export type FeedMessage = {
  id: string;
  date: Date;
  uid: string;
  role: string;
  emojiUrl: string;
  emojiName: string | undefined;
  avatar: string;
  effect: string | undefined;
  message: string;
  speaker: boolean;
  slug: string;
  displayName: string;
};

export type FirestoreFeedMessage = {
  EmojiUrl: string;
  Message: string;
  Timestamp: { seconds: number };
  User: {
    AvatarURL: string; // for backwards compatibility
    AvatarURLV2: string;
    DisplayName: string;
    Role: string;
    Speaker: boolean;
    UID: string;
    Username: string;
  };
  EmojiName?: string;
  Effect?: string;
  EmojiImageUrl?: string;
  EmojiImageUrlV2?: string;
  OrigMessage?: string;
  Context?: string;
};
