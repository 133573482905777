import { Layout, FireImage, BorderRadius, Overflow } from "src/ui";

export enum AvatarSize {
  Small = "small",
  Medium = "medium",
  Large = "large",
}

export interface AvatarProps {
  size?: AvatarSize;
  imageSrc: string;
}

export const Avatar: React.FC<AvatarProps> = ({
  imageSrc,
  size = AvatarSize.Small,
}) => {
  let classSize = "w-20 h-20";
  switch (size) {
    case AvatarSize.Medium:
      classSize = "w-30 h-30";
      break;
    case AvatarSize.Large:
      classSize = "w-72 h-72";
      break;
  }

  return (
    <Layout
      className={`fs-avatar ${classSize}`}
      borderRadius={BorderRadius.Full}
      overflow={Overflow.Hidden}
    >
      <FireImage src={imageSrc} fill />
    </Layout>
  );
};
