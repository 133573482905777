import { Layout } from "src/ui";
import { useUserSeries } from "./use-series";
import { UserSeriesItem } from "./Item";
import { ProfileRail } from "src/screens/Profile/components/Rail";
import { useIntl } from "react-intl";

interface Props {
  slug: string;
}

export const UserSeries: React.FC<Props> = ({ slug }) => {
  const { formatMessage } = useIntl();
  const {
    items: series,
    hasMore,
    isFetchingNextPage,
    fetchNextPage,
    isFetched,
  } = useUserSeries(slug);

  if (isFetched && !series.length) {
    return null;
  }

  return (
    <Layout margin={{ y: 10 }}>
      <ProfileRail
        title={formatMessage({ defaultMessage: "Series" })}
        canLoadMore={!!series.length && hasMore && !isFetchingNextPage}
        onLoadMore={fetchNextPage}
      >
        {!series.length && <UserSeriesItem series={null} loading />}
        {series.map((s) => (
          <UserSeriesItem key={`UserSeriesRailItem-${s.slug}`} series={s} />
        ))}
      </ProfileRail>
    </Layout>
  );
};
