import { LayoutChangeEvent } from "react-native";
import ScalableImage from "src/components/ScalableImage";
import {
  BorderRadius,
  FireImage,
  Interactable,
  Layout,
  Overflow,
} from "src/ui";

interface Props {
  image: string;
  current?: boolean;
  onFocus?: () => void;
  onLayout?: (event: LayoutChangeEvent) => void;
}

const EpisodeTile: React.FC<Props> = ({
  image,
  current,
  onFocus,
  onLayout,
}) => {
  const dim = current ? "w-[17rem] h-[25.5rem]" : "w-[15rem] h-[22.5rem]";

  return (
    <Layout padding={{ t: 5, r: 5 }} onLayout={onLayout}>
      <Interactable borderRadius={BorderRadius.XXXL} onFocus={onFocus}>
        <Layout
          overflow={Overflow.Hidden}
          borderRadius={BorderRadius.XXXL}
          className={dim}
        >
          <FireImage src={image} fill />
        </Layout>
      </Interactable>
    </Layout>
  );
};

export default EpisodeTile;
