import { Portal, usePortal } from "@gorhom/portal";
import { atom, useAtom } from "jotai";
import { JSXElementConstructor, PropsWithChildren, ReactElement } from "react";
import useBackKey from "src/hooks/useBackKey";
import {
  AlignItems,
  Display,
  JustifyContent,
  Layout,
  ModalPortalHostID,
  Position,
} from "src/ui";

interface Props {
  id: string;
}

const Modal: React.FC<PropsWithChildren<Props>> = ({ children, id }) => {
  const { removeModal } = useModal();

  useBackKey(() => {
    removeModal(id);
  });

  return (
    <Portal name={ModalPortalHostID}>
      <Layout
        display={Display.Flex}
        position={Position.Fixed}
        alignItems={AlignItems.Center}
        justifyContent={JustifyContent.Center}
        attachTop
        attachBottom
        attachLeft
        attachRight
        className="modal-root__backdrop bg-black/60"
      >
        {children}
      </Layout>
    </Portal>
  );
};

const modalOpen = atom(false);

export function useModal() {
  const [open, setOpen] = useAtom(modalOpen);
  const { addPortal, removePortal } = usePortal(ModalPortalHostID);

  return {
    modalActive: open,
    showModal: (node: ReactElement<{}, JSXElementConstructor<any>>) => {
      addPortal(node.type.name, <Modal id={node.type.name}>{node}</Modal>);
      setOpen(true);
    },
    removeModal: (name: string) => {
      removePortal(name);
      setOpen(false);
    },
  };
}
