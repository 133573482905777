import React from "react";
import { View, Text, Image } from "../../../components/Tw";
import { mapQueryToShow } from "../../../atoms/content-feed/mappers";
import { VideoFeedEntity } from "../../../atoms/content-feed/types";
import { useGetChatQuery } from "../../../gql/generated/client-cached.graphql";
import { FireText } from "src/ui";

type Props = {
  videoEntity: VideoFeedEntity;
};

export const VideoEntityHeader = ({ videoEntity }: Props) => {
  const chatUlid =
    videoEntity.type === "chat" ? videoEntity.ulid : videoEntity.chatUlid;

  const { data: show } = useGetChatQuery(
    { ulid: chatUlid, id: chatUlid },
    { select: mapQueryToShow }
  );

  if (!show) return null;

  return (
    <View className="flex-row items-center space-x-3 mt-20">
      {/* <ShowImage show={show} className="h-10 w-10 lg:h-14 lg:w-14" fontSizeClassName="text-[40px] lg:text-[56px]" borderRadius="rounded-lg lg:rounded-xl" /> */}
      <Image
        className="w-14 h-14 rounded-xl"
        source={{ uri: show.coverImageUrl! }}
      />
      <View className="text-lg">
        {show.series.length > 0 && (
          <View className="text-white/50">
            <FireText>
              {show.series.map((series) => series.displayName).join(", ")}
            </FireText>
          </View>
        )}
        <Text className="text-white font-bold text-xl">{show.title}</Text>
      </View>
    </View>
  );
};
