import { HighlightCard } from "../../../Profile/components/HighlightsRail/card";
import { useNetworkUpcomingShows } from "./use-upcoming-shows";
import { ProfileRail } from "src/screens/Profile/components/Rail";
import { useIntl } from "react-intl";

interface Props {
  slug: string;
}

const NetworkUpcomingShows: React.FC<Props> = ({ slug }) => {
  const { formatMessage } = useIntl();
  const {
    items: shows,
    hasMore,
    isFetchingNextPage,
    fetchNextPage,
    isFetched,
  } = useNetworkUpcomingShows(slug);

  if (isFetched && !shows.length) {
    return null;
  }

  return (
    <ProfileRail
      title={formatMessage({
        defaultMessage: "Up Next",
      })}
      canLoadMore={!!shows.length && hasMore && !isFetchingNextPage}
      onLoadMore={fetchNextPage}
    >
      {!shows.length && <HighlightCard loading />}
      {shows.map((show) => (
        <HighlightCard key={show.ulid} show={show} />
      ))}
    </ProfileRail>
  );
};

export default NetworkUpcomingShows;
