import React, { useRef } from "react";
import { ContentFeedHorizontalDataProvider } from "../components/ContentFeedHorizontalDataProvider";
import { VideoFeedEntity } from "../../../atoms/content-feed/types";
import { FlatList, ViewToken } from "react-native";
import { activeIndexesAtom } from "../../../atoms/content-feed/activeIndexes";
import { useAtom } from "jotai";
import { ITEM_WIDTH } from "../constants";
import { UseFocusableConfig } from "@noriginmedia/norigin-spatial-navigation";
import { RelatedClipItem } from "./RelatedClipItem";
import FocusGroup from "src/ui/components/FocusGroup";

type RelatedClipSliderProps = {
  active: boolean;
  activeIndex: number;
  verticalEntity: VideoFeedEntity;
};

type Props = RelatedClipSliderProps & UseFocusableConfig;

const RelatedClipSlider: React.FC<Props> = ({
  active,
  activeIndex,
  verticalEntity,
  focusKey,
  onFocus,
}) => {
  // const setActiveIndexes = useSetAtom(activeIndexesAtom);
  const sliderRef = useRef<FlatList>(null);
  const [activeIndexes, setActiveIndexes] = useAtom(activeIndexesAtom);

  const getItemLayout = (data, index) => ({
    length: ITEM_WIDTH,
    offset: ITEM_WIDTH * index,
    index,
  });

  const handleViewableItemsChanged = React.useCallback(
    ({
      viewableItems,
      changed,
    }: {
      viewableItems: Array<ViewToken>;
      changed: Array<ViewToken>;
    }) => {
      setActiveIndexes((prev) => {
        if (
          viewableItems.length === 1 &&
          viewableItems[0].index !== prev.horizontal
        ) {
          return {
            ...prev,
            horizontal: viewableItems[0].index!,
            item: viewableItems[0].item,
          };
        }

        return prev;
      });
    },
    []
  );

  const renderItem = ({
    item,
    index,
  }: {
    item: VideoFeedEntity;
    index: number;
  }) => {
    return (
      <RelatedClipItem
        item={item}
        currentIndex={index}
        activeIndexes={activeIndexes}
        sliderRef={sliderRef.current}
      />
    );
  };

  // console.log("RelatedClipSlider");
  return (
    <ContentFeedHorizontalDataProvider
      active={active}
      activeIndex={activeIndex}
      verticalEntity={verticalEntity}
    >
      {({ entities }) => {
        return (
          <FocusGroup
            id={`RelatedClipSlider_${verticalEntity.ulid}`}
            onFocus={onFocus}
            focusKey={focusKey}
            focusOnMount={active}
          >
            <FlatList
              ref={sliderRef}
              horizontal
              showsHorizontalScrollIndicator={false}
              decelerationRate="fast"
              windowSize={2}
              initialNumToRender={2}
              onEndReachedThreshold={1}
              onViewableItemsChanged={handleViewableItemsChanged}
              data={entities}
              getItemLayout={getItemLayout}
              renderItem={renderItem}
            />
          </FocusGroup>
        );
      }}
    </ContentFeedHorizontalDataProvider>
  );
};

export default RelatedClipSlider;
