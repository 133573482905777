// copied from old webapp
// defined here: https://github.com/firesidecollectives/fireside-backend/pull/1581/files
export enum ShowState {
  SCHEDULED = "scheduled",
  PLANNED = "planned", // scheduled without time
  STARTED = "started", // live
  REPLAYABLE = "replayable",
  ENDED = "ended",
  CANCELLED = "cancelled",
  INVALID = "invalid" // state is NULL and it's NOT scheduled NOR started
}

export enum EntityRole {
  HOST = "host",
  MODERATOR = "moderator",
  GUEST = "guest",
  NETWORK_HOST = "network_host"
}

export enum ShowPublishedState {
  HIDDEN = "hidden", // TODO: remove from rest of codebase
  PUBLISHED = "published"
}

export enum UserRole {
  HOST = "host",
  ATTENDEE = "attendee",
  NEEDS_FORM = "needs_form",
  WAITLISTED = "waitlisted",
  ADMIN = "admin",
  GENERAL_ADMISSION = "general-admission",
  QUEUED = "queued"
}

export enum EntityType {
  SERIES = "series",
  SHOW = "show",
  USER = "user",
  NETWORK = "network"
}

export const entityTypes = Object.values(EntityType);

export enum Entitlements {
  MEMBERSHIP = "membership",
  FANDOM = "fandom",
  PUBLIC = "public"
}
