import { useSetAtom } from "jotai";
import { nanoid } from "nanoid/non-secure";
import { useEffect } from "react";
import { useSession } from "src/core/auth/use-session";

const anonymousIdKey = "generated-anonymous-id";
const prefix = "anon";

/**
 * also used as device id
 */
export const useSetAnonymousId = () => {
  const { setDeviceID } = useSession();
  useEffect(() => {
    let savedAnonymousId = `${prefix}-${nanoid()}`;
    setDeviceID(savedAnonymousId);
  }, [setDeviceID]);
};
