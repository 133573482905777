import { DocumentNode, print } from "graphql";

// import { FsError } from "../exception/fs-error";

export const getQueryName = (doc: DocumentNode) => {
  const queryDefinition = doc.definitions[0];
  let queryName = "";

  if (queryDefinition.kind === "OperationDefinition" && queryDefinition.operation === "query") {
    queryName = queryDefinition.name?.value || "";
  }

  if (!queryName) {
    throw new Error(`couldn't find query name ${print(doc)}`);
  }

  return queryName;
};
