import { Path, Svg } from "react-native-svg";

interface Props {}

export const ProfileStar: React.FC<Props> = () => {
  return (
    <Svg width="22" height="28" viewBox="0 0 16 20" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.00049 1.22266C9.00049 0.670371 8.55278 0.222656 8.00049 0.222656C7.44821 0.222656 7.00049 0.670371 7.00049 1.22266V2.55599C7.00049 3.10827 7.44821 3.55599 8.00049 3.55599C8.55278 3.55599 9.00049 3.10827 9.00049 2.55599V1.22266ZM2.04059 4.51553C1.65005 4.12502 1.01689 4.12503 0.626372 4.51557C0.235857 4.9061 0.235873 5.53927 0.626407 5.92978L1.51534 6.81867C1.90587 7.20918 2.53904 7.20917 2.92955 6.81863C3.32007 6.4281 3.32005 5.79494 2.92952 5.40442L2.04059 4.51553ZM15.3746 5.92978C15.7651 5.53927 15.7651 4.9061 15.3746 4.51557C14.9841 4.12503 14.3509 4.12502 13.9604 4.51553L13.0715 5.40442C12.6809 5.79494 12.6809 6.4281 13.0714 6.81863C13.4619 7.20917 14.0951 7.20918 14.4856 6.81867L15.3746 5.92978ZM9.49073 5.96536C8.8762 4.73812 7.12472 4.73813 6.51019 5.96536L5.14294 8.6958L2.09828 9.13448C0.724324 9.33245 0.180337 11.0249 1.18167 11.9864L3.36832 14.0858L2.85095 17.0594C2.6146 18.4178 4.03591 19.4593 5.26001 18.8247L8.00046 17.4041L10.7409 18.8247C11.965 19.4593 13.3863 18.4178 13.15 17.0594L12.6326 14.0858L14.8193 11.9864C15.8206 11.0249 15.2766 9.33244 13.9026 9.13448L10.858 8.6958L9.49073 5.96536ZM6.85324 9.74711L8.00046 7.45607L9.14769 9.74711C9.39209 10.2352 9.86 10.5727 10.4003 10.6505L12.9415 11.0166L11.1195 12.766C10.7196 13.15 10.5368 13.7077 10.6318 14.2539L11.0643 16.7396L8.76752 15.5489C8.28654 15.2996 7.71438 15.2996 7.23341 15.5489L4.93664 16.7396L5.36912 14.2539C5.46416 13.7077 5.28135 13.15 4.88142 12.766L3.05945 11.0166L5.60065 10.6505C6.14092 10.5727 6.60883 10.2352 6.85324 9.74711Z"
        fill="white"
      />
    </Svg>
  );
};
