import { GraphQLClient } from "graphql-request";
import React, { createContext, useContext, useEffect } from "react";
import { useSession } from "src/core/auth/use-session";

// import { authHeadersAtom } from "../auth/headers";

// const gqlClient = new GraphQLClient(process.env.NEXT_PUBLIC_GQL_QUERY_ENDPOINT!);

const gqlClient = new GraphQLClient(
  "https://graph.firesidechat.com/v1/graphql"
);

const ClientContext = createContext(gqlClient);

export const useClient = () => useContext(ClientContext);

export const ClientProvider = ({ children }) => {
  const { headers, user } = useSession();

  useEffect(() => {
    gqlClient.setHeaders(headers || undefined);
  }, [headers, user]);

  return (
    <ClientContext.Provider value={gqlClient}>
      {children}
    </ClientContext.Provider>
  );
};
