import { Fragment } from "react";
import { ActivityIndicator } from "react-native";
import { Show } from "src/atoms/content-feed/types";
import { ShowCard } from "src/screens/Show/components/Card";
import { ShowScheduledTime } from "src/screens/Show/components/ScheduledTime";
import { useNavigateToShow } from "src/screens/Show/utils/use-url-path";
import {
  AlignItems,
  BorderRadius,
  Display,
  Interactable,
  JustifyContent,
  Layout,
  useRailContext,
  FlexDirection,
} from "src/ui";

interface Props {
  show?: Show;
  loading?: boolean;
}

export const HighlightCard: React.FC<Props> = ({ show, loading }) => {
  const { sliderRef, itemIndex } = useRailContext();
  const navigate = useNavigateToShow();

  if (!show || loading) {
    return (
      <Fragment>
        <Layout margin={{ b: 5 }} alignItems={AlignItems.Center}>
          <ActivityIndicator />
        </Layout>
        <ShowCard show={null} loading />
      </Fragment>
    );
  }

  return (
    <Layout
      className="highlight-card__container"
      display={Display.Flex}
      flexDirection={FlexDirection.Column}
      justifyContent={JustifyContent.End}
      fullHeight
    >
      {show.scheduledAt && (
        <Layout margin={{ b: 5 }} alignItems={AlignItems.Center}>
          <ShowScheduledTime time={show.scheduledAt} />
        </Layout>
      )}
      <Interactable
        borderRadius={BorderRadius.XXXL}
        onFocus={() => {
          if (sliderRef) {
            sliderRef.scrollToIndex({
              animated: true,
              index: itemIndex,
            });
          }
        }}
        onEnterPress={navigate(show)}
      >
        <ShowCard show={show} />
      </Interactable>
    </Layout>
  );
};
