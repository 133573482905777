import { ProfileRail } from "src/screens/Profile/components/Rail";
import { ShowCard } from "src/screens/Show/components/Card";
import { useAppearedIn } from "./use-appeared-in";
import { BorderRadius, Interactable, Layout } from "src/ui";
import { useNavigateToShow } from "src/screens/Show/utils/use-url-path";
import { useIntl } from "react-intl";

interface Props {
  uid: string;
}

export const UserAppearedIn: React.FC<Props> = ({ uid }) => {
  const { formatMessage } = useIntl();
  const {
    items: shows,
    hasMore,
    isFetchingNextPage,
    fetchNextPage,
    isFetched,
  } = useAppearedIn(uid);
  const navigate = useNavigateToShow();

  if (isFetched && !shows.length) {
    return null;
  }

  return (
    <Layout margin={{ y: 10 }}>
      <ProfileRail
        title={formatMessage({ defaultMessage: "Guest Appearances" })}
        onLoadMore={fetchNextPage}
        canLoadMore={!!shows.length && hasMore && !isFetchingNextPage}
      >
        {shows.map((show) => (
          <Interactable
            key={`AppearedIn_${show.slug}`}
            borderRadius={BorderRadius.XXXL}
            onEnterPress={navigate(show)}
          >
            <ShowCard show={show} />;
          </Interactable>
        ))}
      </ProfileRail>
    </Layout>
  );
};
