import { Series } from "src/atoms/content-feed/types";
import { useNavigate } from "src/hooks/navigation";
import { SeriesCard } from "src/screens/Series/components/Card";
import { BorderRadius, Interactable, useRailContext } from "src/ui";

interface Props {
  series: Series | null;
  loading?: boolean;
}

export const UserSeriesItem: React.FC<Props> = ({ series, loading }) => {
  const navigate = useNavigate();
  const { sliderRef, itemIndex } = useRailContext();
  return (
    <Interactable
      key={`UserSeries-${series?.slug}`}
      borderRadius={BorderRadius.XXXL}
      onFocus={() => {
        if (sliderRef) {
          sliderRef.scrollToIndex({
            animated: true,
            index: itemIndex,
          });
        }
      }}
      onEnterPress={() => {
        navigate(`/${series?.slug}`);
      }}
    >
      <SeriesCard series={series} loading={loading} />
    </Interactable>
  );
};
