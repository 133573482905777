import Svg, { Path, G, Defs, Rect, ClipPath } from "react-native-svg";
import { memo } from "react";

const NotificationChecked: React.FC = () => {
  return (
    <Svg width="21" height="20" viewBox="0 0 21 20" fill="none">
      <G clipPath="url(#clip0_929_14405)">
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.0014 0.666016C8.41007 0.666016 6.88389 1.29814 5.75862 2.42336C4.63335 3.54857 4.00118 5.0747 4.00118 6.66602C4.00118 9.43533 3.40908 11.1423 2.87323 12.1246C2.60392 12.6184 2.34333 12.9393 2.16576 13.1267C2.07672 13.2207 2.00786 13.2818 1.96832 13.3148C1.949 13.3309 1.93665 13.3403 1.93231 13.3435C1.57555 13.5901 1.41791 14.0395 1.54396 14.4558C1.67165 14.8776 2.06038 15.166 2.50105 15.166H17.5018C17.9425 15.166 18.3312 14.8776 18.4589 14.4558C18.5849 14.0395 18.4273 13.5901 18.0705 13.3435C18.0662 13.3403 18.0538 13.3309 18.0345 13.3148C17.995 13.2818 17.9261 13.2207 17.8371 13.1267C17.6595 12.9393 17.3989 12.6184 17.1296 12.1246C16.9131 11.7278 16.6875 11.2126 16.4954 10.5569C16.0317 10.7351 15.528 10.8327 15.0016 10.8327C14.8276 10.8327 14.656 10.822 14.4876 10.8013C14.733 11.733 15.0476 12.4843 15.3738 13.0824C15.3892 13.1106 15.4047 13.1385 15.4201 13.166H4.58275C4.59818 13.1385 4.6136 13.1106 4.629 13.0824C5.34321 11.7731 6.00118 9.73003 6.00118 6.66602C6.00118 5.60517 6.42262 4.58776 7.1728 3.83761C7.92299 3.08745 8.94047 2.66602 10.0014 2.66602C10.8567 2.66602 11.6838 2.93991 12.3653 3.43912C12.9281 2.97885 13.6135 2.66288 14.365 2.54767C14.3254 2.50571 14.2851 2.46427 14.2442 2.42336C13.1189 1.29814 11.5928 0.666016 10.0014 0.666016ZM1.93608 13.3409L1.93242 13.3435C1.93118 13.3443 1.93057 13.3448 1.93057 13.3448L1.9306 13.3448L1.93201 13.3437L1.93231 13.3435L1.93608 13.3409ZM1.93608 13.3409L1.93765 13.3398L1.94157 13.3372L1.93608 13.3409Z"
          fill="white"
        />
        <Path
          d="M11.443 17.5C11.2965 17.7526 11.0862 17.9622 10.8332 18.1079C10.5802 18.2537 10.2933 18.3304 10.0013 18.3304C9.70931 18.3304 9.42244 18.2537 9.16941 18.1079C8.91639 17.9622 8.70609 17.7526 8.55957 17.5"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M18.9604 2.5L13.1909 8.33333L10.21 5.61035"
          stroke="white"
          strokeWidth="2.33333"
        />
      </G>
      <Defs>
        <ClipPath id="clip0_929_14405">
          <Rect
            width="20.001"
            height="20"
            fill="white"
            transform="translate(0.000976562)"
          />
        </ClipPath>
      </Defs>
    </Svg>
  );
};

const Memo = memo(NotificationChecked);
export default Memo;
