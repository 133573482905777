import {
  ForwardRefExoticComponent,
  ForwardRefRenderFunction,
  HTMLAttributes,
  PropsWithChildren,
  forwardRef,
} from "react";
import { View, ViewProps } from "react-native";
import {
  AlignItems,
  Background,
  BorderRadius,
  Display,
  FlexDirection,
  JustifyContent,
  Opacity,
  Overflow,
  Position,
  Spacing,
} from "src/ui/models";
import classNames from "classnames";

export interface LayoutProps {
  name?: string;
  display?: Display;
  background?: Background;
  grow?: boolean;
  shrink?: boolean;
  margin?: Spacing;
  padding?: Spacing;
  flexDirection?: FlexDirection;
  flex?: number;
  position?: Position;
  attachTop?: boolean;
  attachBottom?: boolean;
  attachLeft?: boolean;
  attachRight?: boolean;
  fullWidth?: boolean;
  fullHeight?: boolean;
  borderRadius?: BorderRadius;
  alignItems?: AlignItems;
  justifyContent?: JustifyContent;
  opacity?: Opacity;
  overflow?: Overflow;
  className?: string;
  ref?: any;
}

type Props = LayoutProps & ViewProps;

function getSpacing(type: "m" | "p", spacing: Spacing) {
  const obj = {};
  if (typeof spacing === "number") {
    obj[`${type}-${spacing}`] = true;
  }

  if (typeof spacing === "object") {
    obj[`${type}x-${spacing.x}`] = !!spacing.x;
    obj[`${type}y-${spacing.y}`] = !!spacing.y;

    obj[`${type}t-${spacing.t}`] = !!spacing.t;
    obj[`${type}b-${spacing.b}`] = !!spacing.b;
    obj[`${type}l-${spacing.l}`] = !!spacing.l;
    obj[`${type}r-${spacing.r}`] = !!spacing.r;
  }

  return obj;
}

export const Layout: ForwardRefExoticComponent<PropsWithChildren<Props>> =
  forwardRef(
    (
      {
        children,
        name = "",
        display = "",
        background = "",
        grow,
        shrink,
        margin,
        padding,
        flexDirection,
        position = "",
        attachBottom,
        attachLeft,
        attachRight,
        attachTop,
        fullWidth,
        fullHeight,
        borderRadius = "",
        alignItems = "",
        justifyContent = "",
        opacity,
        overflow,
        flex,
        ...htmlProps
      },
      ref
    ) => {
      const margins = margin ? getSpacing("m", margin) : {};
      const paddings = padding ? getSpacing("p", padding) : {};

      const classes = classNames({
        [name]: true,
        [display]: true,
        grow: !!grow,
        shrink: !!shrink,
        [background]: !!background && !opacity,
        [`${background}/${opacity}`]: !!background && !!opacity,
        [flexDirection || "flex-row"]: display === Display.Flex,
        [`flex-${flex ?? 0}`]: display === Display.Flex,
        [position]: !!position,
        ["top-0"]: attachTop,
        ["bottom-0"]: attachBottom,
        ["left-0"]: attachLeft,
        ["right-0"]: attachRight,
        ["w-full"]: fullWidth,
        ["h-full"]: fullHeight,
        [`rounded-${borderRadius}`]: !!borderRadius,
        [`rounded-sm`]: borderRadius === BorderRadius.Small,
        [`rounded-md`]: borderRadius === BorderRadius.Medium,
        [`rounded-lg`]: borderRadius === BorderRadius.Large,
        [`rounded-xl`]: borderRadius === BorderRadius.XL,
        [`rounded-3xl`]: borderRadius === BorderRadius.XXXL,
        [`rounded-full`]: borderRadius === BorderRadius.Full,
        [`justify-center`]: justifyContent === JustifyContent.Center,
        [`justify-start`]: justifyContent === JustifyContent.Start,
        [`justify-end`]: justifyContent === JustifyContent.End,
        [`justify-between`]: justifyContent === JustifyContent.Between,
        ["items-start"]: alignItems === AlignItems.Start,
        ["items-end"]: alignItems === AlignItems.End,
        ["items-center"]: alignItems === AlignItems.Center,
        ["items-stretch"]: alignItems === AlignItems.Stretch,
        ["items-between"]: alignItems === AlignItems.Between,
        [overflow || ""]: !!overflow,

        ...margins,
        ...paddings,
      });

      const { className, ...props } = htmlProps;

      return (
        <View ref={ref} className={`${classes} ${className || ""}`} {...props}>
          {children}
        </View>
      );
    }
  );
