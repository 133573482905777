import { View } from "react-native";
import { Layout, Position, FireImage, Display } from "src/ui";

interface Props {
  imageSrc: string;
}

export const ProfileBackdrop: React.FC<Props> = ({ imageSrc }) => {
  return (
    <Layout
      position={Position.Absolute}
      attachLeft
      attachTop
      fullWidth
      display={Display.Flex}
      className="h-screen"
    >
      <FireImage src={imageSrc} fillWidth />
      <View className="absolute top-0 left-0 h-full w-full bg-gradient-to-b from-transparent via-40% via-[#111]/0 to-80% to-[#111]"></View>
      <View className="absolute top-[50vh] left-0 h-[50vh] w-full bg-gradient-to-b from-transparent to-[#111]"></View>
    </Layout>
  );
};
