import React, { useEffect, useRef, useState } from "react";
import { View, ImageBackground, StyleSheet } from "react-native";
import { Button, Menu, Provider, Text, TextInput } from "react-native-paper";
import { BACKGROUND } from "../../config";
import { auth } from "../../lib/firebase-fireside";
import Icon from "react-native-vector-icons/MaterialCommunityIcons";
import plainAxios from "axios";
import { getList } from "country-list-with-dial-code-and-flag";
import {
  ConfirmationResult,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  innerContainer: {
    width: "100%",
    padding: 10,
    maxWidth: 400,
  },
  titleContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 40,
  },
  title: {
    marginLeft: 8,
    fontSize: 24,
  },
  mb: {
    marginBottom: 20,
  },
  otpInput: {
    marginBottom: 20,
    color: "#ffffff",
  },
  phoneInput: {
    marginBottom: 20,
    paddingLeft: 80,
  },
  text: {
    color: "#FFFFFF",
  },
});

const textInputTheme = {
  colors: {
    placeholder: "gray",
    text: "white",
    primary: "white",
    background: "transparent",
  },
};
const PhoneInput = "PHONE_INPUT";
const VerifyOtp = "VERIFY_OTP";
const countryList = getList();

const ScreenLinkTv = () => {
  const [dialCode, setDialCode] = useState("+1");
  const [phone, setPhone] = useState("");
  const [otp, setOtp] = useState("");
  const [step, setStep] = useState(PhoneInput);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const resultRef = useRef<ConfirmationResult>();

  const url = new URL(window.location.href);
  const code = url.searchParams.get("code");

  const handleOpenMenu = () => setVisible(true);

  const handleCloseMenu = () => setVisible(false);

  const handleMenuItemPress = (dialCode) => {
    setDialCode(dialCode);
    handleCloseMenu();
  };

  useEffect(() => {
    document.addEventListener("click", handleCloseMenu);
    return () => document.removeEventListener("click", handleCloseMenu);
  }, []);

  const signIn = async () => {
    if (!phone) return;

    try {
      setLoading(true);
      const result = await signInWithPhoneNumber(
        auth,
        dialCode + phone,
        new RecaptchaVerifier("recaptcha", { size: "invisible" }, auth)
      );
      resultRef.current = result;
      setStep(VerifyOtp);
    } catch (err) {
      console.log(err);
      alert("Verify failed, please reload and try again!");
    } finally {
      setLoading(false);
    }
  };

  const validateOtp = async () => {
    if (!otp) return;

    try {
      setLoading(true);
      const result = await resultRef.current!.confirm(otp);
      const token = await result.user!.getIdToken();
      console.log(token);
      await plainAxios({
        method: "PUT",
        url: "https://fireside.api.stremium.com/externalToken/" + code,
        headers: { Authorization: token },
      });
      alert("Success! Your TV will refresh shortly.");
    } catch (err) {
      console.log(err);
      alert("Incorrect code!");
    } finally {
      setLoading(false);
    }
  };

  return (
    <ImageBackground style={styles.container} source={{ uri: BACKGROUND }}>
      <View style={styles.innerContainer}>
        {step === PhoneInput && (
          <>
            <div id="recaptcha"></div>
            <View style={styles.titleContainer}>
              <Icon name="phone-outline" size={24} color="#FFF" />
              <Text style={styles.title}>Sign in or Sign up</Text>
            </View>

            <Text style={styles.mb}>
              Enter your phone number below, this is your login
            </Text>

            <Provider>
              <View style={{}}>
                <TextInput
                  style={styles.phoneInput}
                  theme={textInputTheme}
                  mode="outlined"
                  dense
                  value={phone}
                  onChangeText={setPhone}
                ></TextInput>
                <View style={{ position: "absolute", top: 6 }}>
                  <Menu
                    //@ts-ignore
                    contentStyle={{
                      width: "100%",
                      maxHeight: 300,
                      overflow: "auto",
                      opacity: 1,
                      transform: [{ scaleX: 1 }, { scaleY: 1 }],
                    }}
                    visible={visible}
                    onDismiss={handleCloseMenu}
                    anchor={
                      <Button
                        mode="outlined"
                        style={{
                          borderTopWidth: 0,
                          borderLeftWidth: 0,
                          borderBottomWidth: 0,
                        }}
                        contentStyle={{ width: 80, height: 42 }}
                        onPress={handleOpenMenu}
                      >
                        <Text style={styles.text}>{dialCode}</Text>
                      </Button>
                    }
                  >
                    {countryList.map((n, index) => (
                      <Menu.Item
                        key={index}
                        onPress={() => handleMenuItemPress(n.dial_code)}
                        title={`${n.name} (${n.dial_code})`}
                      />
                    ))}
                  </Menu>
                </View>
              </View>
            </Provider>

            <Text style={styles.mb}>
              By entering, I agree to{" "}
              <a style={styles.text} href="https://firesidechat.com/terms">
                Fireside Terms
              </a>{" "}
              and{" "}
              <a
                style={styles.text}
                href="https://firesidechat.com/privacy-policy"
              >
                Privacy Policy
              </a>
            </Text>

            <Button mode="contained" onPress={signIn} style={{ zIndex: -1 }}>
              Next
            </Button>
          </>
        )}
        {step === VerifyOtp && (
          <>
            <View style={styles.titleContainer}>
              <Icon name="code-tags" size={24} color="#FFF" />
              <Text style={styles.title}>Security Code</Text>
            </View>

            <Text style={styles.mb}>We texted you at {dialCode + phone}</Text>
            <TextInput
              style={styles.otpInput}
              theme={textInputTheme}
              mode="outlined"
              dense
              placeholder="XXXXXX"
              value={otp}
              onChangeText={setOtp}
            />

            <Button
              mode="contained"
              loading={loading}
              disabled={loading}
              onPress={validateOtp}
            >
              Next
            </Button>
          </>
        )}
      </View>
    </ImageBackground>
  );
};

export default ScreenLinkTv;
