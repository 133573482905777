import { Maybe } from "../../../../gql/generated/client-cached.graphql";

export enum EventType {
  TRANSCRIBE = "transcribe",

  CHAT_MEMBERSHIP_REACTION = "chatMembershipReaction",

  CHAT_MEMBERSHIP_JOIN = "chatMembershipJoin",

  CHAT_MEMBERSHIP_LEAVE = "chatMembershipLeave",
  CHAT_MEMBERSHIP_KICK = "chatMembershipKick",

  CHAT_MEMBERSHIP_SPOKE = "chatMembershipSpoke",
  CHAT_MEMBERSHIP_MIC_CONTROL = "chatMembershipMicControl",

  CHAT_CREATE = "chatCreate",
  CHAT_UPDATE = "chatUpdate",
  CHAT_END = "chatEnd",

  CHAT_MEMBERSHIP_UPDATED = "chatMembershipUpdated",
  CHAT_MEMBERSHIP_STAGE_CHANGE = "chatMembershipStageChange",
  CHAT_MEMBERSHIP_ROLE_CHANGE = "chatMembershipRoleChange",

  CHAT_VIDEO_START = "chatVideoStart",
  CHAT_VIDEO_STOP = "chatVideoStop"
}

export enum EntityRole {
  HOST = "host",
  MODERATOR = "moderator",
  GUEST = "guest",
  NETWORK_HOST = "network_host"
}

export type ReplayState = {
  activeHeadline: string;
  activeSpeakerUserId: Maybe<string>;
  onStageUserIds: Set<string>;
  participantUserIds: string[];
  reactionsByUserId: Map<string, string>;
  mutedUserIds: Set<string>;
  rolesByUserId: Map<string, EntityRole>;
  videoSharingUserIds: Set<string>;
};

export type ReplayShowMember = {
  uid: string;
  emojiUlid: Maybe<string>;
  muted: boolean;
  role: EntityRole;
  sharingVideo: boolean;
};

const roleSortPriority: Record<EntityRole, number> = {
  [EntityRole.HOST]: 100,
  [EntityRole.NETWORK_HOST]: 100,
  [EntityRole.GUEST]: 50,
  [EntityRole.MODERATOR]: 10
};

export const sortByEntityRoleEnum = (a: EntityRole, b: EntityRole) => roleSortPriority[b] - roleSortPriority[a];
