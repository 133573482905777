import { EntityType } from "src/atoms/content-feed/enums";
import { Entity } from "src/atoms/content-feed/types";

const uidExtractor = <T extends { uid: string }>(item: T) => item.uid;

export const ulidExtractor = <T extends { ulid: string }>(item: T) => item.ulid;

export const slugExtractor = <T extends { slug: string }>(item: T) => item.slug;

export const idExtractor = <T extends { id: string }>(item: T) => item.id;

export const nestedUserExtractor = <T extends { user: { uid: string } }>(
  item: T
) => uidExtractor(item.user);

export const entityKeyExtractor = (entity: Entity): string => {
  switch (entity.entityType) {
    case EntityType.SHOW: {
      return ulidExtractor(entity.entity);
    }

    default: {
      console.error(`entityKeyExtractor - unexpected entity type.`, {
        entityType: entity.entityType,
      });

      return "";
    }
  }
};
