import { Episode } from "src/atoms/airing-now/epg";
import {
  AlignItems,
  Display,
  FireText,
  FlexDirection,
  Layout,
  Overflow,
  Rail,
  TextType,
  useRailContext,
} from "src/ui";
import EpisodeTile from "../EpisodeTile";
import { useIntl } from "react-intl";
import { useEffect, useRef, useState } from "react";
import { FlatList, LayoutRectangle } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import FocusGroup from "src/ui/components/FocusGroup";

interface Props {
  channelID: string;
  upcomingEpisodes: Episode[];
  focused?: boolean;
  onItemFocus?: (episode: Episode) => void;
}
export const ChannelGuideUpcomingEpisodes: React.FC<Props> = ({
  upcomingEpisodes,
  focused,
  onItemFocus,
  channelID,
}) => {
  const { formatMessage } = useIntl();
  const [episodes, setEpisodes] = useState(upcomingEpisodes.slice(0, 4));
  const sliderRef = useRef<ScrollView>(null);

  useEffect(() => {
    if (focused) {
      setEpisodes(upcomingEpisodes.slice(0));
    } else {
      setEpisodes(upcomingEpisodes.slice(0, 4));
    }
  }, [focused, upcomingEpisodes]);

  return (
    <Layout
      display={Display.Flex}
      flexDirection={FlexDirection.Column}
      alignItems={AlignItems.Start}
      shrink
      overflow={Overflow.Hidden}
      fullWidth
    >
      <FireText type={TextType.H4}>
        {formatMessage({ defaultMessage: "Upcoming" })}
      </FireText>
      {/*
       * Rail doesn't seem to work nested (Specifically FlatList)
       * ScrollView seems to solve this. We should consider rebuilding
       * with ScrollView to avoid the magicbox of FlatList.
       */}
      <FocusGroup id={`UpcomingEpisodeScroller_${channelID}`}>
        <ScrollView
          ref={sliderRef}
          horizontal
          nestedScrollEnabled={true}
          className="w-full"
        >
          {episodes.map((e, i) => (
            <EpisodeRender
              key={`upcoming-episode-${e.title}-${e.seasonNumber}-${e.episodeNumber}-${i}`}
              sliderRef={sliderRef.current}
              itemIndex={i}
              episode={e}
              onFocus={onItemFocus}
            />
          ))}
        </ScrollView>
      </FocusGroup>
    </Layout>
  );
};

interface EpisodeRenderProps {
  sliderRef: ScrollView | null;
  itemIndex: number;
  episode: Episode;
  onFocus?: (episode: Episode) => void;
}

const EpisodeRender: React.FC<EpisodeRenderProps> = ({
  episode,
  onFocus,
  sliderRef,
  itemIndex,
}) => {
  const layout = useRef<LayoutRectangle>({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });
  return (
    <EpisodeTile
      onLayout={(e) => {
        layout.current = e.nativeEvent.layout;
      }}
      image={episode.primaryImageUrl}
      onFocus={() => {
        onFocus?.(episode);
        sliderRef?.scrollTo({
          animated: true,
          x: layout.current.width * itemIndex,
        });
      }}
    />
  );
};
