import { Text, View } from "react-native";
import { useParams } from "src/components/Router";

import { useSlugData } from "../../hooks/use-slug-data";
import { EntityType } from "src/atoms/content-feed/enums";
import { NetworkProfile } from "src/screens/Network/components/Profile";
import LoadingScreen from "src/components/LoadingScreen";
import { SeriesProfile } from "src/screens/Series/components/Profile";
import { ShowProfile } from "src/screens/Show/components/Profile";
import { UserProfile } from "src/screens/User/components/Profile";

/**
 * Page that handles dynamic slugs, returns NetworkProfile, SeriesProfile
 * ShowProfile or UserProfile.
 */
const ScreenProfile: React.FC = () => {
  const { slug } = useParams();
  const { entityType, isLoading } = useSlugData(slug);
  const containerClasses = "flex-1 bg-black";

  if (isLoading) {
    return (
      <View className={containerClasses}>
        <LoadingScreen />
      </View>
    );
  }

  if (!slug) {
    return <View className={containerClasses} />;
  }

  switch (entityType) {
    case EntityType.NETWORK: {
      return <NetworkProfile slug={slug} />;
    }
    case EntityType.SERIES: {
      return <SeriesProfile slug={slug} />;
    }
    case EntityType.SHOW: {
      return <ShowProfile slug={slug} />;
    }
    case EntityType.USER: {
      return <UserProfile slug={slug} />;
    }
    default: {
      return <View className={containerClasses} />;
    }
  }
};

export default ScreenProfile;
