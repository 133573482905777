import { useReplayAuditoriumManager } from "../relay-events/useReplayAuditoriumManager";

type Manager = ReturnType<typeof useReplayAuditoriumManager>["replayAuditoriumManager"];

class PlayerAuditoriumBridge {
  private managers = new Map<string, Manager>();

  addManager = (ulid: string, manager: Manager) => {
    this.managers.set(ulid, manager);

    return () => {
      this.managers.delete(ulid);
    };
  };

  tick = (ulid: string, tickSeconds: number) => {
    this.managers.get(ulid)?.tick(tickSeconds);
  };
}

export const playerBridge = new PlayerAuditoriumBridge();
