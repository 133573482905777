import { Episode } from "src/atoms/airing-now/epg";
import { useChannelVideo } from "./use-channel-video";
import VideoPlayer from "src/screens/Player";

interface Props {
  channelID: string;
  episode: Episode;
  isLive?: boolean;
  onPlay?: () => void;
}

export const ChannelVideo: React.FC<Props> = ({
  channelID,
  episode,
  isLive,
  onPlay,
}) => {
  const { data } = useChannelVideo(channelID, episode, isLive);

  if (!data?.url) {
    return null;
  }

  return <VideoPlayer data={data} useCoverMode useVideoJs />;
};
