import React from "react";
import { FeedMessage } from "../../../../atoms/content-feed/types";
import { View, Text, Image } from "../../../../components/Tw";

type Props = {
  item: FeedMessage;
};

const imageClasses = "h-14 w-14 rounded-full";

const VideoEntityFeedMessage = ({ item }: Props) => {
  return (
    <View className="flex-row items-center h-18 w-96 p-2.5 mb-2.5 rounded-[20px] bg-[rgba(0,0,0,0.4)]">
      {item.avatar ? <Image className={imageClasses} source={{ uri: item.avatar }} /> : <View className={imageClasses} style={{ backgroundColor: "rgba(165, 0, 151, 0.7)" }} />}
      <View className="flex-1 ml-3">
        <View className="flex-row">
          <Text className="text-white font-bold whitespace-nowrap">{item.displayName}:</Text>
          <Text className="text-white ml-1"> {item.message ? `${item.message}` : null}</Text>
        </View>
        {!item.message && (
          <View className="flex-row items-center space-x-0.5">
            {Array.from({ length: 4 }).map((_, i) => (
              <Image key={i} className="h-3 w-3" source={{ uri: item.emojiUrl }} />
            ))}
          </View>
        )}
      </View>
    </View>
  );
};

export default VideoEntityFeedMessage;
