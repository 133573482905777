import { pushNavigation, setSelectedVod, vodState } from "src/atoms/vod";
import { BorderRadius, Interactable, Layout, useRailContext } from "src/ui";
import Content from "./Content";
import { useState } from "react";
import { playVod, playerState } from "src/atoms/player";
import { useRecoilState } from "recoil";

interface ItemProps {
  rowIndex: number;
  item: any;
}

export const Item: React.FC<ItemProps> = ({ item, rowIndex }) => {
  const { sliderRef, itemIndex } = useRailContext();
  const [active, setActive] = useState(false);
  const [vod, setVodState] = useRecoilState<any>(vodState);
  const [{ data }, setPlayerState] = useRecoilState<any>(playerState);

  return (
    <Layout margin={{ r: 12 }}>
      <Interactable
        borderRadius={BorderRadius.XXXL}
        onFocus={() => {
          setActive(true);
          setSelectedVod(item, rowIndex, itemIndex);
          if (sliderRef) {
            sliderRef.scrollToIndex({
              animated: true,
              index: itemIndex,
            });
          }
        }}
        onBlur={() => {
          setActive(false);
        }}
        onEnterPress={() => {
          if (item.playable) {
            playVod(setPlayerState, item);
          } else {
            pushNavigation(setVodState, vod, item.id);
          }
        }}
      >
        <Content item={item} active={active} isLoading={false} />
      </Interactable>
    </Layout>
  );
};
