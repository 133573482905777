import { mapToSeries } from "src/atoms/content-feed/mappers";
import { useGetSeriesQuery } from "src/gql/generated/client-cached.graphql";
import { ProfileBackdrop } from "src/screens/Profile/components/Backdrop";
import {
  Background,
  Color,
  Display,
  FireText,
  FlexDirection,
  IconButton,
  Layout,
  useModal,
} from "src/ui";
import { SeriesHeading } from "../Heading";
import { SeriesRecurringText } from "../Recurring";
import { SeriesCategories } from "../Categories";
import QRCode from "src/components/Icons/QRCode";
import { QRModal } from "src/components/QRModal";
import { NetworkFollowButton } from "src/screens/Network/components/FollowButton";
import FocusGroup from "src/ui/components/FocusGroup";
import HighlightsRail from "src/screens/Profile/components/HighlightsRail";
import { ProfileRail } from "src/screens/Profile/components/Rail";
import { NetworkCard } from "src/screens/Network/components/Card";
import { SeriesUpcomingShows } from "../UpcomingShows";
import { SeriesCastAndCrew } from "../../CastAndCrew";
import { SeriesRecentShows } from "../RecentShows";
import { useIntl } from "react-intl";

interface Props {
  slug: string;
}

export const SeriesProfile: React.FC<Props> = ({ slug }) => {
  const { formatMessage } = useIntl();
  const { data: series } = useGetSeriesQuery(
    { slug },
    {
      select: (data) => mapToSeries({ series: data.series_by_pk }),
    }
  );
  const { showModal } = useModal();

  if (!series) {
    return null;
  }

  const categoryNames = series.categoryUlids?.map(
    (id) => series.format?.categories.find((cat) => cat.ulid === id)?.name || ""
  );
  return (
    <Layout
      name="user-profile__container"
      display={Display.Flex}
      flexDirection={FlexDirection.Column}
      background={Background.Base}
      grow
      padding={{ b: 16 }}
    >
      <ProfileBackdrop imageSrc={series?.coverImageUrl || ""} />
      <Layout
        name="user-profile__details"
        margin={{ t: 80, b: 20 }}
        padding={{ x: 20 }}
      >
        <Layout margin={{ b: 4 }}>
          <SeriesHeading
            image={series.coverImageUrl || ""}
            title={series.displayName}
            series={series.networks?.[0]?.name}
          />
        </Layout>
        <Layout margin={{ b: 1 }}>
          <SeriesRecurringText
            recurringModel={{ every_day_of_week: [3], time: "1234" }}
          />
        </Layout>
        {categoryNames && <SeriesCategories names={categoryNames} />}
        <Layout margin={{ y: 5 }}>
          <FireText color={Color.Alt}>{series.description}</FireText>
        </Layout>
        <FocusGroup id="Series_Action_Buttons">
          <Layout display={Display.Flex} margin={{ t: 2 }}>
            <Layout margin={{ r: 4 }}>
              {/* TODO Series Follow */}
              <NetworkFollowButton slug={series.slug} />
            </Layout>
            <IconButton
              onEnterPress={() => {
                showModal(<QRModal path={slug} />);
              }}
            >
              <QRCode />
            </IconButton>
          </Layout>
        </FocusGroup>
      </Layout>
      <HighlightsRail slug={series.slug} />
      {series.networks.length && (
        <Layout margin={{ y: 10 }}>
          <ProfileRail
            title={formatMessage({
              defaultMessage: "Network Affiliations",
            })}
            canLoadMore={false}
            onLoadMore={() => {}}
          >
            {series.networks.map((n) => (
              <NetworkCard
                key={`NetworkAffiliationItem_${n.slug}`}
                image={n.imageUrl}
                slug={n.slug}
              />
            ))}
          </ProfileRail>
        </Layout>
      )}
      <SeriesRecentShows slug={series.slug} />
      <SeriesCastAndCrew roles={series.roles} />
      <SeriesUpcomingShows slug={series.slug} />
    </Layout>
  );
};
