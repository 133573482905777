import { useIntl } from "react-intl";
import {
  AlignItems,
  Color,
  Display,
  FlexDirection,
  Layout,
  ModalContainer,
  FireText,
  TextType,
} from "src/ui";
import { QRCode } from "src/ui/components/QRCode";

interface Props {
  path: string;
  text?: string;
}

export const QRModal: React.FC<Props> = ({ path, text }) => {
  const { formatMessage } = useIntl();
  return (
    <ModalContainer>
      <Layout
        display={Display.Flex}
        flexDirection={FlexDirection.Column}
        alignItems={AlignItems.Center}
      >
        <QRCode value={`https://www.firesidechat.com/${path}`} />
        <Layout margin={{ y: 5 }}>
          <FireText color={Color.Overlay} type={TextType.Large}>
            {text ||
              formatMessage({
                defaultMessage: "Scan to Share",
              })}
          </FireText>
        </Layout>
      </Layout>
    </ModalContainer>
  );
};
