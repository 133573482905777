import ky from "ky-universal";

import { EntityRole } from "./types";
import { Maybe } from "../../../../gql/generated/client-cached.graphql";
import getRelaySnapshot from "../../../../gql/server/api/get-replay-snapshot";

export type ReplayShowMember = {
  uid: string;
  emojiUlid: Maybe<string>;
  muted: boolean;
  role: EntityRole;
  sharingVideo: boolean;
};

export type ReplaySnapshot = {
  active_headline: string;
  active_speaker_user_id: Maybe<string>;
  muted_user_ids: string[];
  on_stage_user_ids: string[];
  participant_user_ids: string[];
  reactions_by_user_id: Record<string, string>;
  roles_by_user_ids: Record<string, string>;
  video_sharing_user_ids: string[];
};

type ErrorMetadata = {
  ulid: string;
  start: number;
  end: number;
};

const mapToReplayShowMember = (snapshot: ReplaySnapshot, uid: string): ReplayShowMember => ({
  emojiUlid: snapshot.reactions_by_user_id[uid],
  muted: snapshot.muted_user_ids.includes(uid),
  role: snapshot.roles_by_user_ids[uid] as EntityRole,
  sharingVideo: snapshot.video_sharing_user_ids.includes(uid),
  uid
});

export class ReplaySnapshotManager {
  private readonly prefetchThresholdSeconds = 5;

  private readonly prefetchSnapshotsAmount = 15;

  private localCache = new Map<number, ReplaySnapshot | null>();

  private lastUpdateSeconds = -1;

  private duration = Number.POSITIVE_INFINITY;

  private ulid = "";

  private pendingLoad: Maybe<[AbortController, [number, number]]> = null;

  private didInit = false;

  /**
   * - call load on initial load
   * - call tick on every player tick, regardless of it being a seek or not
   * - call setDuration once the player resolves the duration
   *
   * @param setAudience audience setter callback
   * @param setFortuneCookie fortune cookie setter callback
   * @param setSpeakers speakers setter callback
   * @param setActiveSpeaker  active speaker setter callback
   * @param onLoadError load error callback
   * called on actual load errors (eg. network failure) as well as aborts (eg. seek while request is pending)
   * @param webOrigin domain of the web app, eg. https://dev.firesidechat.com
   */
  constructor(
    private setAudience: (audience: ReplayShowMember[]) => void,
    private setFortuneCookie: (fortuneCookie: string) => void,
    private setSpeakers: (speakers: ReplayShowMember[]) => void,
    private setActiveSpeaker: (speakers: Maybe<string>) => void,
    private onLoadError: (err: unknown, metadata: ErrorMetadata) => void
  ) {}

  /**
   * call this on initial load
   *
   * @param ulid chat ulid
   * @param floatSeconds should be 0, unless initial load isn't at the beginning
   */
  load = async (ulid: string, floatSeconds: number) => {
    this.ulid = ulid;

    const start = Math.round(floatSeconds);
    const end = Math.min(start + this.prefetchSnapshotsAmount, this.duration);
    const controller = new AbortController();

    if (this.pendingLoad) {
      this.pendingLoad[0].abort();
    }

    this.pendingLoad = [controller, [start, end]];

    let result: (ReplaySnapshot | null)[] = [];

    try {
      result = await getRelaySnapshot({ ulid, start: start, end: end });
    } catch (error) {
      this.onLoadError(error, {
        ulid,
        start,
        end
      });
    }

    let pendingController: AbortController | null = null;

    if (this.pendingLoad) {
      [pendingController] = this.pendingLoad;
    }

    if (pendingController !== controller) {
      return;
    }

    this.pendingLoad = null;

    result.forEach((snapshot, i) => {
      this.localCache.set(start + i, snapshot);
    });

    if (!this.didInit) {
      this.triggerCallbacks(start);

      this.didInit = true;
    }
  };

  /**
   * call this on every player tick
   *
   * @param floatSeconds current player time in seconds
   */
  tick = async (floatSeconds: number) => {
    const seconds = Math.round(floatSeconds);

    if (this.lastUpdateSeconds !== seconds) {
      if (this.localCache.has(seconds)) {
        this.triggerCallbacks(seconds);
      } else if (this.pendingLoad) {
        const [, [start, end]] = this.pendingLoad;

        if (seconds < start || seconds > end) {
          await this.load(this.ulid, seconds);
        }
      } else {
        await this.load(this.ulid, seconds);
      }
    }

    if (!this.pendingLoad) {
      for (let i = 0; i < this.prefetchThresholdSeconds; i += 1) {
        const futureSecond = seconds + i;

        if (futureSecond > this.duration) {
          break;
        }

        const hasFutureSnapshot = this.localCache.has(futureSecond);

        if (!hasFutureSnapshot) {
          this.load(this.ulid, futureSecond);

          break;
        }
      }
    }
  };

  /**
   * call this once media duration is resolved
   *
   * @param floatSeconds media duration in seconds
   */
  setDuration = (floatSeconds: number) => {
    this.duration = Math.floor(floatSeconds);
  };

  private triggerCallbacks = (floatSeconds: number) => {
    const seconds = Math.round(floatSeconds);

    const snapshot = this.localCache.get(seconds);

    if (snapshot) {
      this.setAudience(snapshot.participant_user_ids.map((uid) => mapToReplayShowMember(snapshot, uid)));

      this.setFortuneCookie(snapshot.active_headline);

      this.setSpeakers(snapshot.on_stage_user_ids.map((uid) => mapToReplayShowMember(snapshot, uid)));

      this.setActiveSpeaker(snapshot.active_speaker_user_id);

      this.lastUpdateSeconds = seconds;
    }
  };
}
