import { useCallback } from "react";
import { Maybe } from "src/gql/generated/client-cached.graphql";

export type InfiniteQueryOptionsHookCallbacks<TPage, TItem> = {
  filter?: (item: TItem) => boolean;
  keyExtractor: (item: TItem) => string;
  normalizePageData: (page: TPage) => ReadonlyArray<TItem>;
};

export type UseInfiniteQueryOptionsProps<TPage, TItem> = {
  pageSize: number;
  maxCount?: Maybe<number>;
} & InfiniteQueryOptionsHookCallbacks<TPage, TItem>;

export const useInfiniteQueryOptions = <TPage, TItem>({
  pageSize,
  normalizePageData,
}: UseInfiniteQueryOptionsProps<TPage, TItem>) => {
  const getNextPageParam = useCallback(
    (lastPage: TPage, allPages: TPage[]) => {
      if (normalizePageData(lastPage).length < pageSize) {
        // eslint-disable-next-line unicorn/no-useless-undefined
        return undefined;
      }

      return {
        offset: pageSize * allPages.length,
      };
    },
    [normalizePageData, pageSize]
  );

  return { getNextPageParam, staleTime: Number.POSITIVE_INFINITY };
};
