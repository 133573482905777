import React, { useCallback, useMemo, useState } from "react";
import { View, Image } from "../../../components/Tw";

import { useGetChatQuery } from "../../../gql/generated/client-cached.graphql";
import { VideoFeedEntity } from "../../../atoms/content-feed/types";
import { useVideoEntityPoster } from "../hooks/useVideoEntityPoster";
import { mapQueryToShow } from "../../../atoms/content-feed/mappers";

type PosterProps = {
  videoEntity: VideoFeedEntity;
  landscape: boolean;
};

export const VideoEntityPoster: React.FC<PosterProps> = ({ videoEntity, landscape }: PosterProps) => {
  const { posterUrl, posterVisible } = useVideoEntityPoster({
    videoEntity,
    landscape
  });

  const chatUlid = videoEntity.type === "chat" ? videoEntity.ulid : videoEntity.chatUlid;

  const { data: show } = useGetChatQuery({ ulid: chatUlid, id: chatUlid }, { select: mapQueryToShow });

  const finalPosterUrl = posterUrl || show?.coverImageUrl;

  if (!posterVisible) {
    return null;
  }

  if (!finalPosterUrl) {
    return <View className="absolute inset-0 -z-10" />;
  }

  return <Image resizeMode="contain" className="w-screen h-screen !absolute inset-0 -z-10" source={{ uri: finalPosterUrl }} />;
};
