import { FIRESIDE_COVER_FALLBACK } from "./constants";

export function useShowCover(showImage: string, seriesImage: string) {
  const isFallback = showImage.indexOf(FIRESIDE_COVER_FALLBACK) > -1;
  if (seriesImage && isFallback) {
    return seriesImage;
  }

  return showImage;
}
