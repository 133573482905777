import { AlignItems, Color, Display, Layout, FireText, TextType } from "src/ui";
import { formatTomorrowOrTime } from "src/core/utils/format-time";
import { Notification } from "src/components/Icons";

interface Props {
  /** ISO Format Time */
  time: string;
}
export const ShowScheduledTime: React.FC<Props> = ({ time }) => {
  return (
    <Layout display={Display.Flex} alignItems={AlignItems.Center}>
      <FireText type={TextType.Micro} color={Color.Alt}>
        {formatTomorrowOrTime(time) || " "}
      </FireText>
      <Layout margin={{ l: 4 }}>
        <Notification />
      </Layout>
    </Layout>
  );
};
