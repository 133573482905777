import { Children, PropsWithChildren } from "react";
import { Display, FireText, Layout, Rail, TextType } from "src/ui";

interface Props {
  title?: string;
  canLoadMore?: boolean;
  onLoadMore: () => void;
}

export const ProfileRail: React.FC<PropsWithChildren<Props>> = ({
  title,
  children,
  canLoadMore,
  onLoadMore,
}) => {
  const childs = Children.toArray(children).filter((c) => !!c);
  return (
    <>
      <Layout margin={{ b: 5, l: 20 }}>
        <FireText type={TextType.H4}>{title}</FireText>
      </Layout>
      <Layout display={Display.Flex}>
        <Rail
          id={`${title}_RAIL`}
          contentContainerStyle={{
            marginHorizontal: 80,
          }}
          canLoadMore={canLoadMore}
          onLoadMore={onLoadMore}
        >
          {Children.map(childs, (child, index) => (
            <Layout key={`${title}_${index}`} margin={{ r: 12 }}>
              {child}
            </Layout>
          ))}
        </Rail>
      </Layout>
    </>
  );
};
