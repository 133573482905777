import React, { PropsWithRef } from "react";
import { View, Text } from "../../../components/Tw";
import { VideoFeedEntity } from "../../../atoms/content-feed/types";
import { useAtomValue } from "jotai";
import { playingAtom } from "../../../atoms/content-feed/activeIndexes";
import { VideoEntityPoster } from "./VideoEntityPoster";
import VideoEntityActionsContainer from "../components/auditorium/actions/VideoEntityActionsContainer";
import { VideoEntityHeader } from "./VideoEntityHeader";
import InlinePlayer from "../components/InlinePlayer";
import { ClipSpeakers } from "../components/auditorium/ClipSpeakers";
import { VideoEntityContext } from "../components/auditorium/actions/VideoEntityContext";

type FeedProps = {
  active: boolean;
  feedEntity: VideoFeedEntity;
};

const Feed: React.FC<PropsWithRef<FeedProps>> = ({ feedEntity, active }) => {
  const isPlaying = useAtomValue(playingAtom);

  // console.log("Feed", feedEntity, active);
  return (
    <VideoEntityContext.Provider value={feedEntity}>
      <View className="flex-1 p-6">
        <VideoEntityPoster videoEntity={feedEntity} landscape={true} />
        {active && <InlinePlayer videoFeedEntity={feedEntity} />}
        <VideoEntityHeader videoEntity={feedEntity} />
        {active && feedEntity.type === "clip" && (
          <ClipSpeakers clipEntity={feedEntity} />
        )}
        {/* {active && <Text className="text-white">{JSON.stringify(feedEntity, null, 4)}</Text>} */}
        {active && <VideoEntityActionsContainer videoEntity={feedEntity} />}
      </View>
    </VideoEntityContext.Provider>
  );
};

const Memo = React.memo(Feed, (prevProps, nextProps) => {
  return (
    prevProps.feedEntity?.ulid === nextProps.feedEntity?.ulid &&
    prevProps.active === nextProps.active
  );
});

export default Memo;
