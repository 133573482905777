import { useCallback } from "react";
import { User } from "src/atoms/content-feed/types";
import Star from "src/components/Icons/Star";
import {
  AlignItems,
  Avatar,
  AvatarSize,
  BorderRadius,
  Display,
  FlexDirection,
  Interactable,
  JustifyContent,
  Layout,
  FireText,
  InteractableProps,
} from "src/ui";

interface PublicProps {
  user: User | null;
  host?: boolean;
  loading?: boolean;
}

type Props = PublicProps & InteractableProps;

export const UserAvatar: React.FC<Props> = ({
  user,
  loading,
  onEnterPress,
  onFocus,
  host,
}) => {
  if (loading || !user) {
    return null;
  }

  const getAvatar = useCallback(() => {
    if (onEnterPress) {
      return (
        <Interactable
          borderRadius={BorderRadius.Full}
          onEnterPress={onEnterPress}
          onFocus={onFocus}
        >
          <Avatar imageSrc={user.avatarUrl} size={AvatarSize.Large} />
        </Interactable>
      );
    } else {
      return <Avatar imageSrc={user.avatarUrl} size={AvatarSize.Large} />;
    }
  }, [user, onEnterPress, onFocus]);

  return (
    <Layout
      display={Display.Flex}
      flexDirection={FlexDirection.Column}
      alignItems={AlignItems.Center}
      justifyContent={JustifyContent.Center}
    >
      {getAvatar()}
      <Layout display={Display.Flex} margin={{ t: 2 }}>
        {host && (
          <Layout margin={{ r: 2 }}>
            <Star />
          </Layout>
        )}
        <FireText>{user.displayName}</FireText>
      </Layout>
    </Layout>
  );
};
