import { useEffect, useState } from "react";
import { Dimensions, FlatList, ImageBackground, Image } from "react-native";
import { Episode } from "src/atoms/airing-now/epg";
import {
  AlignItems,
  Background,
  BorderRadius,
  Display,
  FireImage,
  Interactable,
  JustifyContent,
  Layout,
  Overflow,
  Position,
  useRailContext,
} from "src/ui";
import { ChannelCardContent } from "./content";
import { ChannelVideo } from "./video";
import { LIVE_NOW_ANIMATION_NO_BLUR } from "src/config";
import {
  AuditoriumMode,
  useAuditorium,
} from "src/screens/Auditorium/hooks/use-auditorium";
import useBackKey from "src/hooks/useBackKey";
import ScalableImage from "src/components/ScalableImage";

interface Props {
  channelID: string;
  episode: Episode;
  verticalSlider: FlatList | null;
  verticalIndex: number;
}

export const EPISODE_FOCUSKEY_PREFIX = "AiringNowEpisode";
export const ChannelCard: React.FC<Props> = ({
  episode,
  channelID,
  verticalSlider,
  verticalIndex,
}) => {
  const [ready, setReady] = useState(false);
  const { sliderRef, itemIndex, parentFocused } = useRailContext();
  const [active, setActive] = useState(false);
  const [playing, setPlaying] = useState(false);
  const { mode, setMode } = useAuditorium();
  const [interacted, setInteracted] = useState(false);
  const embeddedPlayer = active && mode === AuditoriumMode.Embedded;
  const invis = !parentFocused && itemIndex !== 1 ? "opacity-0" : "opacity-100";
  const cardSize = "w-[75vw] h-[75vh]";
  const width = active ? "w-[73vw]" : "w-[66vw]";

  /**
   * initialScrollIndex on FlatList does not
   * place nice with Spatial Navigation OR
   * horizontalPadding styles. So this initially
   * set up the horizontal rail.
   */
  useEffect(() => {
    if (!ready && sliderRef) {
      if (itemIndex === 1) {
        setTimeout(() => {
          sliderRef.scrollToIndex({
            animated: false,
            index: itemIndex,
            viewOffset: Dimensions.get("window").width * 0.125,
          });
        }, 1);
        setReady(true);
      }
    }
  }, [ready, sliderRef, verticalSlider]);

  // Auto fullscreen after 10s
  useEffect(() => {
    let timeout;
    if (active && itemIndex !== 0) {
      timeout = setTimeout(() => {
        if (active && !interacted) {
          setMode(AuditoriumMode.Fullscreen);
        }
      }, 10000);
    } else {
      clearTimeout(timeout);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [active, interacted]);

  //Exit Fullscreen
  useBackKey(() => {
    if (active && mode !== AuditoriumMode.Embedded) {
      setMode(AuditoriumMode.Embedded);
    }
  });

  const seriesTitle = episode.episodeTitle ? episode.title : null;
  const episodeTitle = episode.episodeTitle || episode.title;

  return (
    <Interactable
      key={channelID}
      disableFocusOutline
      focusKey={`${EPISODE_FOCUSKEY_PREFIX}_${verticalIndex}_${itemIndex}`}
      onFocus={() => {
        const horiViewOffset =
          itemIndex === 0 ? 0 : Dimensions.get("window").width * 0.125;
        sliderRef?.scrollToIndex({
          animated: true,
          index: itemIndex,
          viewOffset: horiViewOffset,
        });
        verticalSlider?.scrollToIndex({
          animated: true,
          index: verticalIndex,
          viewOffset: Dimensions.get("window").height * 0.125,
        });
        setActive(true);
        setPlaying(true);
      }}
      onBlur={() => {
        setActive(false);
        setPlaying(false);
        setMode(AuditoriumMode.Embedded);
      }}
      onEnterPress={() => {
        if (!active) {
          return;
        }

        if (!embeddedPlayer) {
          setMode(AuditoriumMode.Embedded);
        } else {
          setMode(AuditoriumMode.Fullscreen);
        }

        setInteracted(true);
      }}
    >
      <Layout
        className={`${cardSize} ${invis} transition-all duration-1000`}
        display={Display.Flex}
        alignItems={AlignItems.Center}
        justifyContent={JustifyContent.Center}
      >
        <Layout
          background={Background.Base}
          borderRadius={
            !active || embeddedPlayer ? BorderRadius.XXXL : BorderRadius.None
          }
          display={Display.Flex}
          overflow={Overflow.Hidden}
          className={`${width} transition-all duration-1000 aspect-video`}
        >
          {active && (
            <ChannelVideo
              channelID={channelID}
              episode={episode}
              isLive={itemIndex === 1}
              onPlay={() => {
                setPlaying(true);
              }}
            />
          )}
          {!playing && (
            <Layout
              className={`${
                active ? "opacity-100" : "opacity-50"
              } transition-all duration-1000`}
              fullHeight
              fullWidth
            >
              <FireImage src={episode.primaryImageUrl} fill />
            </Layout>
          )}
          <ChannelCardContent
            icon={episode.primaryImageUrl}
            seriesTitle={seriesTitle}
            episodeTitle={episodeTitle}
            description={episode.description}
            active={active}
          />
          {itemIndex === 1 && (
            <Layout
              className={`${
                embeddedPlayer ? "opacity-100" : "opacity-0"
              } transition-all duration-1000`}
              position={Position.Absolute}
              attachLeft
              attachTop
            >
              <Image
                style={{ width: 198, height: 76.5 }}
                source={LIVE_NOW_ANIMATION_NO_BLUR}
              />
            </Layout>
          )}
        </Layout>
      </Layout>
    </Interactable>
  );
};
