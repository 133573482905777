export enum Display {
  Block = "block",
  Flex = "flex",
}

export enum Background {
  Base = "bg-black",
  Alt = "bg-fs-gray-5",
  Overlay = "bg-black/60",
}

export enum FlexDirection {
  Row = "flex-row",
  Column = "flex-col",
  RowReverse = "flex-row-revers",
  ColumnReverse = "flex-col-reverse",
}

export enum AlignItems {
  Start = "start",
  End = "end",
  Center = "center",
  Stretch = "stretch",
  Between = "between",
}

export enum JustifyContent {
  Start = "start",
  End = "end",
  Stretch = "stretch",
  Center = "center",
  Between = "between",
}

export enum Position {
  Absolute = "absolute",
  Relative = "relative",
  Fixed = "fixed",
}

export enum ZIndex {
  Under = "-z-50",
}
