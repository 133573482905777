import classNames from "classnames";
import { PropsWithChildren, useEffect, useRef, useState } from "react";
import { Image as RNImage, View } from "react-native";
import { Maybe } from "src/gql/generated/client-cached.graphql";

export enum BackgroundSize {
  Center = "center",
  Stretch = "stretch",
  Contain = "contain",
  Repeat = "repeat",
  Cover = "cover",
}
export interface ImageProps {
  src?: Maybe<string>;
  fill?: boolean;
  fillWidth?: boolean;
  fillHeight?: boolean;
  height?: number;
  width?: number;
  backgroundSize?: BackgroundSize;
}

export const FireImage: React.FC<PropsWithChildren<ImageProps>> = ({
  src,
  fill,
  fillHeight,
  fillWidth,
  children,
  backgroundSize = BackgroundSize.Cover,
}) => {
  const imageRef = useRef<RNImage>(null);
  const classes = classNames({
    ["h-full"]: fill || fillHeight,
    ["w-full"]: fill || fillWidth,
  });

  if (src) {
    return (
      <RNImage
        ref={imageRef}
        className={classes}
        style={{ resizeMode: backgroundSize }}
        source={{ uri: src }}
      />
    );
  }

  return <View>{children}</View>;
};
