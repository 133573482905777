import { getSdk as getSdkCached } from "src/gql/generated/sdk-cached.graphql";
import { fetcher } from "./fetcher";
import { getSdk } from "src/gql/generated/sdk.graphql";

/**
 * @deprecated
 */
const doNotUseCachedSdk = getSdkCached(fetcher);

export const doNotUseFreshSdk = getSdk(fetcher);

export const cachedQueries = new Map<CachedSdkKey, true>(
  Object.keys(doNotUseCachedSdk).map((key) => [key as CachedSdkKey, true])
);

export type Sdk = typeof doNotUseFreshSdk;

export type SdkKey = keyof Sdk;
export type CachedSdkKey = keyof typeof doNotUseCachedSdk;

export type QueryResult<T> = {
  data: T;
  source: ResponseSource;
};

export enum ResponseSource {
  REDIS = "redis",
  IN_FLIGHT_REQUEST = "in-flight-request",
  ORIGIN = "origin",
  UNKNOWN = "unknown",
}
