import {
  FocusDetails,
  UseFocusableConfig,
  useFocusable,
} from "@noriginmedia/norigin-spatial-navigation";
import { PropsWithChildren, useCallback, useEffect, useState } from "react";
import {
  Dimensions,
  Pressable,
  PressableProps,
  StyleProp,
  ViewStyle,
} from "react-native";
import { BorderRadius } from "src/ui/models";

interface Props {
  style?: StyleProp<ViewStyle>;
  disableScrollOnFocus?: boolean;
  disableFocusOutline?: boolean;
  borderRadius?: BorderRadius;
}

export type InteractableProps = Props & PressableProps & UseFocusableConfig;

export const Interactable: React.FC<PropsWithChildren<InteractableProps>> = ({
  children,
  focusKey,
  onArrowPress,
  onEnterPress,
  onFocus,
  onBlur,
  style,
  borderRadius,
  disableScrollOnFocus,
  disableFocusOutline,
}) => {
  const { ref, focused, setFocus } = useFocusable({
    focusKey,
    onArrowPress: (direction, props, details) => {
      return onArrowPress?.(direction, props || {}, details) || true;
    },
    onEnterPress,
    onFocus: (item, props, details) => {
      if (!disableScrollOnFocus) {
        // Center Focused Item
        const halfWidth = Dimensions.get("window").width / 2;
        const halfHeight = Dimensions.get("window").height / 2;
        // Top - Half Device Dimension + Half Item Dimension
        const top = item.top - halfHeight + item.height / 2;
        const left = item.left - halfWidth + item.width / 2;
        scrollTo({
          top,
          left,
          behavior: "smooth",
        });
      }

      onFocus?.(item, props, details);
    },
    onBlur,
  });

  const focusedBorder = !disableFocusOutline
    ? `border-solid border-4 border-white rounded-${borderRadius}`
    : "";
  const transparentBorder = !disableFocusOutline
    ? "border-4 border-solid border-transparent"
    : "";

  return (
    <Pressable
      ref={ref}
      style={style}
      className={`interactable ${focused ? focusedBorder : transparentBorder}`}
      onPress={(e) => {
        onEnterPress?.({}, { pressedKeys: {} });
      }}
    >
      {children}
    </Pressable>
  );
};
