import { Show } from "src/atoms/content-feed/types";
import { ShowCard } from "src/screens/Show/components/Card";
import { useNavigateToShow } from "src/screens/Show/utils/use-url-path";
import { BorderRadius, Interactable, Layout, useRailContext } from "src/ui";

interface Props {
  show: Show | null;
  loading?: boolean;
}

export const RecentShowItem: React.FC<Props> = ({ show, loading }) => {
  const { sliderRef, itemIndex } = useRailContext();
  const navigate = useNavigateToShow();

  return (
    <Interactable
      borderRadius={BorderRadius.XXXL}
      onFocus={() => {
        if (sliderRef) {
          sliderRef.scrollToIndex({
            animated: true,
            index: itemIndex,
          });
        }
      }}
      onEnterPress={navigate(show)}
    >
      <ShowCard show={show} loading={loading} />
    </Interactable>
  );
};
