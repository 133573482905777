import config from "./firebase.config";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

initializeApp(config);
const auth = getAuth();
const firestore = getFirestore();

export { auth, firestore };
