import { useEffect, useState } from "react";
import axios from "src/utils/axios";

export function useChannelBrandColors(channelID: string) {
  const [backgroundColors, setBackgroundColors] = useState({
    brandColor: null,
    darkerBrandColor: null,
  });

  useEffect(() => {
    async function getColor() {
      const result = await axios.get(`/brandColor?channelId=${channelID}`);
      setBackgroundColors(result.data);
    }

    getColor();
  }, [channelID]);

  return backgroundColors;
}
