import React, { useCallback, useRef } from "react";
import { hasWebFocusableUI } from "../../config";
import { ContentFeedVerticalDataProvider } from "./components/ContentFeedVerticalDataProvider";
import { useSetAnonymousId } from "./hooks/useSetAnonymousId";
import { FeedSessionProvider } from "./components/FeedSessionProvider";
import { FlatList, ViewToken } from "react-native";
import { ITEM_HEIGHT } from "./constants";
import { VideoFeedEntity } from "../../atoms/content-feed/types";
import { useAtom } from "jotai";
import { activeIndexesAtom } from "../../atoms/content-feed/activeIndexes";
import RelatedClipSlider from "./RelatedClipSlider";
import VerticalSnapperProvider from "./components/VerticalSnapperProvider";
import { View } from "../../components/Tw";
import { useFocusable } from "@noriginmedia/norigin-spatial-navigation";

const ScreenContentFeedV2 = () => {
  const { setFocus } = useFocusable();
  useSetAnonymousId();

  const sliderRef = useRef<FlatList>(null);
  const [activeIndexes, setActiveIndexes] = useAtom(activeIndexesAtom);

  const getItemLayout = (data, index) => ({
    length: ITEM_HEIGHT,
    offset: ITEM_HEIGHT * index,
    index,
  });

  const renderItem = ({
    item,
    index,
  }: {
    item: VideoFeedEntity;
    index: number;
  }) => {
    return (
      <RelatedClipSlider
        active={activeIndexes.vertical === index}
        activeIndex={activeIndexes.horizontal}
        verticalEntity={item}
        focusKey={`slider_${index}`}
        onFocus={() => sliderRef.current?.scrollToIndex({ index })}
      />
    );
  };

  const handleViewableItemsChanged = useCallback(
    ({
      viewableItems,
      changed,
    }: {
      viewableItems: Array<ViewToken>;
      changed: Array<ViewToken>;
    }) => {
      setActiveIndexes((prev) => {
        if (
          (viewableItems.length === 1 &&
            viewableItems[0].index !== prev.vertical) ||
          prev.item === null
        ) {
          return {
            ...prev,
            vertical: viewableItems[0].index!,
            item: viewableItems[0].item,
          };
        }

        return prev;
      });
    },
    []
  );

  console.log("ContentFeed");
  return (
    <View className="flex-1 bg-black">
      <FeedSessionProvider>
        <ContentFeedVerticalDataProvider activeIndex={activeIndexes.vertical}>
          {({ entities: verticalAxisEntities }) => {
            return (
              <VerticalSnapperProvider
                activeIndexes={activeIndexes}
                sliderRef={sliderRef}
                setFocus={setFocus}
              >
                <FlatList
                  ref={sliderRef}
                  showsVerticalScrollIndicator={true}
                  // pagingEnabled
                  decelerationRate="fast"
                  // scrollEventThrottle={16}
                  // snapToInterval={ITEM_HEIGHT}
                  windowSize={2}
                  initialNumToRender={2}
                  onEndReachedThreshold={1}
                  onViewableItemsChanged={handleViewableItemsChanged}
                  data={verticalAxisEntities}
                  getItemLayout={getItemLayout}
                  renderItem={renderItem}
                />
              </VerticalSnapperProvider>
            );
          }}
        </ContentFeedVerticalDataProvider>
      </FeedSessionProvider>
    </View>
  );
};

export default ScreenContentFeedV2;
