import { mapToEntityRole } from "src/atoms/content-feed/mappers";
import { EntityRole } from "src/atoms/content-feed/types";
import { useInfiniteGetNetworkCrewQuery } from "src/gql/generated/client-cached.graphql";
import {
  GetNetworkCrewQuery,
  GetNetworkCrewQueryVariables,
} from "src/gql/generated/sdk-cached.graphql";
import { useInfiniteQuery } from "src/hooks/infinite-query/use-infinite-query";
import { paginationQueryVariables } from "src/utils/pagination-constants";
import { nestedUserExtractor } from "src/utils/ulid-extractor";

export function useNetworkCrew(slug: string) {
  return useInfiniteQuery<
    GetNetworkCrewQuery,
    EntityRole,
    GetNetworkCrewQueryVariables
  >({
    hook: useInfiniteGetNetworkCrewQuery,
    variables: { ...paginationQueryVariables, slug },
    optionsProps: {
      keyExtractor: nestedUserExtractor,
      normalizePageData: (page) =>
        page.presentable_roles_users.map((role) => mapToEntityRole(role)),
      pageSize: paginationQueryVariables.limit,
    },
  });
}
