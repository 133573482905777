import { format } from "date-fns";
import { Maybe, RecurringModel } from "src/gql/generated/client-cached.graphql";
const dayOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const useRecurringModel = (model: Maybe<RecurringModel>) => {
  if (!model || !model.time) {
    return null;
  }

  const timeUnits = model.time.split(":");
  const date = new Date();

  date.setHours(Number(timeUnits[0]));
  date.setMinutes(Number(timeUnits[1]) || 0);
  const time = format(date, "h:mmaaa");

  if (model.every_n_days === 1) {
    return {
      repeat: "Daily",
      time,
    };
  }

  if (model.every_day_of_week && model.every_day_of_week.length > 1) {
    return {
      repeat: "Weekdays",
      time,
    };
  }

  if (
    model.every_day_of_week &&
    model.every_day_of_week.length === 1 &&
    typeof model.every_day_of_week[0] === "number"
  ) {
    return {
      repeat: "Weekly",
      time: `${dayOfWeek[model.every_day_of_week[0]]} ${time}`,
    };
  }

  if (model.every_day_of_month?.length === 2) {
    return {
      repeat: "Bi-Monthly",
    };
  }

  if (model.every_day_of_month?.length === 1) {
    return {
      repeat: "Monthly",
    };
  }

  return null;
};
