import { getScaledValue } from "@rnv/renative";
import React from "react";
import { View, Image } from "react-native";
import { ICON_LOADING } from "../config";
import {
  AlignItems,
  Background,
  BackgroundSize,
  Display,
  FireImage,
  JustifyContent,
  Layout,
} from "src/ui";

const LoadingScreen = () => {
  return (
    <Layout
      display={Display.Flex}
      alignItems={AlignItems.Center}
      justifyContent={JustifyContent.Center}
      fullHeight
      background={Background.Base}
    >
      <FireImage
        src={ICON_LOADING}
        fill
        backgroundSize={BackgroundSize.Center}
      />
    </Layout>
  );
};

export default LoadingScreen;
