import React, { Fragment, useState } from "react";
import { StyleSheet, View, Image } from "react-native";
import Logo from "../Logo";
import useAuth from "../../hooks/useAuth";
import { hasWebFocusableUI, GLOW } from "../../config";
import { getScaledValue } from "@rnv/renative";
import { useNavigate } from "../../hooks/useNavigate";
import AnimatedViewOpacity from "../Animated/AnimatedViewOpacity";
import {
  AiringNow,
  AiringNowActive,
  ContentFeedActive,
  Profile,
  ProfileActive,
} from "../Icons";
import useBackKey from "../../hooks/useBackKey";
import { useGetUserQuery } from "src/gql/generated/client-cached.graphql";
import { mapQueryToUser } from "src/atoms/content-feed/mappers";
import {
  FocusContext,
  useFocusable,
} from "@noriginmedia/norigin-spatial-navigation";
import {
  Background,
  BorderRadius,
  Display,
  Interactable,
  Layout,
} from "src/ui";
import { useLogger } from "src/core/utils/logger";
import { useUILayout } from "src/atoms/layout";
import { useNestedFocus } from "src/core/utils/use-nested-focus";

const webStylePatch = hasWebFocusableUI
  ? {
      top: -getScaledValue(60),
      transform: [{ translateY: getScaledValue(60) }],
    }
  : {};
const borderRadius = getScaledValue(100 / 2);
const buttonWidth = getScaledValue(105 / 2);
const buttonHeight = getScaledValue(72 / 2);
const glowWidth = getScaledValue(46 / 2);

const styles = StyleSheet.create({
  topBarContainer: {
    position: "absolute",
    zIndex: 1,
    width: "100%",
    height: getScaledValue(60),
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: getScaledValue(40),
    paddingRight: getScaledValue(15),
    ...webStylePatch,
  },
  iconsContainer: {
    flexDirection: "row",
    width: getScaledValue(315 / 2),
    height: getScaledValue(36),
    backgroundColor: "rgba(0, 0, 0, 0.4)",
    borderRadius: borderRadius,
  },
  button: {
    width: buttonWidth,
    height: buttonHeight,
    borderRadius: borderRadius,
    justifyContent: "center",
    alignItems: "center",
  },
  glow: {
    width: glowWidth,
    height: getScaledValue(20 / 2),
    position: "absolute",
    bottom: -getScaledValue(6 / 2),
    left: (buttonWidth - glowWidth) / 2,
  },
  signOut: {
    width: getScaledValue(150 / 2),
    marginRight: getScaledValue(40 / 2),
  },
});

enum Path {
  ContentFeed = "content-feed",
  AiringNow = "airing-now",
  Profile = "profile",
}

const iconSize = {
  width: getScaledValue(32 / 2),
  height: getScaledValue(32 / 2),
};

const nav = [
  {
    key: Path.ContentFeed,
    icon: <ContentFeedActive {...iconSize} />,
    activeIcon: <ContentFeedActive {...iconSize} />,
  },
  {
    key: Path.AiringNow,
    icon: <AiringNowActive {...iconSize} />,
    activeIcon: <AiringNow {...iconSize} />,
  },
  {
    key: Path.Profile,
    icon: <Profile {...iconSize} />,
    activeIcon: <ProfileActive {...iconSize} />,
  },
];

const TopBar = () => {
  const { logger } = useLogger("TopBar");
  const { hidden } = useUILayout();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [activePath, setActivePath] = useState<Path>(Path.AiringNow);
  const { data: slug } = useGetUserQuery(
    {
      uid: user?.id || "",
    },
    { select: (data) => mapQueryToUser(data).slug }
  );

  const { ref, hasFocusedChild, focusKey, focusSelf } = useFocusable({
    trackChildren: true,
    focusKey: "MENU",
    onFocus: () => {},
  });
  const { hasNestedFocus } = useNestedFocus();

  useBackKey(() => {
    if (hidden || hasNestedFocus) {
      return false;
    }

    if (hasWebFocusableUI) {
      if (!hasFocusedChild) {
        focusSelf();
        logger.info("WEB: Backkey handled", {
          hasFocusedChild,
          hasWebFocusableUI,
        });
        return true;
      }
    } else {
      if (!Path[focusKey]) {
        focusSelf();
        logger.info("Backkey handled", {
          hasFocusedChild,
          hasWebFocusableUI,
          path: Path[focusKey],
        });
        return true;
      }

      logger.info("Backkey skipped");
      return false;
    }
  }, 1);

  const handleEnterPress = (path) => {
    setActivePath(path);
    navigate(path);
  };

  const handleProfilePress = () => {
    setActivePath(Path.Profile);
    navigate(`/${slug}`);
  };

  return (
    <AnimatedViewOpacity
      style={styles.topBarContainer}
      opacity={hidden ? 0 : 1}
    >
      <Logo />
      <FocusContext.Provider value={focusKey}>
        <Layout
          ref={ref}
          display={Display.Flex}
          background={Background.Base}
          borderRadius={BorderRadius.Full}
          flex={0}
          opacity={40}
        >
          {nav.map((n) => {
            return (
              <Fragment key={n.key}>
                <Interactable
                  style={styles.button}
                  focusKey={n.key}
                  hasTVPreferredFocus={focusKey === n.key}
                  onPress={() => handleEnterPress(n.key)}
                  onEnterPress={() => handleEnterPress(n.key)}
                >
                  {activePath === n.key ? n.activeIcon : n.icon}
                </Interactable>
                {activePath === n.key && (
                  <Image style={styles.glow} source={GLOW} />
                )}
              </Fragment>
            );
          })}
        </Layout>
      </FocusContext.Provider>
    </AnimatedViewOpacity>
  );
};

export default TopBar;
