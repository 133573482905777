import { useEffect } from "react";
import { useRecoilValue } from "recoil";
import { epgState, getChannels } from "src/atoms/airing-now/epg";

export function useEpisodeGuide() {
  const channels = useRecoilValue(epgState);

  useEffect(() => {
    getChannels();
  }, []);

  return {
    channels,
  };
}
