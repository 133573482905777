import { atom, useAtom } from "jotai";
import { useUILayout } from "src/atoms/layout";

export enum AuditoriumMode {
  Embedded = "embedded",
  Fullscreen = "fullscreen",
  Clear = "clear",
}

const modeAtom = atom(AuditoriumMode.Embedded);
export function useAuditorium() {
  const [mode, setAuditoriumMode] = useAtom(modeAtom);
  const { show, hide } = useUILayout();

  const setMode = (newMode: AuditoriumMode) => {
    if (newMode === AuditoriumMode.Embedded) {
      show();
    } else {
      hide();
    }
    setAuditoriumMode(newMode);
  };

  return {
    mode,
    setMode,
  };
}
