import { mapToSeries } from "src/atoms/content-feed/mappers";
import { Series } from "src/atoms/content-feed/types";
import { useInfiniteGetProfileSeriesQuery } from "src/gql/generated/client-cached.graphql";
import {
  GetProfileSeriesQuery,
  GetProfileSeriesQueryVariables,
} from "src/gql/generated/client-cached.graphql";
import { useInfiniteQuery } from "src/hooks/infinite-query/use-infinite-query";
import {
  maxDisplayedItems,
  pageSize,
  paginationQueryVariables,
} from "src/utils/pagination-constants";
import { slugExtractor } from "src/utils/ulid-extractor";

export function useUserSeries(slug) {
  return useInfiniteQuery<
    GetProfileSeriesQuery,
    Series,
    GetProfileSeriesQueryVariables
  >({
    hook: useInfiniteGetProfileSeriesQuery,
    variables: {
      slug,
      ...paginationQueryVariables,
    },
    optionsProps: {
      pageSize,
      keyExtractor: slugExtractor,
      maxCount: maxDisplayedItems,
      normalizePageData: (page: GetProfileSeriesQuery) =>
        page.series
          .filter(Boolean)
          .map((item) => mapToSeries({ series: item })),
    },
  });
}
