import { useEffect, useState } from "react";
import { Episode } from "src/atoms/airing-now/epg";
import axios from "src/utils/axios";

export interface PlayerData {
  url: string;
}

export function useChannelVideo(
  channelID: string,
  episode: Episode,
  isLive?: boolean
) {
  const [data, setData] = useState<PlayerData | null>(null);

  useEffect(() => {
    const loadChannel = async () => {
      const response = await axios.get(`/playback/channel/${channelID}`);
      setData(response.data);
    };

    if (isLive) {
      loadChannel();
    } else if (episode.playbackUrl) {
      setData({ url: episode.playbackUrl });
    } else {
      setData(null);
    }
  }, []);

  return { data };
}
