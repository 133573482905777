import React, { createContext, useEffect, useReducer } from "react";
import { auth } from "../lib/firebase";
import LoadingScreen from "../components/LoadingScreen";
import { useSession } from "src/core/auth/use-session";
import { IdTokenResult } from "firebase/auth";
import { Maybe } from "src/gql/generated/client-fresh.graphql";

const initialAuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "AUTH_STATE_CHANGED": {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user,
      };
    }
    case "USER_CHANGED": {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user,
      };
    }
    default: {
      return { ...state };
    }
  }
};

export interface AuthUser {
  id: string;
  avatar: string;
  email: string;
  name: string;
}

export interface AuthContextState {
  isAuthenticated: boolean;
  isInitialised: boolean;
  user: AuthUser | null;
  method: "FirebaseAuth";
  signInWithCustomToken: (token: string) => Promise<void>;
  logout: () => Promise<void>;
}

const AuthContext = createContext<AuthContextState>({
  ...initialAuthState,
  method: "FirebaseAuth",
  signInWithCustomToken: (customToken) => Promise.resolve(),
  logout: () => Promise.resolve(),
});

export const AuthProvider = ({ children }) => {
  const { setUser, setToken } = useSession();
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  const signInWithCustomToken = (customToken) => {
    return auth.signInWithCustomToken(customToken);
  };

  const logout = () => {
    setToken(null);
    return auth.signOut();
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user && !user.isAnonymous) {
        const formattedUser = {
          id: user.uid,
          avatar: user.photoURL || "",
          email: user.email || "",
          name: user.displayName || user.email || "",
        };

        const tokenRes = await user.getIdTokenResult();
        setToken(tokenRes as any as Maybe<IdTokenResult>);
        setUser(formattedUser);
        dispatch({
          type: "AUTH_STATE_CHANGED",
          payload: {
            isAuthenticated: true,
            user: formattedUser,
          },
        });
      } else {
        setUser(null);
        dispatch({
          type: "AUTH_STATE_CHANGED",
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    });

    return unsubscribe;
  }, [dispatch]);

  if (!state.isInitialised) {
    return <LoadingScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "FirebaseAuth",
        signInWithCustomToken,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
