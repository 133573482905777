import { useAtomValue } from "jotai";
import flatten from "lodash/flatten";
import { useEffect, useMemo } from "react";

import { useInfiniteGetHorizontalFeedEntitiesQuery } from "../../../gql/generated/client-fresh.graphql";

import {
  mapQueryItemToFeedEntity,
  VideoFeedEntity,
} from "../../../atoms/content-feed/types";
import { useSession } from "src/core/auth/use-session";

type Props = {
  active: boolean;
  activeIndex: number;
  children: (childrenProps: { entities: VideoFeedEntity[] }) => JSX.Element;
  verticalEntity: VideoFeedEntity;
};

export const ContentFeedHorizontalDataProvider = ({
  active,
  activeIndex,
  children,
  verticalEntity,
}: Props) => {
  const { deviceID } = useSession();

  const { data, fetchNextPage, hasNextPage, isFetching } =
    useInfiniteGetHorizontalFeedEntitiesQuery(
      {
        device_id: deviceID,
        clip_ulid: verticalEntity.ulid,
        chat_ulid: "",
      },
      {
        enabled: active && Boolean(verticalEntity.networkSlug),
        getNextPageParam: (fetchedData) =>
          fetchedData.feedGetRelatedClips &&
          fetchedData.feedGetRelatedClips.length > 0
            ? {
                device_id: deviceID,
                clip_ulid: verticalEntity.ulid,
                chat_ulid: "",
              }
            : undefined,
        select: (fetchedData) => ({
          pageParams: fetchedData.pageParams,
          pages: fetchedData.pages.map(
            (page) =>
              (page.feedGetRelatedClips || [])
                .map((item) => mapQueryItemToFeedEntity(item))
                .filter(Boolean) as VideoFeedEntity[]
          ),
        }),
      }
    );

  const entities = useMemo(
    () => [verticalEntity, ...flatten(data?.pages || [])],
    [data?.pages, verticalEntity]
  );

  useEffect(() => {
    const delta = entities.length - activeIndex;

    if (delta < 3 && hasNextPage && !isFetching) {
      fetchNextPage();
    }
  }, [activeIndex, entities.length, fetchNextPage, hasNextPage, isFetching]);

  return children({ entities });
};
