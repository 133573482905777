import {
  Children,
  PropsWithChildren,
  useContext,
  useRef,
  useState,
} from "react";
import { FlatList, StyleProp, View, ViewStyle } from "react-native";
import FocusGroup, { FocusGroupProps } from "../FocusGroup";
import { RailContext, RailContextProvider } from "./context";
import { Layout } from "../Layout";
import { UseFocusableConfig } from "@noriginmedia/norigin-spatial-navigation";

interface RailProps {
  style?: StyleProp<ViewStyle>;
  contentContainerStyle?: StyleProp<ViewStyle>;
  canLoadMore?: boolean;
  onLoadMore?: () => void;
  vertical?: boolean;
  initialScrollIndex?: number;
  getItemLayout?: (
    data: any,
    index: number
  ) => { length: number; offset: number; index: number };
  nestedScroll?: boolean;
}

type Props = RailProps & FocusGroupProps & UseFocusableConfig;

export const useRailContext = () => useContext(RailContext);

// onEndReachedThreshold={1}
// onViewableItemsChanged={handleViewableItemsChanged}
// getItemLayout={getItemLayout}

export const Rail: React.FC<PropsWithChildren<Props>> = ({
  children,
  id,
  style,
  contentContainerStyle,
  canLoadMore,
  onLoadMore,
  vertical,
  focusOnMount,
  preferredChildFocusKey,
  initialScrollIndex,
  getItemLayout,
  isFocusBoundary,
  nestedScroll,
}) => {
  const data = Children.toArray(children);
  const scrollRef = useRef<FlatList>(null);
  const [parentFocused, setParentFocused] = useState(false);
  const constraints = vertical ? "max-h-full" : "max-w-full";

  return (
    <Layout
      className={`rail-container ${constraints}`}
      fullWidth={!vertical}
      fullHeight={vertical}
    >
      <FocusGroup
        id={id}
        focusOnMount={focusOnMount}
        preferredChildFocusKey={preferredChildFocusKey}
        onHasFocusedChild={(newFocus) => {
          if (parentFocused !== newFocus) {
            setParentFocused(newFocus);
          }
        }}
        isFocusBoundary={isFocusBoundary}
      >
        <FlatList
          style={style}
          contentContainerStyle={contentContainerStyle}
          ref={scrollRef}
          data={data}
          renderItem={({ item, index }) => {
            return (
              <RailContextProvider
                sliderRef={scrollRef.current}
                itemIndex={index}
                parentFocused={parentFocused}
              >
                {item}
              </RailContextProvider>
            );
          }}
          showsHorizontalScrollIndicator={false}
          horizontal={!vertical}
          decelerationRate="fast"
          onEndReachedThreshold={0.8}
          onEndReached={() => {
            if (canLoadMore) {
              onLoadMore?.();
            }
          }}
          extraData={parentFocused}
          initialNumToRender={3}
          windowSize={5}
          getItemLayout={getItemLayout}
          initialScrollIndex={initialScrollIndex}
          nestedScrollEnabled={nestedScroll}
        />
      </FocusGroup>
    </Layout>
  );
};
