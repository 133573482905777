import React, { useEffect, useState } from "react";
import { useGetChatStartTimeQuery } from "../../../../gql/generated/client-cached.graphql";
import { FeedMessage, VideoFeedEntity } from "../../../../atoms/content-feed/types";
import { View, Text } from "../../../../components/Tw";
import { getVideoEntityUlids } from "./actions/VideoEntityContext";
import { useQuery } from "@tanstack/react-query";
import { firestore } from "../../../../lib/firebase";
import range from "lodash/range";
import flatten from "lodash/flatten";
import { messageMapper } from "../../utils";
import { FlatList, ScrollView } from "react-native";
import VideoEntityFeedMessage from "./VideoEntityFeedMessage";
import { useAtomValue } from "jotai";
import { globalPlayerCurrentTimeSecondsAtom } from "../../../../atoms/content-feed/activeIndexes";
import moment from "moment";

type Props = {
  videoEntity: VideoFeedEntity;
};

const addSeconds = (date: Date, amount: number) => {
  date.setSeconds(date.getSeconds() + amount);
  return date;
};

const VideoEntityFeed = ({ videoEntity }: Props) => {
  const globalPlayerCurrentTimeSeconds = useAtomValue(globalPlayerCurrentTimeSecondsAtom);
  const [displayClipReactions, setDisplayClipReactions] = useState<any>([]);
  const { chatUlid, clipUlid } = getVideoEntityUlids(videoEntity);

  const { data: showStartDate } = useGetChatStartTimeQuery(
    {
      chat_ulid: chatUlid
    },
    {
      select: (data) => {
        const fetchedStartDate = data.chats_by_pk?.recording_started_at || data.chats_by_pk?.started_at;

        if (!fetchedStartDate) {
          return null;
        }

        return new Date(fetchedStartDate);
      }
    }
  );

  const { data: allClipReactions } = useQuery(
    ["clip-reactions", clipUlid],
    async () => {
      if (videoEntity.type !== "clip") {
        return [];
      }

      const { startSeconds, endSeconds } = videoEntity;
      const messagesCollection = firestore.doc(`/all_chats/rooms/chats/${chatUlid}`).collection("reaction_messages");

      const clipDuration = endSeconds - startSeconds;
      const clipStart = addSeconds(showStartDate!, startSeconds);

      const segments = 4;
      const segmentDuration = clipDuration / segments;

      const result = await Promise.all(
        range(0, segments, 1).map((i) => {
          return messagesCollection
            .where("Timestamp", ">=", addSeconds(clipStart, segmentDuration * i))
            .where("Timestamp", "<=", addSeconds(clipStart, segmentDuration * (i + 1)))
            .orderBy("Timestamp", "asc")
            .limit(50)
            .get()
            .then((querySnapshot) => querySnapshot);
        })
      );

      return flatten(result.map((item) => item.docs))
        .filter((item) => item.data().Effect !== "firestore_collection_seed")
        .map((item) => messageMapper(item.data(), item.id));
    },
    { enabled: Boolean(showStartDate && clipUlid) }
  );

  useEffect(() => {
    if (!showStartDate || !allClipReactions) {
      return;
    }

    const currentPlaybackDate = addSeconds(showStartDate, globalPlayerCurrentTimeSeconds);

    const potentialNewReactions = allClipReactions.filter((item) => moment(item.date).isBefore(currentPlaybackDate));
    if (potentialNewReactions.length !== displayClipReactions.length) {
      const startIndex = potentialNewReactions.length - 6;
      const endIndex = potentialNewReactions.length - 1;
      setDisplayClipReactions(potentialNewReactions.slice(startIndex > 0 ? startIndex : 0, endIndex));
    }
  }, [globalPlayerCurrentTimeSeconds]);

  //   console.log("potentialNewReactions", globalPlayerCurrentTimeSeconds, displayClipReactions);
  return (
    <ScrollView showsVerticalScrollIndicator={false}>
      {displayClipReactions.map((n: FeedMessage) => (
        <VideoEntityFeedMessage key={n.uid} item={n} />
      ))}
    </ScrollView>
  );
};

export default VideoEntityFeed;
