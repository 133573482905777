import { useEffect, useState } from "react";

class Logger {
  static category: string[] = [];
  private categorySnapshot;

  constructor() {
    this.categorySnapshot = Logger.category.slice();
  }

  private getCategoryMessage() {
    return this.categorySnapshot.map((c) => `[${c}]`).join("");
  }

  static withCategory(category: string) {
    Logger.category.push(category);
    return new Logger();
  }

  static removeCategory(category: string) {
    Logger.category = Logger.category.filter((c) => c !== category);
  }

  public info(message: string, ...optionalParams: any[]) {
    console.info(
      `%c${this.getCategoryMessage()}[INFO]:`,
      "color: #4466ff; padding: 2px",
      message,
      ...optionalParams
    );
  }

  public debug(message: string, ...optionalParams: any[]) {
    console.debug(
      `%c${this.getCategoryMessage()}[DEBUG]:`,
      "color: #cc33aa; padding: 2px",
      message,
      ...optionalParams
    );
  }

  public errorAndReport(message: string, ...optionalParams: any[]) {
    console.error(
      `${this.getCategoryMessage()} [ERROR]:`,
      message,
      ...optionalParams
    );
  }
}

const logger = new Logger();

export function useLogger(category?: string) {
  const [log, setLogger] = useState<Logger>(logger);

  useEffect(() => {
    if (category) {
      setLogger(Logger.withCategory(category));
    }

    () => {
      if (category) {
        Logger.removeCategory(category);
      }
    };
  }, [category]);

  return {
    logger,
  };
}
