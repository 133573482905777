import {
  AlignItems,
  Card,
  Display,
  Layout,
  TextType,
  FireImage,
  FireText,
} from "src/ui";

interface Props {
  image: string;
  title: string;
}

export const ProfileHeading: React.FC<Props> = ({ image, title }) => {
  return (
    <Layout
      name="profile-heading__container"
      display={Display.Flex}
      alignItems={AlignItems.Center}
    >
      <Card>
        <FireImage src={image} fill />
      </Card>
      <Layout margin={{ l: 5 }}>
        <FireText type={TextType.H3}>{title}</FireText>
      </Layout>
    </Layout>
  );
};
