import { PropsWithChildren } from "react";
import { View } from "react-native";
import { Aspect } from "src/ui";

export enum CardSize {
  Small = "small",
  Medium = "medium",
  Large = "large",
  XLarge = "xlarge",
}

export interface CardProps {
  size?: CardSize;
  aspect?: Aspect;
}

type Props = CardProps;

export const Card: React.FC<PropsWithChildren<Props>> = ({
  children,
  size = CardSize.Small,
  aspect = Aspect.Square,
}) => {
  const sizeVariants = {
    small: "w-[120px]",
    medium: "w-[240px]",
    large: "w-[320px]",
    xlarge: "w-[440px]",
  };

  const aspectVariants = {
    square: "aspect-square",
    wide: "aspect-video",
  };

  return (
    <View
      className={`${sizeVariants[size]} ${aspectVariants[aspect]} rounded-3xl overflow-hidden`}
    >
      {children}
    </View>
  );
};
