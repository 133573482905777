import { EntityType } from "src/atoms/content-feed/enums";
import { useGetProfileSlugDataQuery } from "src/gql/generated/client-cached.graphql";

export interface SlugInfo {
  slug: string;
  entityType?: EntityType;
  isLoading?: boolean;
}

export function useSlugData(slug = ""): SlugInfo {
  const { data, isLoading } = useGetProfileSlugDataQuery({
    slug,
  });

  let entityType: EntityType = EntityType.USER;
  if (data?.networks.length) {
    entityType = EntityType.NETWORK;
  }

  if (data?.series.length) {
    entityType = EntityType.SERIES;
  }

  if (data?.chats.length) {
    entityType = EntityType.SHOW;
  }

  if (data?.users.length) {
    entityType = EntityType.USER;
  }

  return {
    slug,
    entityType,
    isLoading,
  };
}
