import { useIntl } from "react-intl";
import { mapToShow } from "src/atoms/content-feed/mappers";
import { Show } from "src/atoms/content-feed/types";
import {
  GetSeriesUpcomingChatsQuery,
  GetSeriesUpcomingChatsQueryVariables,
  useInfiniteGetSeriesUpcomingChatsQuery,
} from "src/gql/generated/client-cached.graphql";
import { useInfiniteQuery } from "src/hooks/infinite-query/use-infinite-query";
import { ProfileRail } from "src/screens/Profile/components/Rail";
import { ShowCard } from "src/screens/Show/components/Card";
import { useNavigateToShow } from "src/screens/Show/utils/use-url-path";
import { BorderRadius, Interactable, Layout } from "src/ui";
import {
  maxDisplayedItems,
  pageSize,
  paginationQueryVariables,
} from "src/utils/pagination-constants";
import { slugExtractor } from "src/utils/ulid-extractor";

interface Props {
  slug: string;
}

export const SeriesUpcomingShows: React.FC<Props> = ({ slug }) => {
  const { formatMessage } = useIntl();
  const {
    items: shows,
    hasMore,
    fetchNextPage,
    isFetched,
    isFetchingNextPage,
  } = useInfiniteQuery<
    GetSeriesUpcomingChatsQuery,
    Show,
    GetSeriesUpcomingChatsQueryVariables
  >({
    hook: useInfiniteGetSeriesUpcomingChatsQuery,
    variables: {
      slug,
      ...paginationQueryVariables,
    },
    optionsProps: {
      normalizePageData: (page) =>
        page.chatsForSeries.scheduled.map((chat) => mapToShow({ chat })),
      keyExtractor: slugExtractor,
      pageSize,
      maxCount: maxDisplayedItems,
    },
  });
  const navigate = useNavigateToShow();

  if (isFetched && !shows.length) {
    return null;
  }

  return (
    <Layout margin={{ y: 10 }}>
      <ProfileRail
        title={formatMessage({ defaultMessage: "Upcoming Shows" })}
        canLoadMore={!!shows.length && hasMore && !isFetchingNextPage}
        onLoadMore={fetchNextPage}
      >
        {shows.map((show) => (
          <Interactable
            borderRadius={BorderRadius.XXXL}
            onEnterPress={navigate(show)}
          >
            <ShowCard key={show.slug} show={show} />
          </Interactable>
        ))}
      </ProfileRail>
    </Layout>
  );
};
