import { IdTokenResult } from "firebase/auth";
import { Maybe } from "src/gql/generated/client-fresh.graphql";

export const hasHasuraClaim = (
  token?: Maybe<IdTokenResult>
): token is IdTokenResult => {
  if (!token) {
    return false;
  }

  return !!(token.claims.claims as { "x-hasura-user-id": string })?.[
    "x-hasura-user-id"
  ];
};
