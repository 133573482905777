import React, { useEffect, useRef } from "react";
import ReactHlsPlayer from "../../components/Players/HlsPlayer";
import VideoJsPlayer from "../../components/Players/VideoJsPlayer";

const HlsPlayer = React.memo((props) => <ReactHlsPlayer {...props} />);

// Notes:
// Tizen: Handle playback button - 2 ways
// 1. setMediaKeyEventListener/ unsetMediaKeyEventListener: It will show playback hint + forward event to the app
//    https://docs.tizen.org/application/web/guides/multimedia/media-key/ This feature is supported in mobile and wearable applications only!!!
// 2. window.tizen.tvinputdevice.registerKeyBatch: It hide playback hint
//    https://developer.samsung.com/smarttv/develop/guides/user-interaction/remote-control.html

const VideoPlayer = ({ onReady, onPlay, onEnded, onClose, onPause, onStop, data, options = {}, useVideoJs = true, useControls = false, useCoverMode }) => {
  const playerRef = useRef();

  const {url} = data;
  useEffect(() => {
    if (url) {
      playerRef.current?.play();
    }
  }, [url])

  useEffect(() => {
    if (useControls) {
      document.addEventListener("keydown", handleKeyDown);
      window.tizen?.mediakey?.setMediaKeyEventListener({});
    }

    return () => {
      if (useControls) {
        document.removeEventListener("keydown", handleKeyDown);
        window.tizen?.mediakey?.unsetMediaKeyEventListener();
      }
    };
  }, []);

  const handleKeyDown = (e) => {
    if (e.key === "Escape" || e.key === "Backspace" || e.keyCode === 10009 || e.keyCode === 461) {
      e.stopImmediatePropagation();
      onClose?.();
      return;
    }

    const player = playerRef.current;
    player.focus();

    switch (e.keyCode) {
      case 13: // Enter
        if (player.paused) {
        } else {
          player.classList.remove("nocontrols");
          onPause?.();
        }
        break;
      case 38: // ArrowUp
        player.classList.remove("nocontrols");
        player.setAttribute("controls", "controls");
        break;
      case 40: // ArrowDown
        player.classList.add("nocontrols");
        break;
      case 37: // ArrowLeft
      case 39: // ArrowRight
        if (useVideoJs) {
          playerRef.current.currentTime(playerRef.current.currentTime());
        }
        player.classList.remove("nocontrols");
        break;
      case 415: // Play
        player.play();
        break;
      case 19: // Pause
        player.pause();
        player.classList.remove("nocontrols");
        onPause?.();
        break;
      case 10252: // PlayPause
        if (player.paused) {
          player.play();
        } else {
          player.pause();
          player.classList.remove("nocontrols");
          onPause?.();
        }
        break;
      case 413: // Stop
        player.pause();
        if (useVideoJs) {
          playerRef.current.currentTime(0);
        } else {
          player.currentTime = 0;
        }
        player.classList.remove("nocontrols");
        onStop?.();
        break;
      case 412: // Rewind
        if (useVideoJs) {
          playerRef.current.currentTime(playerRef.current.currentTime() - 60);
        } else {
          player.currentTime -= 60;
        }
        player.classList.remove("nocontrols");
        break;
      case 417: // FastForward
        if (useVideoJs) {
          playerRef.current.currentTime(playerRef.current.currentTime() + 60);
        } else {
          player.currentTime += 60;
        }
        player.classList.remove("nocontrols");
        break;
      default:
    }
  };

  if (useVideoJs) {
    return (
      <VideoJsPlayer
        options={{
          bigPlayButton: false,
          errorDisplay: false,
          controls: useControls,
          autoplay: true,
          sources: [{ src: data.url }],
          ...options
        }}
        onReady={(player) => {
          playerRef.current = player;

          if (useCoverMode) {
            player.children_[0].style.objectFit = "cover";
          }

          player.play();

          onReady?.(player);
        }}
        onPlay={() => {
          onPlay?.();
        }}
      />
    );
  }

  const handleHlsReady = (player) => {
    onReady?.(player);
  };

  return data && data.url.includes(".m3u8") && <HlsPlayer url={data.url} controls={useControls} onReady={handleHlsReady} onPlay={onPlay} onEnded={onEnded} />;
};

export default VideoPlayer;
