import flatMap from "lodash/flatMap";
import uniqBy from "lodash/uniqBy";

import {
  GetChatAudienceQuery,
  GetChatQuery,
  GetEmojiByUlidQuery,
  GetLobbySectionEntitiesQuery,
  GetSeriesQuery,
  GetShowQuery,
  GetUserQuery,
  Maybe,
  RecurringModel,
} from "../../gql/generated/client-cached.graphql";
import { GetRedirectChatQuery } from "../../gql/generated/client-fresh.graphql";
import {
  Entitlements,
  EntityRole as EntityRoleEnum,
  EntityType,
  ShowPublishedState,
  ShowState,
  UserRole,
} from "./enums";
import {
  ChatMembership,
  ChatMembershipStatus,
  Emoji,
  EntityOverrideData,
  EntityRole,
  Network,
  Series,
  Show,
  User,
} from "./types";
import { GetUserProfileQuery } from "src/gql/generated/sdk-cached.graphql";

type EnumObj =
  | typeof ChatMembershipStatus
  | typeof ShowPublishedState
  | typeof ShowState
  | typeof UserRole
  | typeof EntityRoleEnum
  | typeof EntityType;

export const mapToEnum = <T extends EnumObj>(
  input: Maybe<string>,
  enumObj: T,
  defaultValue: T[keyof T]
): T[keyof T] => {
  if (input) {
    const entries = Object.entries(enumObj);

    for (let i = 0; i < entries.length; i += 1) {
      if (input === entries[i][1]) {
        return enumObj[entries[i][0] as keyof T];
      }
    }
  }

  return defaultValue;
};

type EntityRoleInput = Maybe<
  Partial<{
    role: Maybe<string>;
    user: Maybe<UserInput>;
  }>
>;

type SeriesInput = Maybe<
  Partial<{
    description: Maybe<string>;
    category_ulids: Maybe<string[]>;
    recurring_model: unknown;
    cover_image_url: Maybe<string>;
    display_name: string;
    slug: string;
    format: Maybe<NonNullable<GetSeriesQuery["series_by_pk"]>["format"]>;
    series_roles: ReadonlyArray<EntityRoleInput>;
    networks: Maybe<ReadonlyArray<Maybe<NetworkInput>>>;
    content_restriction: Maybe<string>;
    presentable_entitlements: ReadonlyArray<{
      entitlement?: Maybe<{ type: string }>;
    }>;
    parents: NonNullable<GetSeriesQuery["series_by_pk"]>["parents"];
  }>
>;

type UserInput = Maybe<
  Partial<{
    uid: string;
    slug: string;
    display_name: Maybe<string>;
    avatar_url_v2: Maybe<string>;
    agora_id: Maybe<number>;
    bio: Maybe<string>;
  }>
>;

export type NetworkInput = Maybe<
  GetLobbySectionEntitiesQuery["lobby_entities"][number]["network"]
>;

type ChatInput = {
  chat: Maybe<
    Partial<{
      ulid: Maybe<string>;
      title: Maybe<string>;
      description: Maybe<string>;
      scheduled_at: Maybe<string>;
      started_at: Maybe<string>;
      cover_image_url: Maybe<string>;
      state: Maybe<string>;
      preview_url: Maybe<string>;
      published_state: Maybe<string>;
      chat_roles: Maybe<ReadonlyArray<Maybe<EntityRoleInput>>>;
      chats_series: Maybe<
        ReadonlyArray<
          Maybe<{
            series?: SeriesInput;
          }>
        >
      >;
      creator: UserInput;
      external_show_id: Maybe<string>;
      networks: Maybe<ReadonlyArray<Maybe<NetworkInput>>>;
      parents: Maybe<NonNullable<GetChatQuery["chats_by_pk"]>["parents"]>;
      slug: Maybe<string>;
      presentable_entitlements: ReadonlyArray<{
        entitlement?: Maybe<{ type: string }>;
      }>;
      content_restriction: Maybe<string>;
    }>
  >;
  overrideData?: Maybe<EntityOverrideData>;
};

export const mapToUser = (user: UserInput): User => ({
  agoraId: user?.agora_id || null,
  avatarUrl: user?.avatar_url_v2 || "",
  bio: user?.bio || "",
  displayName: user?.display_name || "",
  slug: user?.slug || "",
  uid: user?.uid || "",
});

export const mapToEntityRole = (input: EntityRoleInput): EntityRole => ({
  role: input?.role
    ? mapToEnum(input.role, EntityRoleEnum, EntityRoleEnum.GUEST)
    : EntityRoleEnum.GUEST,
  user: mapToUser(input?.user),
});

export const mapToNetwork = (network: NetworkInput): Network => ({
  description: network?.description || "",
  imageUrl: network?.image_url || "",
  coverImageUrl: network?.cover_image_url || network?.image_url || "",
  learnMoreUrl: network?.learn_more_url || null,
  name: network?.name || "",
  orderPriority: network?.order_priority || 0,
  slug: network?.slug || "",
});

const mapToEntitlement = (presentable: ChatInput["chat"] | SeriesInput) => {
  if (presentable?.presentable_entitlements?.length) {
    const membership = presentable.presentable_entitlements.find(
      (item) => item.entitlement?.type === Entitlements.MEMBERSHIP
    );

    return membership ? Entitlements.MEMBERSHIP : Entitlements.FANDOM;
  }

  if (presentable?.content_restriction) {
    return presentable.content_restriction as Entitlements;
  }

  return Entitlements.PUBLIC;
};

const mapParentsToNetworkInputs = (
  parents: NonNullable<ChatInput["chat"]>["parents"]
) =>
  (parents || []).reduce<NetworkInput[]>((networkInputs, parentObj) => {
    if (parentObj.parent?.networks) {
      networkInputs.push(parentObj.parent.networks);
    }

    return networkInputs;
  }, []);

export const mapToSeries = ({ series }: { series?: SeriesInput }): Series => {
  return {
    coverImageUrl: series?.cover_image_url || null,
    displayName: series?.display_name || "",
    slug: series?.slug || "",
    roles: (series?.series_roles || []).map((role) => mapToEntityRole(role)),
    description: series?.description || "",
    categoryUlids: series?.category_ulids || [],
    format: series?.format || null,
    recurringModel: (series?.recurring_model as Maybe<RecurringModel>) || null,
    networks: (
      series?.networks || mapParentsToNetworkInputs(series?.parents)
    ).map((network) => mapToNetwork(network)),
    contentRestriction: mapToEntitlement(series),
  };
};

export const mapToShow = ({ chat, overrideData }: ChatInput): Show => {
  const showSeries = (chat?.chats_series || [])
    .filter(Boolean)
    .map((series) => mapToSeries(series!));

  return {
    audioTrailerUrl: chat?.preview_url || null,
    coverImageUrl: overrideData?.coverImageUrl || chat?.cover_image_url || null,
    description: overrideData?.description || chat?.description || "",
    publishedState: mapToEnum(
      chat?.published_state,
      ShowPublishedState,
      ShowPublishedState.HIDDEN
    ),
    roles: (chat?.chat_roles || [])
      .filter(Boolean)
      .map((chatRole) => mapToEntityRole(chatRole!)),
    scheduledAt: chat?.scheduled_at || null,
    startedAt: chat?.started_at || null,
    series: showSeries,
    state: mapToEnum(chat?.state, ShowState, ShowState.INVALID),
    title: overrideData?.title || chat?.title || "",
    ulid: chat?.ulid || "",
    videoTrailerUrl: overrideData?.videoTrailerUrl || chat?.preview_url || null,
    creator: mapToUser(chat?.creator),
    externalShowId: chat?.external_show_id || null,
    networks: (() => {
      const directNetworks = (
        chat?.networks ||
        mapParentsToNetworkInputs(chat?.parents) ||
        []
      ).reduce<Network[]>((networks, network) => {
        if (network) {
          networks.push(mapToNetwork(network));
        }

        return networks;
      }, []);

      const indirectNetworks = flatMap(showSeries, (series) => series.networks);

      return uniqBy(
        [...directNetworks, ...indirectNetworks],
        (item) => item.slug
      );
    })(),
    slug: chat?.slug || "",
    contentRestriction: mapToEntitlement(chat),
  };
};

type ChatMembershipInput =
  | NonNullable<GetRedirectChatQuery["chats_by_pk"]>["chat_memberships"][number]
  | NonNullable<
      NonNullable<GetChatAudienceQuery["getActiveChatParticipants"]>[number]
    >;

type EmojiInput =
  | GetEmojiByUlidQuery["emojis_by_pk"]
  | ChatMembershipInput["emoji"];

export const mapToEmoji = (emoji: EmojiInput): Emoji => ({
  animatedUrl: emoji?.animated_url_v2 || null,
  staticUrl: emoji?.image_url_v2 || "",
  animated: Boolean(emoji?.animated_url_v2),
  name: emoji?.name || "",
  ulid: emoji?.ulid || "",
});

export const mapToChatMembership = (
  input: ChatMembershipInput
): ChatMembership => ({
  status: mapToEnum(
    input.status,
    ChatMembershipStatus,
    ChatMembershipStatus.LEFT
  ),
  emoji: mapToEmoji(input.emoji),
  muted: Boolean(input.is_muted),
  onStage: Boolean(input.is_on_stage),
  role: mapToEnum(input.role, EntityRoleEnum, EntityRoleEnum.GUEST),
  sharingVideoAt: input.sharing_video_at || null,
  user: mapToUser(input.user),
  chatUlid: input.chat_ulid || "",
});

type ChatInputQuery =
  | GetShowQuery
  | GetChatQuery
  | GetLobbySectionEntitiesQuery["lobby_entities"][number];

export const mapQueryToShow = (input: ChatInputQuery) => {
  let chat: any = null;
  let overrideData: any = null;

  if ("chats_by_pk" in input) {
    chat = input.chats_by_pk;
    overrideData = input.lobby_entities[0]?.data as Maybe<EntityOverrideData>;
  } else if ("chat" in input) {
    chat = input.chat;
    overrideData = input.data as Maybe<EntityOverrideData>;
  }

  return mapToShow({
    chat,
    overrideData,
  });
};

type UserInputQuery = GetUserQuery & Partial<GetUserProfileQuery>;

export const mapQueryToUser = (input: UserInputQuery) => {
  if (input.users_by_pk) {
    return mapToUser(input.users_by_pk);
  } else if (input.users && input.users.length) {
    return mapToUser(input.users[0]);
  }

  throw "Input not valid.";
};

type SeriesInputQuery = GetSeriesQuery;

export const mapQueryToSeries = (input: SeriesInputQuery) =>
  mapToSeries({ series: input.series_by_pk });
