import { PropsWithChildren } from "react";
import { Layout } from "../../Layout";
import { Background, BorderRadius } from "src/ui/models";

interface Props {}

export const ModalContainer: React.FC<PropsWithChildren<Props>> = ({
  children,
}) => {
  return (
    <Layout
      padding={10}
      background={Background.Overlay}
      borderRadius={BorderRadius.XL}
      className="modal__container"
    >
      {children}
    </Layout>
  );
};
