import { useIntl } from "react-intl";
import { mapToShow } from "src/atoms/content-feed/mappers";
import { Show } from "src/atoms/content-feed/types";
import {
  GetSeriesReplayChatsQuery,
  GetSeriesReplayChatsQueryVariables,
  useInfiniteGetSeriesReplayChatsQuery,
} from "src/gql/generated/client-cached.graphql";
import { useInfiniteQuery } from "src/hooks/infinite-query/use-infinite-query";
import { ProfileRail } from "src/screens/Profile/components/Rail";
import { ShowCard } from "src/screens/Show/components/Card";
import { useNavigateToShow } from "src/screens/Show/utils/use-url-path";
import { BorderRadius, Interactable, Layout } from "src/ui";
import {
  maxDisplayedItems,
  paginationQueryVariables,
} from "src/utils/pagination-constants";
import { ulidExtractor } from "src/utils/ulid-extractor";

interface Props {
  slug: string;
}

export const SeriesRecentShows: React.FC<Props> = ({ slug }) => {
  const { formatMessage } = useIntl();
  const {
    items: shows,
    hasMore,
    isFetched,
    isFetchingNextPage,
    fetchNextPage,
  } = useInfiniteQuery<
    GetSeriesReplayChatsQuery,
    Show,
    GetSeriesReplayChatsQueryVariables
  >({
    hook: useInfiniteGetSeriesReplayChatsQuery,
    variables: {
      slug,
      ...paginationQueryVariables,
    },
    optionsProps: {
      keyExtractor: ulidExtractor,
      normalizePageData: (page) =>
        page.chatsForSeries.hosted_published.map((chat) => mapToShow({ chat })),
      pageSize: paginationQueryVariables.limit,
      maxCount: maxDisplayedItems,
    },
  });
  const navigate = useNavigateToShow();

  if (!shows.length && isFetched) {
    return null;
  }

  return (
    <Layout margin={{ y: 10 }}>
      <ProfileRail
        title={formatMessage({ defaultMessage: "Recent Shows" })}
        canLoadMore={!!shows.length && hasMore && !isFetchingNextPage}
        onLoadMore={fetchNextPage}
      >
        {!shows.length && <ShowCard loading show={null} />}
        {shows.map((s) => (
          <Interactable
            key={`RecentShows_${s.slug}`}
            borderRadius={BorderRadius.XXXL}
            onEnterPress={navigate(s)}
          >
            <ShowCard show={s} />
          </Interactable>
        ))}
      </ProfileRail>
    </Layout>
  );
};
