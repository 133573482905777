import {
  Component,
  ComponentType,
  MemoExoticComponent,
  PropsWithChildren,
} from "react";
import { Interactable, InteractableProps } from "../Interactable";
import { BorderRadius } from "src/ui";
import { View } from "src/components/Tw";
import { UseFocusableConfig } from "@noriginmedia/norigin-spatial-navigation";
import { PressableProps } from "react-native";

export interface IconButtonProps {}

type Props = IconButtonProps &
  PressableProps &
  InteractableProps &
  UseFocusableConfig;

export const IconButton: React.FC<PropsWithChildren<Props>> = ({
  children,
  focusKey,
  ...otherProps
}) => {
  return (
    <Interactable
      focusKey={focusKey}
      borderRadius={BorderRadius.Full}
      {...otherProps}
    >
      <View className="flex-0 items-center justify-center rounded-full bg-gradient-to-br from-fs-gray-start to-fs-gray-stop border-solid border-2 border-white/10 w-20 h-20">
        {children}
      </View>
    </Interactable>
  );
};
