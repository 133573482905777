import { useCallback } from "react";
import { useIntl } from "react-intl";
import { EntityRole } from "src/atoms/content-feed/enums";
import { EntityRole as EntityRoleItem } from "src/atoms/content-feed/types";
import { useNavigate } from "src/hooks/navigation";
import { ProfileRail } from "src/screens/Profile/components/Rail";
import { UserAvatar } from "src/screens/User/components/Avatar";
import { Layout, useRailContext } from "src/ui";

interface Props {
  roles: EntityRoleItem[];
}

export const SeriesCastAndCrew: React.FC<Props> = ({ roles }) => {
  const { formatMessage } = useIntl();

  if (!roles.length) {
    return null;
  }

  const sortedRoles = useCallback(() => {
    return roles.sort((r) =>
      r.role === EntityRole.NETWORK_HOST || r.role === EntityRole.HOST ? -1 : 1
    );
  }, [roles]);

  return (
    <Layout margin={{ y: 10 }}>
      <ProfileRail
        title={formatMessage({
          defaultMessage: "Cast & Crew",
        })}
        canLoadMore={false}
        onLoadMore={() => {}}
      >
        {sortedRoles().map((r) => (
          <Item key={`CastAndCrew_${r.user.uid}`} entity={r} />
        ))}
      </ProfileRail>
    </Layout>
  );
};

const Item: React.FC<{ entity: EntityRoleItem }> = ({ entity }) => {
  const { sliderRef, itemIndex } = useRailContext();
  const navigate = useNavigate();
  return (
    <UserAvatar
      key={`CastAndCrewRail_${entity.user.uid}`}
      user={entity.user}
      host={
        entity.role === EntityRole.NETWORK_HOST ||
        entity.role === EntityRole.HOST
      }
      onEnterPress={() => {
        navigate(`/${entity.user.slug}`);
      }}
      onFocus={() => {
        if (sliderRef) {
          sliderRef.scrollToIndex({
            animated: true,
            index: itemIndex,
          });
        }
      }}
    />
  );
};
