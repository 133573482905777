import {
  FocusContext,
  UseFocusableConfig,
  useFocusable,
} from "@noriginmedia/norigin-spatial-navigation";
import { PropsWithChildren, useEffect } from "react";
import { View } from "react-native";

export interface FocusGroupProps {
  id: string;
  focusOnMount?: boolean;
  onHasFocusedChild?: (hasFocusedChild: boolean) => void;
}

type Props = FocusGroupProps & UseFocusableConfig;

const FocusGroup: React.FC<PropsWithChildren<Props>> = ({
  id,
  children,
  onFocus,
  focusOnMount,
  preferredChildFocusKey,
  onHasFocusedChild,
  isFocusBoundary,
}) => {
  const { ref, focusSelf, focusKey, hasFocusedChild } = useFocusable({
    trackChildren: true,
    focusKey: id,
    onFocus,
    preferredChildFocusKey,
    isFocusBoundary,
  });

  useEffect(() => {
    onHasFocusedChild?.(hasFocusedChild || false);
  }, [hasFocusedChild]);

  useEffect(() => {
    if (focusOnMount) {
      focusSelf();
    }
  }, []);

  return (
    <FocusContext.Provider value={focusKey}>
      {/**
       * Max Height and Max Width are for TV
       * This may need to change for web
       */}
      <View className="max-h-full max-w-full" ref={ref}>
        {children}
      </View>
    </FocusContext.Provider>
  );
};

export default FocusGroup;
