import { VideoFeedEntity } from "../../../atoms/content-feed/types";
import { useVideoEntityPosterUrl } from "./useVideoEntityPosterUrl";
import { useVideoEntitySource } from "./useVideoEntitySource";

type UseClipPosterInput = {
  videoEntity: VideoFeedEntity;
  landscape: boolean;
};

export const useVideoEntityPoster = ({ videoEntity, landscape }: UseClipPosterInput) => {
  const source = useVideoEntitySource({
    videoEntity,
    landscape
  });

  const posterUrl = useVideoEntityPosterUrl({
    videoEntity,
    source
  });

  const posterVisible = true;

  return {
    posterUrl,
    posterVisible
  };
};
