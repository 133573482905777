import ReactQRCode from "react-qr-code";
interface Props {
  value: string;
}

export const QRCode: React.FC<Props> = ({ value }) => {
  return (
    <ReactQRCode
      size={360}
      style={{ height: "auto", maxWidth: "100%", width: "100%" }}
      value={value}
      viewBox={`0 0 360 360`}
    />
  );
};
