import React, { useEffect, useMemo, useRef } from "react";
import { View, StyleSheet, Image, FlatList } from "react-native";
import { vodState, pushNavigation, popNavigation } from "../../atoms/vod";
import VodDetails from "./VodDetails";
import Player from "../Player";
import { useNavigate } from "../../hooks/useNavigate";
import { playerState, stop } from "../../atoms/player";
import { useRecoilState } from "recoil";
import LoadingScreen from "../../components/LoadingScreen";
import _ from "lodash";
import LinearGradient from "../../components/LinearGradient";
import useBackKey from "../../hooks/useBackKey";
import { FireText, Layout, Rail, TextType } from "src/ui";
import FocusGroup from "src/ui/components/FocusGroup";
import { Item } from "./Item";
import { useUILayout } from "src/atoms/layout";

const styles = StyleSheet.create({
  header: {
    display: "flex",
    height: "50%",
  },
  detailsContainer: {
    minHeight: 100,
    marginLeft: 80,
    marginTop: 150,
  },
  image: {
    backgroundColor: "#000000",
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: -1,
  },
  logo: {
    height: 30,
    width: 216,
    marginLeft: 80,
    marginTop: 45,
  },
  bottomGradient: {
    position: "absolute",
    top: "50%",
    height: "50%",
    width: "100%",
    zIndex: -1,
  },
  leftGradient: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: "80%",
    zIndex: -1,
  },
});

let timerId;
const ScreenHome = () => {
  // const { setFocus, pause, resume } = useFocusable();
  const navigate = useNavigate();
  const [{ data }, setPlayerState] = useRecoilState<any>(playerState);
  const [vod, setVodState] = useRecoilState<any>(vodState);
  const firstRender = useRef(false);
  const categoryScroller = useRef<HTMLDivElement>(null);
  const { hide, show } = useUILayout();

  const navigations = vod.navigations;
  const curNavigation = _.last(navigations);
  let activeItem = curNavigation?.focusedVod?.item;

  useEffect(() => {
    if (navigations === null) {
      firstRender.current = true;
    }

    if (!activeItem) return;

    if (firstRender.current) {
      timerId = setTimeout(() => {
        navigate("airing-now");
      }, 2 * 1000);
    }

    return () => {
      clearTimeout(timerId);
    };
  }, [navigations, activeItem]);

  useBackKey(
    useMemo(
      () => () => {
        if (navigations?.length > 1) {
          popNavigation(setVodState, vod);
          return true;
        }

        if (data) {
          return true;
        }

        console.log("[VOD] Back key skipped");
        return false;
      },
      [navigations, data]
    )
  );

  useEffect(() => {
    pushNavigation(setVodState, vod, null);
  }, []);

  useEffect(() => {
    if (data) {
      hide();
      // pause();
    } else {
      show();
      // resume();
    }
  }, [data]);

  if (!navigations) {
    return <LoadingScreen />;
  }

  return (
    <View style={{ flex: 1 }}>
      {data && (
        <View
          style={{
            position: "absolute",
            height: "100%",
            width: "100%",
            backgroundColor: "#000",
            zIndex: 9999,
          }}
        >
          <Player
            useVideoJs={false}
            useControls
            data={data}
            onClose={() => {
              stop(setPlayerState);
              return true;
            }}
          />
        </View>
      )}

      <View style={styles.header}>
        <View style={styles.detailsContainer}>
          <VodDetails item={activeItem} />
        </View>
      </View>

      <Image style={styles.image} source={{ uri: activeItem?.backgroundUrl }} />

      <LinearGradient
        style={styles.bottomGradient}
        useAngle
        angle={180}
        colors={["rgba(17, 17, 17, 0)", "rgba(17, 17, 17, 1)"]}
      />
      <LinearGradient
        style={styles.leftGradient}
        useAngle
        angle={270}
        colors={["rgba(0, 0, 0, 0)", "rgba(0, 0, 0, 1)"]}
      />

      <div
        ref={categoryScroller}
        style={{ scrollPaddingTop: "70px", overflowY: "scroll" }}
      >
        {curNavigation.rows.map((r, index) => {
          return (
            <>
              <Layout margin={{ t: 12, b: 5, l: 20 }}>
                <FireText type={TextType.H4}>{r.title}</FireText>
              </Layout>
              <FocusGroup
                id={`Category_${r.title}`}
                onFocus={(item) => {
                  categoryScroller.current?.scrollTo({
                    top: item.y - 100,
                    behavior: "smooth",
                  });
                }}
                focusOnMount={index === 0}
              >
                <Rail
                  id={`Row_${r.title}`}
                  contentContainerStyle={{
                    marginHorizontal: 80,
                  }}
                >
                  {r.items.map((item) => (
                    <Item item={item} rowIndex={index} />
                  ))}
                </Rail>
              </FocusGroup>
            </>
          );
        })}
      </div>
    </View>
  );
};

export default ScreenHome;
