import { mapToShow } from "src/atoms/content-feed/mappers";
import { Show } from "src/atoms/content-feed/types";
import {
  GetHostedPublishedChatsForUserQuery,
  GetHostedPublishedChatsForUserQueryVariables,
  useInfiniteGetHostedPublishedChatsForUserQuery,
} from "src/gql/generated/client-cached.graphql";
import { useInfiniteQuery } from "src/hooks/infinite-query/use-infinite-query";
import {
  maxDisplayedItems,
  pageSize,
  paginationQueryVariables,
} from "src/utils/pagination-constants";
import { ulidExtractor } from "src/utils/ulid-extractor";

export function useUpcoming(slug: string) {
  return useInfiniteQuery<
    GetHostedPublishedChatsForUserQuery,
    Show,
    GetHostedPublishedChatsForUserQueryVariables
  >({
    hook: useInfiniteGetHostedPublishedChatsForUserQuery,
    optionsProps: {
      keyExtractor: ulidExtractor,
      normalizePageData: (page) =>
        page.chatsForUser.hosted_published.map((chat) => mapToShow({ chat })),

      pageSize: paginationQueryVariables.limit,
      maxCount: maxDisplayedItems,
    },
    variables: {
      ...paginationQueryVariables,
      slug,
    },
  });
}
