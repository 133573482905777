import React, { createContext, useContext } from "react";

const VerticalSnapperContext = createContext({ sliderRef: null, activeIndexes: null, snapToNext: () => {} });

const VerticalSnapperProvider = ({ children, sliderRef, activeIndexes, setFocus }) => {
  const snapToNext = () => {
    if (setFocus) {
      setFocus(`slider_${activeIndexes.vertical + 1}`);
    } else {
      sliderRef.current.scrollToIndex({ index: activeIndexes.vertical + 1 });
    }
  };

  return (
    <VerticalSnapperContext.Provider
      value={{
        sliderRef,
        activeIndexes,
        snapToNext
      }}
    >
      {children}
    </VerticalSnapperContext.Provider>
  );
};

export default VerticalSnapperProvider;

export function useVerticalSnapperContext() {
  return useContext(VerticalSnapperContext);
}
