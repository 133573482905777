import { useState } from "react";
import { useToast } from "react-native-toast-notifications";
import useBackKey from "../useBackKey";
import { useNavigate } from "../useNavigate";
import { useLocation } from "../useLocation";
import { hasWebFocusableUI } from "../../config";
import {
  AuditoriumMode,
  useAuditorium,
} from "src/screens/Auditorium/hooks/use-auditorium";
import { useModal } from "src/ui";
import { useLogger } from "src/core/utils/logger";
import { useNestedFocus } from "src/core/utils/use-nested-focus";

const useExit = () => {
  const { logger } = useLogger();
  const navigate = useNavigate();
  const location = useLocation();

  const toast = useToast();
  const [isPending, setIsPending] = useState(false);

  const { mode } = useAuditorium();
  const { modalActive } = useModal();
  const { hasNestedFocus } = useNestedFocus();

  // TODO: Find the correct check
  const canGoBack = () => {
    console.log("canGoBack", location);
    // if (hasWebFocusableUI) {
    //   return history.state.idx > 0;
    // } else {
    //   return true;
    // }

    return location.key !== "default";
  };

  useBackKey(() => {
    if (mode !== AuditoriumMode.Embedded || modalActive || hasNestedFocus) {
      logger.info("Back Key Navigation Interupped", {
        auditoriumMode: mode,
        modalActive,
        hasNestedFocus,
      });
      return false;
    }

    if (canGoBack() && !location.pathname.includes("/login")) {
      console.log("-1");
      navigate(-1);
      return true;
    } else {
      if (isPending) {
        if (hasWebFocusableUI) {
          console.log("Exit");
          window.close();
          return false;
        } else {
          console.log("Exit");
          return false;
        }
      }

      setIsPending(true);
      setTimeout(() => setIsPending(false), 3000);
      toast.show("Press back again to exit the app");
      return true;
    }
  }, 0);
};

export default useExit;
