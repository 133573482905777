import { Background, Layout, Position, Rail } from "src/ui";
import { useEpisodeGuide } from "../../hooks/use-episode-guide";
import LoadingScreen from "src/components/LoadingScreen";
import { Dimensions } from "react-native";
import { ChannelRail } from "../ChannelRail";
import { Glow } from "../Glow";
import {
  AuditoriumMode,
  useAuditorium,
} from "src/screens/Auditorium/hooks/use-auditorium";

export const AiringNowScreenV2: React.FC = () => {
  const { channels } = useEpisodeGuide();
  const { mode } = useAuditorium();

  if (!channels) {
    return <LoadingScreen />;
  }

  return (
    <Layout
      background={Background.Alt}
      fullWidth
      fullHeight
      className={`transition-all duration-500 ${
        mode !== AuditoriumMode.Embedded ? "scale-143" : "scale-100"
      }`}
    >
      <Glow />
      <Rail
        contentContainerStyle={{
          paddingVertical: Dimensions.get("window").height * 0.125,
        }}
        id="AiringNow"
        vertical
      >
        {channels.map((channel) => (
          <ChannelRail key={`ChannelRail_${channel.id}`} channel={channel} />
        ))}
      </Rail>
    </Layout>
  );
};
