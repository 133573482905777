import { atom, useAtom } from "jotai";

const nestedFocusAtom = atom(false);

export function useNestedFocus() {
  const [hasNestedFocus, setNestedFocus] = useAtom(nestedFocusAtom);

  return {
    hasNestedFocus,
    setNestedFocus,
  };
}
