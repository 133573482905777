import React, { useEffect, useState } from "react";
import {
  ImageBackground,
  View,
  StyleSheet,
  ActivityIndicator,
} from "react-native";
import { Text } from "react-native-paper";
import { getScaledValue } from "@rnv/renative";
import axios from "../../utils/axios";
import useAuth from "../../hooks/useAuth";
import { BACKGROUND } from "../../config";
import { QRCode } from "src/ui";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  qrContainer: {
    padding: getScaledValue(30 / 2),
    backgroundColor: "#FFF",
    width: getScaledValue(320 / 2),
    height: getScaledValue(320 / 2),
    borderRadius: getScaledValue(36 / 2),
    justifyContent: "center",
    alignItems: "center",
  },
});

const ScreenQrLogin = () => {
  const { signInWithCustomToken } = useAuth();

  const [isLoading, setIsLoading] = useState(true);
  const [linkCode, setLinkCode] = useState();
  const [expired, setExpired] = useState(false);

  useEffect(() => {
    let timerId;
    let count = 0;
    axios({
      method: "POST",
      url: "/externalToken",
    })
      .then((res) => {
        console.log(res);
        setIsLoading(false);
        setLinkCode(res.data);
        timerId = setInterval(() => {
          if (count < 60) {
            axios({
              method: "GET",
              url: `/externalToken?code=${res.data}`,
            })
              .then(async (res) => {
                clearInterval(timerId);
                const token = res.data.token;
                console.log(token);
                signInWithCustomToken(token);
              })
              .catch(() => {
                console.log("Not Linked Yet");
              });
            count++;
          } else {
            setExpired(true);
            clearInterval(timerId);
            console.log("Expired");
          }
        }, 10 * 1000);
      })
      .catch((err) => {
        console.log(err);
      });

    return () => {
      clearInterval(timerId);
    };
  }, []);
  console.log("Auth Url", `https://ctv.firesidechat.com/link?code=${linkCode}`);
  return (
    <ImageBackground style={styles.container} source={BACKGROUND}>
      <View style={styles.qrContainer}>
        {isLoading ? (
          <ActivityIndicator />
        ) : expired ? (
          <Text style={{ color: "#000", textAlign: "center" }}>
            Operation timed out. Please close and reopen the app to try again
          </Text>
        ) : (
          <QRCode
            value={`https://ctv.firesidechat.com/link?code=${linkCode}`}
          />
        )}
      </View>
    </ImageBackground>
  );
};

export default ScreenQrLogin;
