import { useCallback, useMemo, useRef } from "react";

import {
  GetNetworkReplayChatsDocument,
  GetNetworkReplayChatsQuery,
  GetNetworkReplayChatsQueryVariables,
  GetNetworkUpcomingChatsDocument,
  GetNetworkUpcomingChatsQuery,
  GetNetworkUpcomingChatsQueryVariables,
} from "src/gql/generated/client-cached.graphql";
import { fetchCachedQuery } from "src/gql/fetch-cached-query";
import { Show } from "src/atoms/content-feed/types";
import { mapToShow } from "src/atoms/content-feed/mappers";
import { ulidExtractor } from "src/utils/ulid-extractor";
import {
  createFetcher,
  CreateFetcherInput,
  FetcherState,
  useStitchedInfiniteQuery,
} from "src/hooks/use-stitched-infinite-query";

const networkHighlightsCreateFetcherStaticOptions: Pick<
  CreateFetcherInput<
    Show,
    GetNetworkUpcomingChatsQuery,
    GetNetworkUpcomingChatsQueryVariables,
    GetNetworkReplayChatsQuery,
    GetNetworkReplayChatsQueryVariables
  >,
  "name" | "firstQueryNormalizer" | "secondQueryNormalizer"
> = {
  name: "GetNetworkHighlights",
  firstQueryNormalizer: (page) =>
    page.presentable_relationships.map((item) =>
      mapToShow({ chat: item.child?.presentable?.chats, overrideData: null })
    ),
  secondQueryNormalizer: (page) =>
    page.presentable_relationships.map((item) =>
      mapToShow({ chat: item.child?.presentable?.chats, overrideData: null })
    ),
};

type Props = {
  slug: string;
};

export const useHighlights = ({ slug }: Props) => {
  const fetcherState = useRef<FetcherState>({
    firstQueryDone: false,
    secondQueryOffset: 0,
  });

  const firstQueryFetcher = useCallback(
    (firstQueryVariables: GetNetworkUpcomingChatsQueryVariables) =>
      fetchCachedQuery<
        GetNetworkUpcomingChatsQuery,
        GetNetworkUpcomingChatsQueryVariables
      >(GetNetworkUpcomingChatsDocument, {
        ...firstQueryVariables,
      })(),
    []
  );

  const secondQueryFetcher = useCallback(
    (secondQueryVariables: GetNetworkReplayChatsQueryVariables) =>
      fetchCachedQuery<
        GetNetworkReplayChatsQuery,
        GetNetworkReplayChatsQueryVariables
      >(GetNetworkReplayChatsDocument, {
        ...secondQueryVariables,
      })(),
    []
  );

  const { fetcher, queryKey } = useMemo(
    () =>
      createFetcher<
        Show,
        GetNetworkUpcomingChatsQuery,
        GetNetworkUpcomingChatsQueryVariables,
        GetNetworkReplayChatsQuery,
        GetNetworkReplayChatsQueryVariables
      >({
        ...networkHighlightsCreateFetcherStaticOptions,
        fetcherState: fetcherState.current,
        firstQueryFetcher,
        firstQueryVariables: {
          slug,
        },
        secondQueryFetcher,
        secondQueryVariables: { slug },
      }),
    [firstQueryFetcher, secondQueryFetcher, slug]
  );

  return useStitchedInfiniteQuery<Show>({
    fetcher,
    queryKey,
    keyExtractor: ulidExtractor,
  });
};
