import { IconButton } from "src/ui";
import { Notification } from "src/components/Icons";
import { useFollowNetwork } from "./use-follow";
import NotificationChecked from "src/components/Icons/NotificationChecked";
import useAuth from "src/hooks/useAuth";

interface Props {
  slug: string;
}

export const NetworkFollowButton: React.FC<Props> = ({ slug }) => {
  const { user } = useAuth();
  const { followed, handleChange } = useFollowNetwork(slug, user?.id || "");

  return (
    <IconButton onEnterPress={handleChange}>
      {followed ? <NotificationChecked /> : <Notification />}
    </IconButton>
  );
};
