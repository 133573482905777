import React from "react";
import { Image } from "react-native";
//@ts-ignore
import LIVE_NOW from "../../../../platformAssets/runtime/live-now.svg";

const LiveNow = ({ width = 264, height = 102 }) => {
  return <Image style={{ width: width, height: height }} source={LIVE_NOW} />;
};

export default LiveNow;
