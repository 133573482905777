import { mapQueryToUser } from "src/atoms/content-feed/mappers";
import { useGetUserProfileQuery } from "src/gql/generated/client-cached.graphql";
import { ProfileBackdrop } from "src/screens/Profile/components/Backdrop";
import {
  Background,
  Color,
  Display,
  FlexDirection,
  Layout,
  FireText,
} from "src/ui";
import { UserProfileHeading } from "../ProfileHeading";
import HighlightsRail from "src/screens/Profile/components/HighlightsRail";
import { UserSeries } from "../Series";
import { UserNetworkAffiliations } from "../NetworkAffiliations";
import { UserAppearedIn } from "../AppearedIn";
import { UserProduced } from "../Produced";
import { UserUpcoming } from "../UpcomingShows";

interface Props {
  slug: string;
}

export const UserProfile: React.FC<Props> = ({ slug }) => {
  const { data: user, isLoading } = useGetUserProfileQuery(
    {
      slug,
    },
    {
      staleTime: Number.POSITIVE_INFINITY,
      enabled: Boolean(slug),
      select: mapQueryToUser,
    }
  );

  if (isLoading || !user) {
    return null;
  }

  return (
    <Layout
      name="user-profile__container"
      display={Display.Flex}
      flexDirection={FlexDirection.Column}
      background={Background.Base}
      grow
      padding={{ b: 16 }}
    >
      <ProfileBackdrop imageSrc={user.avatarUrl} />
      <Layout
        name="user-profile__content"
        margin={{ t: 80 }}
        padding={{ x: 20 }}
      >
        <UserProfileHeading
          image={user.avatarUrl}
          title={user.displayName}
          handle={user.slug}
        />
        <Layout margin={{ y: 5 }}>
          <FireText color={Color.Alt}>{user.bio}</FireText>
        </Layout>
      </Layout>
      <HighlightsRail slug={user.slug} />
      <UserNetworkAffiliations uid={user.uid} />

      <UserSeries slug={user.slug} />
      <UserAppearedIn uid={user.uid} />
      <UserProduced uid={user.uid} />
      <UserUpcoming slug={user.slug} />
    </Layout>
  );
};
