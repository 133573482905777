import { UserAvatar } from "src/screens/User/components/Avatar";
import { useNavigate } from "src/hooks/navigation";
import { EntityRole as EntityRoleType } from "src/atoms/content-feed/enums";
import { ProfileRail } from "src/screens/Profile/components/Rail";
import { useNetworkCrew } from "./use-crew";
import { useIntl } from "react-intl";
import { Layout, useRailContext } from "src/ui";
import { EntityRole } from "src/atoms/content-feed/types";

interface Props {
  slug: string;
}

export const NetworkCastAndCrew: React.FC<Props> = ({ slug }) => {
  const { formatMessage } = useIntl();
  const {
    hasMore,
    fetchNextPage,
    isFetchingNextPage,
    items: users,
  } = useNetworkCrew(slug);

  return (
    <ProfileRail
      title={formatMessage({
        defaultMessage: "Network Cast & Crew",
      })}
      canLoadMore={!!users.length && hasMore && !isFetchingNextPage}
      onLoadMore={fetchNextPage}
    >
      {!users.length && <UserAvatar user={null} loading />}
      {users.map((entity) => (
        <Item key={`CastAndCrewRail_${entity.user.uid}`} entity={entity} />
      ))}
    </ProfileRail>
  );
};

const Item: React.FC<{ entity: EntityRole }> = ({ entity }) => {
  const { sliderRef, itemIndex } = useRailContext();
  const navigate = useNavigate();

  return (
    <UserAvatar
      user={entity.user}
      host={entity.role === EntityRoleType.NETWORK_HOST}
      onEnterPress={() => {
        navigate(`/${entity.user.slug}`);
      }}
      onFocus={() => {
        if (sliderRef) {
          sliderRef.scrollToIndex({
            animated: true,
            index: itemIndex,
          });
        }
      }}
    />
  );
};
