import { memo } from "react";
import Svg, { Path } from "react-native-svg";

interface Props {
  size?: number;
}

const Star: React.FC<Props> = ({ size }) => {
  const s = size ?? 30;
  return (
    <Svg width={s} height={s} viewBox={`0 0 ${s} ${s}`} fill="none">
      <Path
        d="M12.8652 4.40524C13.5549 3.06289 13.8997 2.39172 14.3631 2.17439C14.7666 1.98518 15.2334 1.98518 15.6369 2.17439C16.1003 2.39172 16.4451 3.06289 17.1348 4.40524L19.3362 8.69025C19.5393 9.08562 19.6409 9.2833 19.7861 9.4376C19.9148 9.57426 20.068 9.68555 20.2377 9.7657C20.4294 9.8562 20.6488 9.8917 21.0876 9.9627L25.8431 10.7322C27.3329 10.9733 28.0778 11.0938 28.4277 11.4675C28.7323 11.7927 28.8765 12.2366 28.8213 12.6788C28.7578 13.1868 28.226 13.7221 27.1625 14.7928L23.7675 18.2106C23.4542 18.526 23.2976 18.6836 23.1957 18.8695C23.1055 19.0341 23.047 19.2142 23.0232 19.4004C22.9964 19.6106 23.0304 19.8302 23.0985 20.2695L23.8362 25.0301C24.0673 26.5214 24.1828 27.2671 23.9356 27.7153C23.7204 28.1056 23.3428 28.3799 22.9052 28.464C22.4025 28.5606 21.729 28.2203 20.3821 27.5396L16.0824 25.367C15.6857 25.1665 15.4873 25.0663 15.2791 25.0268C15.0947 24.9919 14.9053 24.9919 14.7209 25.0268C14.5127 25.0663 14.3143 25.1665 13.9176 25.367L9.61793 27.5396C8.271 28.2203 7.59753 28.5606 7.09484 28.464C6.6572 28.3799 6.27961 28.1056 6.06439 27.7153C5.81719 27.2671 5.93274 26.5214 6.16383 25.0301L6.9015 20.2695C6.96957 19.8302 7.0036 19.6106 6.97675 19.4004C6.95298 19.2142 6.89447 19.0341 6.80426 18.8695C6.7024 18.6836 6.54578 18.526 6.23254 18.2106L2.83753 14.7928C1.77398 13.7221 1.24221 13.1868 1.17874 12.6788C1.12347 12.2366 1.2677 11.7927 1.57233 11.4675C1.92224 11.0938 2.66712 10.9733 4.15688 10.7322L8.91244 9.9627C9.35122 9.8917 9.57061 9.8562 9.76226 9.7657C9.932 9.68555 10.0852 9.57426 10.2139 9.4376C10.3591 9.2833 10.4607 9.08561 10.6638 8.69025L12.8652 4.40524Z"
        stroke="white"
        strokeWidth="2.25"
        strokeLinecap="round"
      />
    </Svg>
  );
};

const Memo = memo(Star);
export default Memo;
