import ky from "ky-universal";
import { sdk } from "../sdk/sdk";
import { Maybe } from "../../../gql/generated/client-cached.graphql";

type Input = {
  ulid: string;
  start: number;
  end: number;
};

export type ReplaySnapshot = {
  active_headline: string;
  active_speaker_user_id: Maybe<string>;
  muted_user_ids: string[];
  on_stage_user_ids: string[];
  participant_user_ids: string[];
  reactions_by_user_id: Record<string, string>;
  roles_by_user_ids: Record<string, string>;
  video_sharing_user_ids: string[];
};

export default async (input: Input) => {
  const { end, start, ulid } = input;

  const delta = end - start;

  if (delta < 0 || delta > 30) {
    return [];
  }

  try {
    const queryResult = await sdk.GetReplaySnapshotsUrl({ chat_ulid: ulid });

    if (!queryResult.chatSnapshotURL.snapshot_url) {
      return [];
    }

    const filenames = Array.from({ length: delta + 1 }).map((_, i) => `${start + i}.json`);

    const snapshots = await Promise.all(
      filenames.map((filename) =>
        ky
          .get(`${queryResult.chatSnapshotURL.snapshot_url}${filename}`)
          .json<ReplaySnapshot>()
          .then((query) => query)
          .catch((e) => {
            return null;
          })
      )
    );

    return snapshots;
  } catch (e) {
    return [];
  }
};
