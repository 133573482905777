/* eslint-disable react/destructuring-assignment */
import { useContext } from "react";
import { Image } from "../../../../../components/Tw";
import { useGetNetworkQuery } from "../../../../../gql/generated/client-cached.graphql";

import { mapToNetwork } from "src/atoms/content-feed/mappers";
import { VideoEntityContext } from "./VideoEntityContext";
import { BorderRadius, Interactable } from "src/ui";

export const VideoEntityActionNetwork = () => {
  const videoEntity = useContext(VideoEntityContext);

  const { data: networkCoverImageUrl } = useGetNetworkQuery(
    { slug: videoEntity.networkSlug! },
    {
      enabled: Boolean(videoEntity.networkSlug),
      select: (data) => {
        const network = mapToNetwork(data.networks_by_pk);

        return network.imageUrl || network.coverImageUrl;
      },
    }
  );

  if (!networkCoverImageUrl) {
    return null;
  }

  return (
    <Interactable borderRadius={BorderRadius.XXXL}>
      <Image
        className="h-12 w-12 cursor-pointer rounded-xl lg:h-14 lg:w-14"
        source={{ uri: networkCoverImageUrl }}
      />
    </Interactable>
  );
};
