import { FeedMessage, FirestoreFeedMessage } from "../../atoms/content-feed/types";

export const messageMapper = (firebaseMessage: FirestoreFeedMessage, id: string): FeedMessage => ({
  uid: firebaseMessage.User.UID,
  effect: firebaseMessage.Effect,
  role: firebaseMessage.User.Role,
  emojiUrl: firebaseMessage.EmojiUrl,
  emojiName: firebaseMessage.EmojiName,
  message: firebaseMessage.Message,
  speaker: firebaseMessage.User.Speaker,
  avatar: firebaseMessage.User.AvatarURLV2 || firebaseMessage.User.AvatarURL,
  slug: firebaseMessage.User.Username,
  displayName: firebaseMessage.User.DisplayName,
  date: new Date(1000 * firebaseMessage.Timestamp.seconds),
  id
});
