import { useQueryClient } from "@tanstack/react-query";
import { useAtomValue } from "jotai";
import { useEffect } from "react";
import { useCreateFeedSessionMutation } from "../../../gql/generated/client-fresh.graphql";
import { useSession } from "src/core/auth/use-session";

type FeedSessionProviderProps = {
  children: JSX.Element;
};

export const FeedSessionProvider = ({ children }: FeedSessionProviderProps) => {
  const { mutateAsync: createFeedSession, isSuccess } =
    useCreateFeedSessionMutation();
  const { deviceID } = useSession();

  const queryClient = useQueryClient();

  useEffect(() => {
    if (!deviceID) {
      return;
    }

    (async () => {
      try {
        queryClient.removeQueries(["GetVerticalFeedEntities.infinite"]);
        queryClient.removeQueries(["GetHorizontalFeedEntities.infinite"]);

        await createFeedSession({ device_id: deviceID });
      } catch (e) {}
    })();
  }, [createFeedSession, deviceID, queryClient]);

  if (!isSuccess) {
    return null;
  }

  return children;
};
