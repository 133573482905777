import { parse } from "graphql";
import { getQueryName } from "./get-query-name";
import { sdkWithMetadata } from "./server/sdk/sdk";

export const fetchCachedQuery =
  <TData, TVariables>(query: string, variables?: TVariables): (() => Promise<TData>) =>
  async () => {
    const doc = parse(query);
    const operation = getQueryName(doc);

    const result = await sdkWithMetadata(operation as any, variables);

    return result.data;
  };
