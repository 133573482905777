import { EnterPressHandler } from "@noriginmedia/norigin-spatial-navigation";
import { Show } from "src/atoms/content-feed/types";
import { Maybe } from "src/gql/generated/client-cached.graphql";
import { useNavigate } from "src/hooks/navigation";

type ShowNavigationHandler = (show: Show | null) => EnterPressHandler;

export function useNavigateToShow(): ShowNavigationHandler {
  const navigate = useNavigate();
  return (show) => {
    if (!show) {
      return () => {};
    }

    return () => {
      navigate(`/${show.slug}?id=${show.ulid}`);
    };
  };
}
