import { mapToShow } from "src/atoms/content-feed/mappers";
import { useGetShowQuery } from "src/gql/generated/client-cached.graphql";
import {
  Background,
  BorderRadius,
  Button,
  Color,
  Display,
  FireText,
  FlexDirection,
  IconButton,
  Interactable,
  Layout,
  TextType,
  useModal,
} from "src/ui";
import { ShowHeading } from "../Heading";
import { ProfileBackdrop } from "src/screens/Profile/components/Backdrop";
import { useShowCover } from "../../utils/use-cover";
import { FormattedMessage, useIntl } from "react-intl";
import { SeriesCategories } from "src/screens/Series/components/Categories";
import { useLogger } from "src/core/utils/logger";
import FocusGroup from "src/ui/components/FocusGroup";
import { QRModal } from "src/components/QRModal";
import QRCode from "src/components/Icons/QRCode";
import { ProfileRail } from "src/screens/Profile/components/Rail";
import { NetworkCard } from "src/screens/Network/components/Card";
import { SeriesCard } from "src/screens/Series/components/Card";
import { SeriesCastAndCrew } from "src/screens/Series/CastAndCrew";
import { useNavigate } from "src/hooks/navigation";

interface Props {
  slug: string;
}

export const ShowProfile: React.FC<Props> = ({ slug }) => {
  const { logger } = useLogger();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const { showModal } = useModal();
  const { data: show } = useGetShowQuery(
    { slug },
    { select: (data) => mapToShow({ chat: data.chats[0] }) }
  );

  if (!show) {
    return null;
  }

  logger.info("Show Details", { show });

  const showCover = useShowCover(
    show?.coverImageUrl || "",
    show?.series?.[0]?.coverImageUrl || ""
  );

  const categoryNames = show.series[0].categoryUlids?.map(
    (id) =>
      show.series[0].format?.categories.find((cat) => cat.ulid === id)?.name ||
      ""
  );

  return (
    <Layout
      name="show-profile__container"
      display={Display.Flex}
      flexDirection={FlexDirection.Column}
      background={Background.Alt}
      grow
      padding={{ b: 16 }}
    >
      <ProfileBackdrop imageSrc={showCover} />
      <Layout
        name="show-profile__content"
        margin={{ t: 80 }}
        padding={{ x: 20 }}
      >
        <ShowHeading show={show} />
        {(!!show.roles.length || !!categoryNames?.length) && (
          <Layout margin={{ t: 4 }}>
            <FireText type={TextType.Micro}>
              <FormattedMessage
                defaultMessage="<Alt>Hosts:</Alt> {hosts}"
                values={{
                  hosts: show.roles.map((r) => r.user.displayName).join(","),
                  Alt: (text) => (
                    <FireText type={TextType.Micro} color={Color.Alt}>
                      {text}
                    </FireText>
                  ),
                }}
              />
            </FireText>
            {!!categoryNames?.length && (
              <SeriesCategories names={categoryNames} />
            )}
          </Layout>
        )}
        <Layout margin={{ y: 5 }}>
          <FireText color={Color.Alt}>{show.description}</FireText>
        </Layout>
        <FocusGroup id="SHOW_PROFILE_BUTTONS">
          <Layout
            name="show-profile__buttons"
            display={Display.Flex}
            margin={{ t: 2 }}
          >
            <Layout margin={{ r: 4 }}>
              <Button
                onEnterPress={() => {
                  showModal(
                    <QRModal
                      path={slug}
                      text={formatMessage({
                        defaultMessage: "Scan to Join",
                      })}
                    />
                  );
                }}
              >
                {formatMessage({ defaultMessage: "Join Membership" })}
              </Button>
            </Layout>
            <IconButton
              onEnterPress={() => {
                showModal(<QRModal path={slug} />);
              }}
            >
              <QRCode />
            </IconButton>
          </Layout>
        </FocusGroup>
      </Layout>
      <Layout margin={{ y: 10 }}>
        <ProfileRail
          title={formatMessage({ defaultMessage: "Network Affiliations" })}
          onLoadMore={() => {}}
          canLoadMore={false}
        >
          {show.networks.map((n) => {
            return (
              <NetworkCard
                key={n.slug}
                slug={n.slug}
                image={n.imageUrl}
                name={n.name}
              />
            );
          })}
        </ProfileRail>
      </Layout>
      <Layout margin={{ y: 10 }}>
        <ProfileRail
          title={formatMessage({ defaultMessage: "Series" })}
          onLoadMore={() => {}}
          canLoadMore={false}
        >
          {show.series.map((s) => {
            return (
              <Interactable
                borderRadius={BorderRadius.XXXL}
                onEnterPress={() => {
                  navigate(`/${s.slug}`);
                }}
              >
                <SeriesCard key={s.slug} series={s} />
              </Interactable>
            );
          })}
        </ProfileRail>
      </Layout>
      <SeriesCastAndCrew roles={show.roles} />
    </Layout>
  );
};
