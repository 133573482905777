import { atom } from "jotai";
import { VideoFeedEntity } from "./types";

export type ActiveIndexes = {
  vertical: number;
  horizontal: number;
  item: VideoFeedEntity | null;
};

export const activeIndexesAtom = atom<ActiveIndexes>({
  vertical: 0,
  horizontal: 0,
  item: null,
});

export const playingAtom = atom(false);

export const globalPlayerCurrentTimeSecondsAtom = atom(0);
