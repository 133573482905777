import { Entitlements } from "src/atoms/content-feed/enums";
import {
  AlignItems,
  Aspect,
  Card,
  CardSize,
  Color,
  Display,
  FireImage,
  FireText,
  Layout,
  TextType,
} from "src/ui";
import Lock from "src/components/Icons/Lock";
import { ShowCard } from "../Card";
import { Show } from "src/atoms/content-feed/types";

interface Props {
  show: Show;
}

export const ShowHeading: React.FC<Props> = ({ show }) => {
  return (
    <Layout
      name="show-heading__container"
      display={Display.Flex}
      alignItems={AlignItems.Center}
    >
      <ShowCard
        show={show}
        aspect={Aspect.Square}
        size={CardSize.Small}
        entitlement={show.contentRestriction === Entitlements.MEMBERSHIP}
      />
      <Layout margin={{ l: 5 }}>
        <FireText type={TextType.Subtitle} color={Color.Alt}>
          {show.series?.[0]?.displayName}
        </FireText>
        <FireText type={TextType.H3}>{show.title}</FireText>
      </Layout>
    </Layout>
  );
};
