import { useCallback, useEffect, useState } from "react";
import { View } from "../../../../components/Tw";
import { useAtomValue } from "jotai";
import { Text } from "../../../../components/Tw";
import noop from "lodash/noop";
import { Maybe } from "../../../../gql/generated/client-cached.graphql";
import { ReplayEventManager } from "../relay-events/ReplayEventManager";
import { ReplayShowMember, sortByEntityRoleEnum } from "../relay-events/types";
import { ClipFeedEntity } from "../../../../atoms/content-feed/types";
import { useReplayAuditoriumManager } from "../relay-events/useReplayAuditoriumManager";
// import { activeFeedEntityAtom } from "@/content-feed/atoms";
import { playerBridge } from "./PlayerAuditoriumBridge";
import ClipSpeaker from "./ClipSpeaker";

const sortSpeakers = (speakers: ReplayShowMember[]) => [...speakers].sort((a, b) => sortByEntityRoleEnum(a.role, b.role));

type Props = {
  clipEntity: ClipFeedEntity;
};

export const ClipSpeakers = ({ clipEntity }: Props) => {
  const [activeSpeakerUid, setActiveSpeakerUid] = useState<Maybe<string>>(null);
  const [speakers, setSpeakersState] = useState<ReplayShowMember[]>([]);

  const setSpeakers = useCallback((newSpeakers: typeof speakers) => {
    setSpeakersState(sortSpeakers(newSpeakers));
  }, []);

  const { replayAuditoriumManager } = useReplayAuditoriumManager({
    ulid: clipEntity.chatUlid,
    startSeconds: clipEntity.startSeconds,

    setActiveSpeaker: setActiveSpeakerUid,
    setSpeakers,

    resetActiveSpeaker: noop,
    resetSpeakers: noop,

    setAudience: noop,
    setFortuneCookie: noop,
    resetAudience: noop,
    resetFortuneCookie: noop,

    additionalKeys: [clipEntity.ulid]
  });

  useEffect(() => {
    if (!replayAuditoriumManager || !clipEntity) {
      return noop;
    }

    if (clipEntity.ulid === clipEntity.ulid && replayAuditoriumManager instanceof ReplayEventManager) {
      return () => {
        // note - ReplayEventManager can't tick backwards
        replayAuditoriumManager.setSeeking(true);

        replayAuditoriumManager.tick(clipEntity.startSeconds);
      };
    }

    return noop;
  }, [clipEntity, clipEntity.startSeconds, clipEntity.ulid, replayAuditoriumManager]);

  useEffect(() => {
    if (replayAuditoriumManager) {
      replayAuditoriumManager.tick(clipEntity.startSeconds);

      replayAuditoriumManager.setDuration(clipEntity.endSeconds);

      const removeManager = playerBridge.addManager(clipEntity.ulid, replayAuditoriumManager);

      return removeManager;
    }

    return noop;
  }, [clipEntity.endSeconds, clipEntity.startSeconds, clipEntity.ulid, replayAuditoriumManager]);

  if (speakers.length === 0) {
    return null;
  }

  return (
    <View className="items-end">
      <View className="bg-[#0006] p-5 rounded-l-[40px]">
        {speakers.map((speaker) => (
          <ClipSpeaker key={speaker.uid} speaker={speaker} />
        ))}
      </View>
    </View>
  );
};
