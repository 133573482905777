import { useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import { useLogger } from "src/core/utils/logger";
import {
  IsFollowingPresentableQuery,
  useFollowPresentableMutation,
  useIsFollowingPresentableQuery,
  useUnfollowPresentableMutation,
} from "src/gql/generated/client-fresh.graphql";

export function useFollowNetwork(slug: string, userUid: string) {
  const { logger } = useLogger();
  const queryClient = useQueryClient();
  const { data: isFollowing, isLoading: followingLoading } =
    useIsFollowingPresentableQuery(
      {
        slug,
        userUid,
      },
      {
        enabled: Boolean(userUid),
        staleTime: 0,
        select: (data) => Boolean(data.presentable_follows_by_pk),
      }
    );
  const followed = isFollowing;

  const { mutateAsync: follow, isLoading: followLoading } =
    useFollowPresentableMutation();
  const { mutateAsync: unfollow, isLoading: unfollowLoading } =
    useUnfollowPresentableMutation();

  const loading = followingLoading || followLoading || unfollowLoading;

  const handleChange = useCallback(async () => {
    try {
      const request = followed ? unfollow({ slug }) : follow({ slug });

      await request;

      queryClient.setQueryData<IsFollowingPresentableQuery>(
        useIsFollowingPresentableQuery.getKey({ slug, userUid }),
        {
          presentable_follows_by_pk: !followed
            ? { user_uid: userUid, slug }
            : null,
        }
      );

      queryClient.invalidateQueries({
        queryKey: ["GetAccessBundles"],
      });
    } catch (e) {
      logger.errorAndReport("Error mutating network follow", e, {
        slug,
        userUid,
      });
    }
  }, [followed, follow, queryClient, slug, unfollow, userUid]);

  return {
    loading,
    followed,
    handleChange,
  };
}
