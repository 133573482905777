import React from "react";
import { useGetUserQuery } from "../../../../gql/generated/client-cached.graphql";
import { EntityRole, ReplayShowMember } from "../relay-events/types";
import { mapQueryToUser } from "../../../../atoms/content-feed/mappers";
import { View, Text, Image } from "../../../../components/Tw";
import { CROWN, CLAPPER_BOARD, GUEST_MICROPHONE } from "../../../../config";

type Props = {
  speaker: ReplayShowMember;
};

const ClipSpeaker = ({ speaker: { uid, role } }: Props) => {
  const { data: user } = useGetUserQuery({ uid }, { select: mapQueryToUser });

  if (!user) return null;

  return (
    <View className="items-center mb-3">
      {user.avatarUrl && <Image className="rounded-full h-14 w-14" source={{ uri: user.avatarUrl }} />}
      <View className="flex-row items-center justify-center space-x-1 w-18">
        <Text className="text-white" numberOfLines={1}>
          {user.displayName || "..."}
        </Text>
        {role === EntityRole.HOST && <Image source={{ uri: CROWN }} className="h-3 w-3" />}
        {role === EntityRole.MODERATOR && <Image source={{ uri: CLAPPER_BOARD }} className="h-3 w-3" />}
        {role === EntityRole.GUEST && <Image source={{ uri: GUEST_MICROPHONE }} className="h-3 w-3" />}
      </View>
    </View>
  );
};

export default ClipSpeaker;
