import { VideoFeedEntity } from "../../../atoms/content-feed/types";

type Input = {
  videoEntity: VideoFeedEntity;
  landscape: boolean;
};

export const useVideoEntitySource = ({ videoEntity, landscape }: Input) => {
  const primaryVideoSource = landscape ? videoEntity.landscapePlaybackUrl : videoEntity.portraitPlaybackUrl;
  const alternateVideoSource = landscape ? videoEntity.portraitPlaybackUrl : videoEntity.landscapePlaybackUrl;

  const videoSource = primaryVideoSource || alternateVideoSource;

  if (videoEntity.type === "clip") {
    return videoEntity.hasVideo ? videoSource : videoEntity.audioUrl || videoSource;
  }

  return videoSource;
};
