import { useIntl } from "react-intl";
import { Episode } from "src/atoms/airing-now/epg";
import { IconLock } from "src/components/Icons";
import ScalableImage from "src/components/ScalableImage";
import { AlignItems, Color, Display, FireText, Layout, TextType } from "src/ui";

interface Props {
  image: string;
  description: string;
  episode?: Episode | null;
}

const ChannelDetails: React.FC<Props> = ({ image, description, episode }) => {
  const { formatMessage } = useIntl();
  const heading = episode
    ? formatMessage({ defaultMessage: "About the Episode" })
    : formatMessage({
        defaultMessage: "About the network",
      });

  return (
    <>
      <ScalableImage source={{ uri: image }} height={68} />
      <Layout
        display={Display.Flex}
        alignItems={AlignItems.Center}
        margin={{ y: 5 }}
      >
        <Layout margin={{ r: 2 }}>
          <IconLock />
        </Layout>
        <FireText>
          {formatMessage({ defaultMessage: "You are a member" })}
        </FireText>
      </Layout>
      <Layout margin={{ y: 1 }}>
        <FireText type={TextType.H4}>{heading}</FireText>
        <FireText color={Color.Alt}>
          {episode?.description || description}
        </FireText>
      </Layout>
    </>
  );
};

export default ChannelDetails;
