import {
  Component,
  ComponentType,
  MemoExoticComponent,
  PropsWithChildren,
} from "react";
import { Interactable, InteractableProps } from "../Interactable";
import {
  Background,
  BorderRadius,
  Color,
  Layout,
  FireText,
  TextTransform,
} from "src/ui";
import { View } from "src/components/Tw";
import {
  UseFocusableConfig,
  useFocusable,
} from "@noriginmedia/norigin-spatial-navigation";
import { PressableProps } from "react-native";

export interface ButtonProps {}

type Props = ButtonProps &
  PressableProps &
  InteractableProps &
  UseFocusableConfig;

export const Button: React.FC<PropsWithChildren<Props>> = ({
  children,
  focusKey,
  ...otherProps
}) => {
  return (
    <Interactable
      focusKey={focusKey}
      borderRadius={BorderRadius.Full}
      {...otherProps}
    >
      <View className="flex-0 items-center justify-center rounded-full bg-gradient-to-br from-fs-gray-start to-fs-gray-stop border-solid border-2 border-white/10 h-20 px-12">
        <FireText transform={TextTransform.Upper} color={Color.Alt}>
          {children}
        </FireText>
      </View>
    </Interactable>
  );
};
