import { useNavigate } from "src/hooks/navigation";
import {
  AlignItems,
  Aspect,
  BorderRadius,
  Card,
  CardSize,
  Display,
  FireImage,
  FireText,
  FlexDirection,
  Interactable,
  Layout,
  TextType,
} from "src/ui";

interface Props {
  image: string;
  name?: string;
  slug: string;
}
export const NetworkCard: React.FC<Props> = ({ image, name, slug }) => {
  const navigate = useNavigate();

  return (
    <Layout
      name="profile-heading__container"
      display={Display.Flex}
      flexDirection={FlexDirection.Column}
      alignItems={AlignItems.Center}
    >
      <Interactable
        borderRadius={BorderRadius.XXXL}
        onEnterPress={() => {
          navigate(`/${slug}`);
        }}
      >
        <Card size={CardSize.Medium}>
          <FireImage src={image} fill />
        </Card>
      </Interactable>
      {name && (
        <Layout margin={{ l: 5 }}>
          <FireText type={TextType.Micro}>{name}</FireText>
        </Layout>
      )}
    </Layout>
  );
};
