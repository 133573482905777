import { fetcher } from "./fetcher";
import {
  doNotUseFreshSdk,
  QueryResult,
  Sdk,
  SdkKey,
  ResponseSource,
} from "./internal";
import { getSdk } from "../../generated/sdk.graphql";

type Result<T extends SdkKey> = Awaited<ReturnType<Sdk[T]>>;

export const sdk = getSdk<RequestInit, unknown>(async (doc, vars, opts) => {
  return fetcher(doc, vars, opts);
});

export const sdkWithMetadata = async <T extends SdkKey>(
  queryName: T,
  vars: Parameters<Sdk[T]>[0],
  opts?: RequestInit
): Promise<QueryResult<Result<T>>> => {
  return {
    data: (await doNotUseFreshSdk[queryName](vars as any, opts)) as Result<T>,
    source: ResponseSource.ORIGIN,
  };
};
