import React from "react";
import { VideoFeedEntity } from "../../../../../atoms/content-feed/types";
import { View, Text } from "../../../../../components/Tw";
import VideoEntityFeed from "../VideoEntityFeed";
import { VideoEntityActionNetwork } from "./VideoEntityActionNetwork";
import VideoEntityActionEnter from "./VideoEntityActionEnter";
import VideoEntityActionShare from "./VideoEntityActionShare";
import VideoEntityActionReactions from "./VideoEntityActionReactions";
import FocusGroup from "src/ui/components/FocusGroup";

type Props = {
  videoEntity: VideoFeedEntity;
};

const VideoEntityActionsContainer = ({ videoEntity }: Props) => {
  return (
    <View className="mt-auto flex flex-row items-stretch">
      <View className="grow justify-end">
        <View className="max-h-[500px]">
          <VideoEntityFeed videoEntity={videoEntity} />
        </View>

        <View className="items-end">
          {/* <Text className="text-white">TODO: Reaction Panel</Text> */}
        </View>
      </View>
      <FocusGroup id={`ACTION_GROUP_${videoEntity.ulid}`}>
        <View className="ml-auto flex flex-col items-center justify-end space-y-4">
          <VideoEntityActionNetwork />
          <VideoEntityActionShare />
          <VideoEntityActionEnter />
          <VideoEntityActionReactions />
        </View>
      </FocusGroup>
    </View>
  );
};

export default VideoEntityActionsContainer;
