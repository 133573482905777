import { Series } from "src/atoms/content-feed/types";
import { Fireside } from "src/components/Icons";
import {
  AlignItems,
  Aspect,
  Background,
  Card,
  CardSize,
  Display,
  JustifyContent,
  Layout,
  FireImage,
} from "src/ui";

interface Props {
  series: Series | null;
  loading?: boolean;
}

export const SeriesCard: React.FC<Props> = ({ series, loading }) => {
  const cardProps = {
    display: Display.Flex,
    background: Background.Alt,
    fullWidth: true,
    fullHeight: true,
    alignItems: AlignItems.Center,
    justifyContent: JustifyContent.Center,
  };

  if (loading || !series) {
    return (
      <Card size={CardSize.XLarge} aspect={Aspect.Square}>
        <Layout className="animate-pulse" {...cardProps}>
          <Layout className="w-80">
            <Fireside fill="#7e7a81" />
          </Layout>
        </Layout>
      </Card>
    );
  }

  return (
    <Card size={CardSize.XLarge} aspect={Aspect.Square}>
      <Layout {...cardProps}>
        <FireImage src={series.coverImageUrl} fill>
          <Layout className="w-80">
            <Fireside fill="#7e7a81" />
          </Layout>
        </FireImage>
      </Layout>
    </Card>
  );
};
