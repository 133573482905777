import { useQuery } from "@tanstack/react-query";
import ky from "ky-universal";

import { useGetChatQuery } from "../../../gql/generated/client-cached.graphql";
import { VideoFeedEntity } from "../../../atoms/content-feed/types";

type Props = {
  videoEntity: VideoFeedEntity;
  source: string;
};

export const useVideoEntityPosterUrl = ({ videoEntity, source }: Props) => {
  const chatUlid = videoEntity.type === "chat" ? videoEntity.ulid : videoEntity.chatUlid;

  const { data: coverImageUrl } = useGetChatQuery(
    { ulid: chatUlid, id: chatUlid },
    {
      select: (data) => data.chats_by_pk?.cover_image_url,
      enabled: videoEntity.type === "chat" || !videoEntity.hasVideo
    }
  );

  const { data: clipThumbnailUrl } = useQuery(
    ["clip-thumbnail-url", videoEntity.ulid, source],
    () => {
      const url = new URL("/api/get-clip-thumbnail-url", window.location.origin);

      url.searchParams.set("ulid", videoEntity.ulid);
      url.searchParams.set("stream", source);

      return ky.get(url).json<{ url: string }>();
    },
    {
      enabled: videoEntity.type === "clip" && videoEntity.hasVideo,
      select: (data) => data.url
    }
  );

  if (videoEntity.type === "chat") {
    return coverImageUrl || "";
  }

  if (!videoEntity.hasVideo) {
    return coverImageUrl || "";
  }

  return clipThumbnailUrl || "";
};
