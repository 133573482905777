import { PropsWithChildren, createContext, useContext, useMemo } from "react";
import { FlatList } from "react-native";

interface RailContextState {
  sliderRef: FlatList | null;
  itemIndex: number;
  parentFocused?: boolean;
}

export const RailContext = createContext<RailContextState>({
  sliderRef: null,
  itemIndex: -1,
});

interface Props {
  sliderRef: FlatList | null;
  itemIndex: number;
  parentFocused?: boolean;
}

export const RailContextProvider: React.FC<PropsWithChildren<Props>> = ({
  sliderRef,
  itemIndex,
  parentFocused,
  children,
}) => {
  const state = useMemo(() => {
    return {
      sliderRef,
      itemIndex,
      parentFocused,
    };
  }, [sliderRef, itemIndex, parentFocused]);

  return <RailContext.Provider value={state}>{children}</RailContext.Provider>;
};
