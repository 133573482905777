import { NetworkInput } from "src/atoms/content-feed/mappers";
import { Network, Show } from "src/atoms/content-feed/types";
import {
  GetUserMembershipsQuery,
  GetUserMembershipsQueryVariables,
  Maybe,
  useInfiniteGetUserMembershipsQuery,
} from "src/gql/generated/client-cached.graphql";
import { useInfiniteQuery } from "src/hooks/infinite-query/use-infinite-query";
import {
  maxDisplayedItems,
  pageSize,
  paginationQueryVariables,
} from "src/utils/pagination-constants";
import { slugExtractor } from "src/utils/ulid-extractor";

const mapToBasicNetwork = (network: NetworkInput) => ({
  slug: network?.slug || "",
  name: network?.name || "",
});

const getNetworkImage = (
  network: NetworkInput,
  preferredImage?: Maybe<string>
) => preferredImage || network?.image_url || network?.cover_image_url || "";

export function useNetworkAffiliations(uid: string) {
  return useInfiniteQuery<
    GetUserMembershipsQuery,
    { slug: string; name: string; imageUrl },
    GetUserMembershipsQueryVariables
  >({
    hook: useInfiniteGetUserMembershipsQuery,
    variables: {
      uid,
      ...paginationQueryVariables,
    },
    optionsProps: {
      normalizePageData: (data: GetUserMembershipsQuery) =>
        data.user_bundles.map((el) => ({
          ...mapToBasicNetwork(el.bundle?.network),
          imageUrl: getNetworkImage(el.bundle?.network, el.image_url),
        })),
      keyExtractor: slugExtractor,
      pageSize,
      maxCount: maxDisplayedItems,
    },
  });
}
