import { createContext } from "react";
import { VideoFeedEntity } from "../../../../../atoms/content-feed/types";

export const VideoEntityContext = createContext<VideoFeedEntity>({
  audioUrl: "",
  chatUlid: "",
  endSeconds: -1,
  hasVideo: false,
  landscapePlaybackUrl: "",
  networkSlug: null,
  portraitPlaybackUrl: "",
  startSeconds: -1,
  type: "clip",
  ulid: ""
});

export const getVideoEntityUlids = (videoEntity: VideoFeedEntity) => {
  const chatUlid = videoEntity.type === "chat" ? videoEntity.ulid : videoEntity.chatUlid;
  const clipUlid = videoEntity.type === "chat" ? null : videoEntity.ulid;

  return {
    chatUlid,
    clipUlid
  };
};
