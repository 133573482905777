import { Layout } from "src/ui";
import { HighlightCard } from "./card";
import { ProfileRail } from "src/screens/Profile/components/Rail";
import { useHighlights } from "./use-highlights";
import { useIntl } from "react-intl";

interface Props {
  slug: string;
}

const HighlightsRail: React.FC<Props> = ({ slug }) => {
  const { formatMessage } = useIntl();
  const {
    hasMore,
    fetchNextPage,
    isFetchingNextPage,
    isFetched,
    items: shows,
  } = useHighlights({ slug });

  if (isFetched && !shows.length) {
    return null;
  }

  return (
    <Layout margin={{ y: 10 }}>
      <ProfileRail
        title={formatMessage({
          defaultMessage: "Highlights",
        })}
        canLoadMore={!!shows.length && hasMore && !isFetchingNextPage}
        onLoadMore={fetchNextPage}
      >
        {!shows.length && <HighlightCard loading />}
        {shows.map((show) => (
          <HighlightCard key={show.ulid} show={show} />
        ))}
      </ProfileRail>
    </Layout>
  );
};

export default HighlightsRail;
