import identity from "lodash/identity";
import { mapToSeries } from "src/atoms/content-feed/mappers";
import { Series } from "src/atoms/content-feed/types";
import { useInfiniteGetNetworkSeriesQuery } from "src/gql/generated/client-cached.graphql";
import {
  GetNetworkSeriesQuery,
  GetNetworkSeriesQueryVariables,
} from "src/gql/generated/sdk-cached.graphql";
import { useInfiniteQuery } from "src/hooks/infinite-query/use-infinite-query";
import {
  paginationQueryVariables,
  pageSize,
  maxDisplayedItems,
} from "src/utils/pagination-constants";
import { slugExtractor } from "src/utils/ulid-extractor";

export function useNetworkSeries(slug: string) {
  return useInfiniteQuery<
    GetNetworkSeriesQuery,
    Series,
    GetNetworkSeriesQueryVariables
  >({
    hook: useInfiniteGetNetworkSeriesQuery,
    variables: {
      slug,
      ...paginationQueryVariables,
    },
    optionsProps: {
      pageSize,
      keyExtractor: slugExtractor,
      filter: identity,
      maxCount: maxDisplayedItems,
      normalizePageData: (page) =>
        page.presentable_relationships.map((item) =>
          mapToSeries({ series: item.child?.presentable?.series })
        ),
    },
  });
}
