import React from "react";
import { View } from "react-native";
import { Outlet } from "../components/Router";
import TopBar from "../components/TopBar";

const Layout = () => {
  return (
    <View style={{ flex: 1 }}>
      <TopBar />
      <Outlet />
    </View>
  );
};

export default Layout;
