import React, { useEffect, useRef } from "react";
import "video.js/dist/video-js.min.css";
import videojs from "video.js";
// import "videojs-contrib-ads";
// import "videojs-ima/dist/videojs.ima.css";
// import "videojs-ima";

export const VideoJsPlayer = (props) => {
  const videoRef = React.useRef(null);
  const playerRef = React.useRef(null);
  const { options, onReady } = props;

  useEffect(() => {
    // Make sure Video.js player is only initialized once
    if (!playerRef.current) {
      // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode.
      const videoElement = document.createElement("video-js");

      videoElement.classList.add("vjs-big-play-centered");
      videoRef.current.appendChild(videoElement);

      const player = (playerRef.current = videojs(videoRef.current, options, () => {
        console.log("player is ready");
        onReady && onReady(player);
      }));

      player.on("play", () => {
        props.onPlay?.();
      }) 

      player.on("error", (e) => {
        console.log("error", e);
        player.src(options.sources);
      });
      // You could update an existing player in the `else` block here on prop change, for example:
    } else {
      const player = playerRef.current;

      player.autoplay(options.autoplay);
      player.src(options.sources);
    }
  }, [videoRef]);

  // Dispose the Video.js player when the functional component unmounts
  useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <div data-vjs-player style={{ height: "100%", width: "100%", overflow: "hidden" }}>
      <video ref={videoRef} autoPlay disablePictureInPicture className="video-js vjs-big-play-centered" />
    </div>
  );
};

export default VideoJsPlayer;
