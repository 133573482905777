import { useEffect, useRef, useState } from "react";
import { Dimensions, FlatList, View } from "react-native";
import { Channel, Episode } from "src/atoms/airing-now/epg";
import {
  AlignItems,
  BorderRadius,
  Display,
  FireText,
  FlexDirection,
  Interactable,
  JustifyContent,
  Layout,
  Overflow,
  Position,
  TextType,
  useRailContext,
} from "src/ui";
import { useChannelBrandColors } from "./use-brand-colors";
import ScalableImage from "src/components/ScalableImage";
import { useIntl } from "react-intl";
import EpisodeTile from "./EpisodeTile";
import ChannelDetails from "./ChannelDetails";
import Timeline from "src/screens/AiringNow/ChannelPreview/Timeline";
import { ChannelGuideUpcomingEpisodes } from "./UpcomingEpisodes";
import { useFocusable } from "@noriginmedia/norigin-spatial-navigation";
import FocusGroup from "src/ui/components/FocusGroup";
import useBackKey from "src/hooks/useBackKey";
import { useNestedFocus } from "src/core/utils/use-nested-focus";

interface Props {
  channel: Channel;
  verticalSlider: FlatList | null;
  verticalIndex: number;
}

export const EPISODE_FOCUSKEY_PREFIX = "AiringNowEpisode";
export const ChannelGuideCard: React.FC<Props> = ({
  channel,
  verticalSlider,
  verticalIndex,
}) => {
  const { hasNestedFocus, setNestedFocus } = useNestedFocus();
  const { sliderRef, itemIndex, parentFocused } = useRailContext();
  const focusKey = `${EPISODE_FOCUSKEY_PREFIX}_${verticalIndex}_${itemIndex}`;
  const railFocusKey = `AiringNowChannel_${channel.title}`;
  const { setFocus } = useFocusable({ focusKey: railFocusKey });
  const [focused, setFocused] = useState(false);
  const [active, setActive] = useState(false);
  const fullCardWidth = active ? "w-[88vw] h-[75vh]" : "w-[68vw] h-[68vh]";
  const cardWidth = active ? "w-2/3 h-[75vh]" : "w-full h-[68vh]";
  const invis = !parentFocused && !focused ? "opacity-0" : "opacity-100";
  const { brandColor, darkerBrandColor } = useChannelBrandColors(channel.id);
  const cardRef = useRef<HTMLDivElement>();
  const { formatMessage } = useIntl();
  const detailDelay = active ? "delay-1000" : "delay-0";
  const [selectedEpisode, setSelectedEpisode] = useState<Episode | null>();
  // onBlur isn't working with state so we're making a ref
  const focusedRef = useRef(focused);
  focusedRef.current = focused;

  useEffect(() => {
    cardRef.current?.style.setProperty("--brand-color", brandColor || "black");
    cardRef.current?.style.setProperty(
      "--brand-color-dark",
      darkerBrandColor || "black"
    );
  }, [brandColor, darkerBrandColor]);

  useBackKey((props) => {
    if (selectedEpisode) {
      setNestedFocus(false);
      setSelectedEpisode(null);
      setFocused(false);
      focusedRef.current = false;
      setFocus(focusKey);
    }
  });

  return (
    <>
      <Interactable
        disableFocusOutline
        focusKey={focusKey}
        onFocus={() => {
          sliderRef?.scrollToIndex({
            animated: true,
            index: itemIndex,
            viewOffset: Dimensions.get("window").width * 0.125,
          });
          verticalSlider?.scrollToIndex({
            animated: true,
            index: verticalIndex,
            viewOffset: Dimensions.get("window").height * 0.125,
          });
          setActive(true);
        }}
        onBlur={() => {
          if (!focusedRef.current) {
            setNestedFocus(false);
            setActive(false);
          }
        }}
        onEnterPress={(props) => {
          setNestedFocus(!hasNestedFocus);
          setFocused(true);
          setFocus("CurrentChannel");
        }}
      >
        <Layout
          className={`${
            active ? "w-[90vw]" : "w-[75vw]"
          } h-[75vh] transition-all duration-1000`}
          display={Display.Flex}
          alignItems={AlignItems.Center}
          justifyContent={JustifyContent.Center}
          style={{
            marginLeft: active ? -Dimensions.get("window").width * 0.125 : 0,
          }}
        >
          <Layout
            borderRadius={BorderRadius.XXXL}
            display={Display.Flex}
            overflow={Overflow.Hidden}
            className={`${fullCardWidth} ${invis} transition-all duration-1000`}
          >
            <Layout
              ref={cardRef}
              className={`${cardWidth} h-[75vh] transition-all duration-1000 bg-gradient-to-br from-[var(--brand-color-dark)] to-[var(--brand-color)]`}
              borderRadius={BorderRadius.XXXL}
            >
              <Layout
                className={`${
                  !active ? "opacity-0" : "opacity-100"
                } transition-all duration-1000`}
                fullHeight
                display={Display.Flex}
                flexDirection={FlexDirection.Column}
                justifyContent={JustifyContent.Between}
                padding={{ x: 8, t: 12, b: 5 }}
              >
                <ScalableImage source={{ uri: channel.thumb }} height={100} />
                <Layout
                  display={Display.Flex}
                  alignItems={AlignItems.End}
                  className="flex-1"
                >
                  <Layout
                    display={Display.Flex}
                    flexDirection={FlexDirection.Column}
                    alignItems={AlignItems.Start}
                  >
                    <FireText type={TextType.H4}>
                      {formatMessage({ defaultMessage: "Airing Now" })}
                    </FireText>
                    {active && (
                      <FocusGroup id="CurrentChannel" focusable={focused}>
                        <EpisodeTile
                          image={channel.currentEpisode.primaryImageUrl}
                          current
                          onFocus={() => {
                            setSelectedEpisode(channel.currentEpisode);
                          }}
                        />
                      </FocusGroup>
                    )}
                  </Layout>
                  {active && (
                    <ChannelGuideUpcomingEpisodes
                      channelID={channel.title}
                      upcomingEpisodes={channel.upcomingEpisodes}
                      focused={focused}
                      onItemFocus={(episode) => {
                        setSelectedEpisode(episode);
                      }}
                    />
                  )}
                </Layout>
              </Layout>
            </Layout>
            <Layout
              padding={{ l: 12 }}
              display={Display.Flex}
              flexDirection={FlexDirection.Column}
              justifyContent={JustifyContent.End}
              className={`${
                active ? "opacity-100" : "opacity-0"
              } w-1/3 transition-all ${detailDelay}`}
            >
              <ChannelDetails
                image={channel.thumb}
                description={channel.description}
                episode={selectedEpisode}
              />
              <Layout margin={{ t: 5 }}>
                <Timeline
                  brandColor={brandColor}
                  darkerBrandColor={darkerBrandColor}
                  currentEpisode={channel.currentEpisode}
                  upcomingEpisodes={channel.upcomingEpisodes}
                />
              </Layout>
            </Layout>
          </Layout>
        </Layout>
      </Interactable>
      <Layout
        position={Position.Absolute}
        display={Display.Flex}
        fullHeight
        alignItems={AlignItems.Center}
        justifyContent={JustifyContent.Center}
        className={`${
          active ? "opacity-0 delay-0" : "opacity-100 delay-1000"
        } right-[90px] transition-all`}
      >
        <ScalableImage source={{ uri: channel.thumb }} width={125} />
      </Layout>
    </>
  );
};
