import { ProfileStar } from "src/components/Icons/ProfileStar";
import { AlignItems, Color, Display, FireText, Layout, TextType } from "src/ui";

interface Props {
  names: string[];
}

export const SeriesCategories: React.FC<Props> = ({ names }) => {
  return (
    <Layout display={Display.Flex} alignItems={AlignItems.Center}>
      <Layout margin={{ r: 4 }}>
        <ProfileStar />
      </Layout>
      <FireText type={TextType.Micro} color={Color.Alt}>
        {names.join("&bull;")}
      </FireText>
    </Layout>
  );
};
