import { atom, useAtom } from "jotai";

const layoutState = atom(false);
export function useUILayout() {
  const [hidden, setHidden] = useAtom(layoutState);

  return {
    hidden,
    show: () => {
      setHidden(false);
    },
    hide: () => {
      setHidden(true);
    },
  };
}
