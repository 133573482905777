import { ProfileRail } from "src/screens/Profile/components/Rail";
import { ShowCard } from "src/screens/Show/components/Card";
import { useUpcoming } from "./use-upcoming";
import { BorderRadius, Interactable, Layout } from "src/ui";
import { useNavigateToShow } from "src/screens/Show/utils/use-url-path";
import { useIntl } from "react-intl";
import { UserUpcomingShowItem } from "./item";

interface Props {
  slug: string;
}

export const UserUpcoming: React.FC<Props> = ({ slug }) => {
  const { formatMessage } = useIntl();
  const {
    items: shows,
    hasMore,
    isFetchingNextPage,
    fetchNextPage,
    isFetched,
  } = useUpcoming(slug);

  if (isFetched && !shows.length) {
    return null;
  }

  return (
    <Layout margin={{ y: 10 }}>
      <ProfileRail
        title={formatMessage({ defaultMessage: "Upcoming Shows" })}
        onLoadMore={fetchNextPage}
        canLoadMore={!!shows.length && hasMore && !isFetchingNextPage}
      >
        {shows.map((show) => (
          <UserUpcomingShowItem key={`UpcomingShow_${show.slug}`} show={show} />
        ))}
      </ProfileRail>
    </Layout>
  );
};
