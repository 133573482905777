export enum TextType {
  Micro = "micro",
  Subtitle = "subtitle",
  Large = "large",
  Base = "p",
  H1 = "h1",
  H2 = "h2",
  H3 = "h3",
  H4 = "h4",
}

export enum TextTransform {
  Normal = "normal-case",
  Upper = "uppercase",
  Lower = "lowercase",
  Capitalize = "capitalize",
}
