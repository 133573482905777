import { ProfileRail } from "src/screens/Profile/components/Rail";
import { useNetworkAffiliations } from "./use-network-affiliations";
import { Layout } from "src/ui";
import { NetworkCard } from "src/screens/Network/components/Card";
import { useIntl } from "react-intl";

interface Props {
  uid: string;
}

export const UserNetworkAffiliations: React.FC<Props> = ({ uid }) => {
  const { formatMessage } = useIntl();

  const {
    items: networks,
    hasMore,
    isFetchingNextPage,
    fetchNextPage,
    isFetched,
  } = useNetworkAffiliations(uid);

  if (isFetched && !networks.length) {
    return null;
  }

  return (
    <Layout margin={{ y: 10 }}>
      <ProfileRail
        title={formatMessage({ defaultMessage: "Network Affiliations" })}
        onLoadMore={fetchNextPage}
        canLoadMore={!!networks.length && hasMore && !isFetchingNextPage}
      >
        {networks.map((n) => {
          return (
            <NetworkCard
              key={n.slug}
              slug={n.slug}
              image={n.imageUrl}
              name={n.name}
            />
          );
        })}
      </ProfileRail>
    </Layout>
  );
};
