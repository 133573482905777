import React, { useEffect } from "react";
import { View } from "react-native";
import { Router, Routes, Route } from "../components/Router";
import ScreenHome from "../screens/Vod";
import ScreenQrLogin from "../screens/Auth/QrLogin";
import ScreenAiringNow from "../screens/AiringNow";
import AuthGuard from "../components/AuthGuard";
import GuestGuard from "../components/GuestGuard";
import ScreenContentFeed from "../screens/ContentFeed";
import ScreenLinkTv from "../screens/Auth/LinkTv";
import Layout from "../layouts";
import useExit from "../hooks/useExit";
import { hasWebFocusableUI } from "../config";
import "../components/GlobalStyles";
import ScreenProfile from "src/screens/Profile/components/Profile";
import { AiringNowScreenV2 } from "src/screens/AiringNowV2/components/Screen";

const App = () => {
  useExit();

  return (
    <View style={hasWebFocusableUI ? { height: "100vh" } : { flex: 1 }}>
      <Routes>
        <Route path="link" element={<ScreenLinkTv />} />

        <Route path="/auth" element={<GuestGuard />}>
          <Route path="login" element={<ScreenQrLogin />} />
        </Route>

        <Route path="/" element={<AuthGuard />}>
          <Route path="/" element={<Layout />}>
            <Route path="content-feed" element={<ScreenContentFeed />} />
            <Route path="airing-now" element={<AiringNowScreenV2 />} />
            <Route path="/" element={<ScreenHome />} />
            <Route path=":slug" element={<ScreenProfile />} />
          </Route>
        </Route>
      </Routes>
    </View>
  );
};

export default () => (
  <Router>
    <App />
  </Router>
);
