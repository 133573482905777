import { atom } from "recoil";
import { setRecoil } from "recoil-nexus";
import axios from "../../utils/axios";

export interface Channel {
  numGuideDays: number;
  providerId: string | null;
  free: boolean;
  guideId: string | null;
  guideType: string | null;
  description: string;
  sources: string | null;
  subtitle: string | null;
  thumb: string;
  title: string;
  id: string;
  requestOrId: string | null;
  resolveLocally: boolean;
  region: string | null;
  displayNum: number | null;
  providerDisplayName: string;
  upcomingEpisodes: Episode[];
  currentEpisode: Episode;
  adobeResourceId: string;
}

export interface Episode {
  title: string;
  /** Minutes */
  duration: number;
  /** ISO Date String */
  airTime: string;
  seasonNumber: number;
  episodeNumber: number;
  episodeTitle: string | null;
  primaryImageUrl: string;
  repeat: boolean;
  programID: string;
  seriesProgramID: string;
  description: string;
  mainCategory: string;
  playbackUrl: string | null;
  vodAssetId: string | null;
}

export const epgState = atom<Channel[] | null>({
  key: "epg",
  default: null,
});

interface ChannelsResponse {
  data: Channel[];
}

export const getChannels = async () => {
  const { data } = await axios.get<any, ChannelsResponse>(`/programGuide`);
  const filteredData = data.filter(
    (channel) => channel.currentEpisode && channel.upcomingEpisodes
  );
  setRecoil(epgState, filteredData);
};
