import { Show } from "src/atoms/content-feed/types";
import { useNavigate } from "src/hooks/navigation";
import { ShowCard } from "src/screens/Show/components/Card";
import { Interactable, BorderRadius, useRailContext, Layout } from "src/ui";

interface Props {
  show: Show;
}

export const UserUpcomingShowItem: React.FC<Props> = ({ show }) => {
  const { sliderRef, itemIndex } = useRailContext();
  const navigate = useNavigate();

  return (
    <Interactable
      key={`UpcomingShow_${show.slug}`}
      borderRadius={BorderRadius.XXXL}
      onEnterPress={() => {
        navigate(show);
      }}
      onFocus={() => {
        if (sliderRef) {
          sliderRef.scrollToIndex({
            animated: true,
            index: itemIndex,
          });
        }
      }}
    >
      <ShowCard show={show} />
    </Interactable>
  );
};
