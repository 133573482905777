import { Show } from "src/atoms/content-feed/types";
import { Fireside } from "src/components/Icons";
import {
  Card,
  Layout,
  CardSize,
  Aspect,
  AlignItems,
  Background,
  Display,
  JustifyContent,
  FireImage,
  Position,
  BorderRadius,
} from "src/ui";
import { useShowCover } from "../../utils/use-cover";
import Lock from "src/components/Icons/Lock";

interface Props {
  show: Show | null;
  size?: CardSize;
  aspect?: Aspect;
  loading?: boolean;
  entitlement?: boolean;
}

export const ShowCard: React.FC<Props> = ({
  show,
  loading,
  aspect = Aspect.Wide,
  size = CardSize.XLarge,
  entitlement,
}) => {
  const cardProps = {
    display: Display.Flex,
    background: Background.Alt,
    fullWidth: true,
    fullHeight: true,
    alignItems: AlignItems.Center,
    justifyContent: JustifyContent.Center,
  };
  const showCover = useShowCover(
    show?.coverImageUrl || "",
    show?.series?.[0]?.coverImageUrl || ""
  );

  if (loading || !show) {
    return (
      <Card size={size} aspect={aspect}>
        <Layout className="animate-pulse" {...cardProps}>
          <Layout className="w-80">
            <Fireside fill="#7e7a81" />
          </Layout>
        </Layout>
      </Card>
    );
  }

  return (
    <Layout>
      <Card size={size} aspect={aspect}>
        <Layout {...cardProps}>
          <FireImage src={showCover} fill>
            <Layout className="w-80">
              <Fireside fill="#7e7a81" />
            </Layout>
          </FireImage>
        </Layout>
      </Card>
      {entitlement && (
        <Layout
          background={Background.Overlay}
          borderRadius={BorderRadius.Full}
          padding={2}
          position={Position.Absolute}
          className="-right-2 -bottom-2"
        >
          <Lock />
        </Layout>
      )}
    </Layout>
  );
};
