import { BorderRadius, Layout, Position } from "src/ui";

export const Glow: React.FC = () => {
  const glow = "shadow-[0_0_2000px_250px_white/30]";

  return (
    <Layout position={Position.Absolute} fullHeight fullWidth>
      <Layout
        position={Position.Absolute}
        borderRadius={BorderRadius.Full}
        className={`transition-all duration-1000 aspect-video w-[25%] left-[37.5%] top-[37.5%] bg-white/30 ${glow} shadow-white/30`}
      />
    </Layout>
  );
};
