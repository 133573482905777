import React, { useEffect } from "react";
import Hls from "hls.js";

function ReactHlsPlayer({ playerRef = React.createRef(), url, controls, onReady, onPlay, onEnded }) {
  useEffect(() => {
    let hls = null;

    function _initPlayer() {
      if (hls != null) {
        hls.destroy();
      }

      const newHls = new Hls({
        enableWorker: false
      });

      newHls.attachMedia(playerRef.current);

      newHls.on(Hls.Events.MEDIA_ATTACHED, () => {
        newHls.loadSource(url);

        newHls.on(Hls.Events.MANIFEST_PARSED, () => {
          console.log("[HLS] Attempting autoplay");
          var playPromise = playerRef.current.play();
          if (playPromise !== undefined) {
            playPromise
              .then(function () {
                console.log("Automatic playback started!");
              })
              .catch(function (error) {
                console.log("Automatic playback failed: " + error);
              });
          }
        });
      });

      newHls.on(Hls.Events.ERROR, function (event, data) {
        if (data.fatal) {
          switch (data.type) {
            case Hls.ErrorTypes.NETWORK_ERROR:
              newHls.startLoad();
              break;
            case Hls.ErrorTypes.MEDIA_ERROR:
              newHls.recoverMediaError();
              break;
            default:
              _initPlayer();
              break;
          }
        }
      });

      hls = newHls;
    }

    _initPlayer();

    return () => {
      if (hls != null) {
        hls.destroy();
      }
    };
  }, [playerRef, url]);

  return (
    <video
      ref={(n) => {
        if (n) {
          playerRef.current = n;
          onReady?.(n);
        }
      }}
      autoPlay
      controls={controls}
      disablePictureInPicture
      controlsList="nodownload nofullscreen noplaybackrate"
      style={{ position: "fixed", top: 0, bottom: 0, left: 0, right: 0, zIndex: 9999, width: "100%", height: "100%" }}
      onPlay={onPlay}
      onEnded={onEnded}
    />
  );
}

export default ReactHlsPlayer;
