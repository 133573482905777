import { Channel, Episode } from "src/atoms/airing-now/epg";
import { Rail, useRailContext } from "src/ui";
import { ChannelCard, EPISODE_FOCUSKEY_PREFIX } from "../ChannelCard";
import { Dimensions } from "react-native";
import { ChannelGuideCard } from "../ChannelGuide";

interface Props {
  channel: Channel;
}

export const ChannelRail: React.FC<Props> = ({ channel }) => {
  const { sliderRef, itemIndex } = useRailContext();
  const episodes: Episode[] = [channel.currentEpisode].concat(
    channel.upcomingEpisodes.filter((u) => !!u.playbackUrl)
  );

  return (
    <Rail
      id={`AiringNowChannel_${channel.title}`}
      contentContainerStyle={{
        paddingHorizontal: Dimensions.get("window").width * 0.125,
      }}
      focusOnMount={itemIndex === 0}
      preferredChildFocusKey={`${EPISODE_FOCUSKEY_PREFIX}_${itemIndex}_1`}
    >
      <ChannelGuideCard
        key={`Episode_0`}
        channel={channel}
        verticalSlider={sliderRef}
        verticalIndex={itemIndex}
      />
      {episodes.map((e, i) => {
        return (
          <ChannelCard
            key={`Episode_${i + 1}`}
            channelID={channel.id}
            episode={e}
            verticalSlider={sliderRef}
            verticalIndex={itemIndex}
          />
        );
      })}
    </Rail>
  );
};
