import { useIntl } from "react-intl";
import {
  AlignItems,
  Card,
  Display,
  Layout,
  TextType,
  FireImage,
  FireText,
  Avatar,
  AvatarSize,
  Color,
} from "src/ui";

interface Props {
  image: string;
  title: string;
  handle: string;
}

export const UserProfileHeading: React.FC<Props> = ({
  image,
  title,
  handle,
}) => {
  const { formatMessage } = useIntl();
  return (
    <Layout
      name="user-profile-heading__container"
      display={Display.Flex}
      alignItems={AlignItems.Center}
    >
      <Avatar imageSrc={image} size={AvatarSize.Medium} />
      <Layout margin={{ l: 5 }}>
        <FireText type={TextType.H3}>{title}</FireText>
        <FireText type={TextType.Subtitle} color={Color.Alt}>
          {formatMessage({ defaultMessage: "/{handle}" }, { handle })}
        </FireText>
      </Layout>
    </Layout>
  );
};
