import React, { useRef } from "react";
import { useSetAtom } from "jotai";
import { globalPlayerCurrentTimeSecondsAtom, playingAtom } from "../../../atoms/content-feed/activeIndexes";
import { ClipFeedEntity, VideoFeedEntity } from "../../../atoms/content-feed/types";
import { View } from "../../../components/Tw";
import Player from "../../Player";
import { useVerticalSnapperContext } from "./VerticalSnapperProvider";
import { hasWebFocusableUI } from "../../../config";

const InlinePlayer = ({ videoFeedEntity }: { videoFeedEntity: VideoFeedEntity | null }) => {
  const playerRef = useRef<any>();
  const setPlaying = useSetAtom(playingAtom);
  const setPlayerCurrentTime = useSetAtom(globalPlayerCurrentTimeSecondsAtom);
  const { snapToNext } = useVerticalSnapperContext();

  const handleTimeUpdate = ({ currentTime: nativeCurrentTime }) => {
    const currentTime = nativeCurrentTime ?? playerRef.current!.currentTime;
    setPlayerCurrentTime(currentTime);
    const endSeconds = (videoFeedEntity as ClipFeedEntity).endSeconds;
    if (hasWebFocusableUI) {
      if (endSeconds <= currentTime) {
        playerRef.current?.pause();
        setPlaying(false);
        snapToNext();
      }
    } else {
      console.log("currentTime", currentTime);
    }
  };

  const handleReady = (videoElement) => {
    playerRef.current = videoElement;
  };

  const handlePlay = () => {
    setPlaying(true);
    const currentTime = (videoFeedEntity as ClipFeedEntity).startSeconds;
    if (hasWebFocusableUI) {
      playerRef.current!.currentTime = currentTime;
      playerRef.current!.ontimeupdate = handleTimeUpdate;
    } else {
      playerRef.current.seek(currentTime);
      console.log(playerRef.current);
      playerRef.current._onProgress = handleTimeUpdate;
    }
  };

  if (!videoFeedEntity) return null;

  return (
    <View className="-z-10">
      <Player useVideoJs={false} data={{ url: videoFeedEntity?.landscapePlaybackUrl }} onReady={handleReady} onPlay={handlePlay} />
    </View>
  );
};

export default InlinePlayer;
