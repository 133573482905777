import { mapToNetwork } from "src/atoms/content-feed/mappers";
import QRCode from "src/components/Icons/QRCode";
import { useGetNetworkQuery } from "src/gql/generated/client-cached.graphql";
import { ProfileBackdrop } from "src/screens/Profile/components/Backdrop";
import { ProfileHeading } from "src/screens/Profile/components/Heading";
import {
  Background,
  Button,
  Display,
  FlexDirection,
  IconButton,
  Layout,
  FireText,
  useModal,
} from "src/ui";
import HighlightsRail from "../../../Profile/components/HighlightsRail";
import FocusGroup from "src/ui/components/FocusGroup";
import NetworkRecentShows from "../RecentShows";
import { NetworkSeries } from "../Series";
import NetworkUpcomingShows from "../UpcomingShows";
import { NetworkCastAndCrew } from "../CastAndCrew";
import { QRModal } from "../../../../components/QRModal";
import { NetworkFollowButton } from "../FollowButton";
import { useIntl } from "react-intl";

interface Props {
  slug: string;
}

export const NetworkProfile: React.FC<Props> = ({ slug }) => {
  const { formatMessage } = useIntl();
  const { showModal } = useModal();
  const { data: network, isLoading } = useGetNetworkQuery(
    { slug },
    {
      select: (data) => mapToNetwork(data.networks_by_pk),
    }
  );

  if (isLoading) {
    return (
      <Layout display={Display.Flex} background={Background.Base} grow>
        <FireText>Loading...</FireText>
      </Layout>
    );
  }

  if (!network) {
    return <Layout />;
  }

  return (
    <Layout
      name="network-profile__container"
      display={Display.Flex}
      flexDirection={FlexDirection.Column}
      background={Background.Base}
      grow
      padding={{ b: 16 }}
    >
      <ProfileBackdrop imageSrc={network.coverImageUrl} />
      <Layout
        name="network-profile__content"
        margin={{ t: 80 }}
        padding={{ x: 20 }}
      >
        <ProfileHeading image={network.imageUrl} title={network.name} />
        <Layout margin={{ y: 5 }}>
          <FireText>{network.description}</FireText>
        </Layout>
        <FocusGroup id="NETWORK_PROFILE_BUTTONS">
          <Layout name="network-profile__buttons" display={Display.Flex}>
            <Layout margin={{ r: 4 }}>
              <Button
                onEnterPress={() => {
                  showModal(
                    <QRModal
                      path={slug}
                      text={formatMessage({
                        defaultMessage: "Scan to Join",
                      })}
                    />
                  );
                }}
              >
                {formatMessage({ defaultMessage: "Join Membership" })}
              </Button>
            </Layout>
            <Layout margin={{ r: 4 }}>
              <NetworkFollowButton slug={network.slug} />
            </Layout>
            <IconButton
              onEnterPress={() => {
                showModal(<QRModal path={slug} />);
              }}
            >
              <QRCode />
            </IconButton>
          </Layout>
        </FocusGroup>
      </Layout>
      <Layout margin={{ y: 10 }}>
        <HighlightsRail slug={network.slug} />
      </Layout>
      <Layout margin={{ y: 10 }}>
        <NetworkRecentShows slug={network.slug} />
      </Layout>
      <Layout margin={{ y: 10 }}>
        <NetworkSeries slug={network.slug} />
      </Layout>
      <Layout margin={{ y: 10 }}>
        <NetworkUpcomingShows slug={network.slug} />
      </Layout>
      <Layout margin={{ y: 10 }}>
        <NetworkCastAndCrew slug={network.slug} />
      </Layout>
    </Layout>
  );
};
