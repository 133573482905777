import {
  FetchNextPageOptions,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
} from "@tanstack/react-query";
import { RequestInit } from "graphql-request/dist/types.dom";
import flatMap from "lodash/flatMap";
import uniqBy from "lodash/uniqBy";
import { useMemo } from "react";

import {
  useInfiniteQueryOptions,
  UseInfiniteQueryOptionsProps,
} from "src/hooks/infinite-query/use-infinite-query-options";

export type UseInfiniteHookReturnValue<TItem> = {
  items: TItem[];
  hasMore: boolean;
  isFetchingNextPage: boolean;
  fetchNextPage: (options?: FetchNextPageOptions) => Promise<unknown>;
};

export type InfiniteHook<TPage, TVars> = (
  variables: TVars,
  options: UseInfiniteQueryOptions<TPage, unknown, TPage>,
  headers?: RequestInit["headers"]
) => UseInfiniteQueryResult<TPage>;

export type PaginationVars = { offset: number; limit: number };

type Props<TVars extends PaginationVars, TPage, TItem> = {
  variables: TVars;
  hook: InfiniteHook<TPage, TVars>;
  optionsProps: UseInfiniteQueryOptionsProps<TPage, TItem>;
};

const trueFunc = () => true;

export const useInfiniteQuery = <TPage, TItem, TVars extends PaginationVars>({
  hook,
  variables,
  optionsProps,
}: Props<TVars, TPage, TItem>) => {
  const options = useInfiniteQueryOptions<TPage, TItem>(optionsProps);
  const { data, hasNextPage, isFetchingNextPage, fetchNextPage, isFetched } =
    hook(variables, options);

  const items: TItem[] = useMemo(() => {
    const normalizedItems = flatMap<TPage, TItem>(
      data?.pages || [],
      optionsProps.normalizePageData
    );
    const uniqueItems = uniqBy(normalizedItems, optionsProps.keyExtractor);
    const filteredItems = uniqueItems.filter((item) =>
      (optionsProps.filter || trueFunc)(item)
    );
    const maxItems = optionsProps.maxCount || Number.POSITIVE_INFINITY;

    return filteredItems.length > maxItems
      ? filteredItems.slice(0, maxItems)
      : filteredItems;
  }, [
    data?.pages,
    optionsProps.normalizePageData,
    optionsProps.keyExtractor,
    optionsProps.maxCount,
    optionsProps.filter,
  ]);

  const hasMore = useMemo(
    () =>
      Boolean(hasNextPage) &&
      items.length < (optionsProps.maxCount || Number.POSITIVE_INFINITY),
    [hasNextPage, items.length, optionsProps.maxCount]
  );

  return {
    items,
    hasMore,
    fetchNextPage,
    isFetchingNextPage,
    isFetched,
  };
};
