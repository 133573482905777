import { QueryKey, useInfiniteQuery } from "@tanstack/react-query";
import flatMap from "lodash/flatMap";
import uniqBy from "lodash/uniqBy";
import { useMemo } from "react";
import { PaginationVars } from "./infinite-query/use-infinite-query";
import { maxDisplayedItems, pageSize } from "src/utils/pagination-constants";
import { useInfiniteQueryOptions } from "./infinite-query/use-infinite-query-options";
import { infinitizeGeneratedQueryKey } from "src/gql/infinite-generated-query-key";

export type CreateFetcherInput<
  TData,
  FirstQuery,
  FirstQueryVariables,
  SecondQuery,
  SecondQueryVariables
> = {
  name: string;
  fetcherState: FetcherState;
  firstQueryFetcher: (
    params: PaginationVars & FirstQueryVariables
  ) => Promise<FirstQuery>;
  firstQueryNormalizer: (params: Awaited<FirstQuery>) => TData[];
  firstQueryVariables: FirstQueryVariables;
  secondQueryFetcher: (
    params: PaginationVars & SecondQueryVariables
  ) => Promise<SecondQuery>;
  secondQueryNormalizer: (params: Awaited<SecondQuery>) => TData[];
  secondQueryVariables: SecondQueryVariables;
};

export type FetcherState = {
  firstQueryDone: boolean;
  secondQueryOffset: number;
};

export const createFetcher = <
  TData,
  FirstQuery,
  FirstQueryVariables,
  SecondQuery,
  SecondQueryVariables
>(
  createFetcherInput: CreateFetcherInput<
    TData,
    FirstQuery,
    Omit<FirstQueryVariables, "offset" | "limit">,
    SecondQuery,
    Omit<SecondQueryVariables, "offset" | "limit">
  >
) => {
  const queryKey = [
    createFetcherInput.name,
    createFetcherInput.firstQueryVariables,
    createFetcherInput.secondQueryVariables,
  ];

  return {
    queryKey,
    fetcher: async (
      paginationVariables: PaginationVars
    ): Promise<{ data: TData[]; pageParam: number }> => {
      const {
        fetcherState,
        firstQueryFetcher,
        firstQueryVariables,
        firstQueryNormalizer,
        secondQueryFetcher,
        secondQueryVariables,
        secondQueryNormalizer,
      } = createFetcherInput;

      let entities: TData[] = [];

      if (!fetcherState.firstQueryDone) {
        const firstQueryData = await firstQueryFetcher({
          ...firstQueryVariables,
          ...paginationVariables,
        });

        entities = firstQueryNormalizer(firstQueryData);

        if (entities.length < pageSize) {
          fetcherState.firstQueryDone = true;
        }
      }

      if (fetcherState.firstQueryDone) {
        const limit = pageSize - entities.length;
        const secondQueryPaginationVariables = {
          limit,
          offset: fetcherState.secondQueryOffset,
        };

        const secondQueryData = await secondQueryFetcher({
          ...secondQueryVariables,
          ...secondQueryPaginationVariables,
        });

        const secondQueryEntities = secondQueryNormalizer(secondQueryData);

        entities = [...entities, ...secondQueryEntities];

        fetcherState.secondQueryOffset += secondQueryEntities.length;
      }

      return {
        data: entities,
        pageParam: paginationVariables.offset,
      };
    },
  };
};

export const useStitchedInfiniteQuery = <DataType>({
  fetcher,
  queryKey,
  keyExtractor,
}: {
  fetcher: (paginationVariables: PaginationVars) => Promise<{
    data: DataType[];
    pageParam: number;
  }>;
  queryKey: QueryKey;
  keyExtractor: (item: DataType) => string;
}) => {
  const infiniteQueryOptions = useInfiniteQueryOptions({
    pageSize,
    normalizePageData: (page: { data: DataType[] }) => page.data || [],
    keyExtractor,
  });

  const {
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    isFetched,
    ...result
  } = useInfiniteQuery(
    infinitizeGeneratedQueryKey(queryKey),
    ({ pageParam }) => {
      const offset = pageParam?.offset || 0;

      return fetcher({ offset, limit: pageSize });
    },
    {
      ...infiniteQueryOptions,
    }
  );

  const items = useMemo(() => {
    if (!result.data) {
      return [];
    }

    const normalizedItems = flatMap(
      result.data.pages || [],
      (el) => el?.data || []
    );
    const uniqueItems = uniqBy(normalizedItems, keyExtractor);
    const filteredItems = uniqueItems.filter(Boolean);
    const maxItems = maxDisplayedItems || Number.POSITIVE_INFINITY;

    return filteredItems.length > maxItems
      ? filteredItems.slice(0, maxItems)
      : filteredItems;
  }, [keyExtractor, result.data]);

  return {
    hasMore: Boolean(hasNextPage),
    fetchNextPage,
    isFetchingNextPage,
    isFetched,
    items: items as DataType[],
  };
};
