import { mapToShow } from "src/atoms/content-feed/mappers";
import { Show } from "src/atoms/content-feed/types";
import {
  GetAppearedInChatsQuery,
  GetAppearedInChatsQueryVariables,
  useInfiniteGetAppearedInChatsQuery,
} from "src/gql/generated/client-cached.graphql";
import { useInfiniteQuery } from "src/hooks/infinite-query/use-infinite-query";
import {
  maxDisplayedItems,
  pageSize,
  paginationQueryVariables,
} from "src/utils/pagination-constants";
import { slugExtractor } from "src/utils/ulid-extractor";

export function useAppearedIn(userID: string) {
  return useInfiniteQuery<
    GetAppearedInChatsQuery,
    Show,
    GetAppearedInChatsQueryVariables
  >({
    hook: useInfiniteGetAppearedInChatsQuery,
    variables: {
      user_uid: userID,
      offset: paginationQueryVariables.offset,
      limit: paginationQueryVariables.limit,
    },
    optionsProps: {
      normalizePageData: (page: GetAppearedInChatsQuery) =>
        page.chats.map((chat) => mapToShow({ chat, overrideData: null })),
      keyExtractor: slugExtractor,
      pageSize,
      maxCount: maxDisplayedItems,
    },
  });
}
