import { FlatList, View } from "react-native";
import Feed from "../Feed";
import { useFocusable } from "@noriginmedia/norigin-spatial-navigation";
import { VideoFeedEntity } from "src/atoms/content-feed/types";
import { ActiveIndexes } from "src/atoms/content-feed/activeIndexes";

interface Props {
  sliderRef: FlatList | null;
  item: VideoFeedEntity;
  currentIndex: number;
  activeIndexes: ActiveIndexes;
}

export const RelatedClipItem: React.FC<Props> = ({
  sliderRef,
  item,
  currentIndex,
  activeIndexes,
}) => {
  const { ref, updateAllLayouts } = useFocusable({
    onFocus: () => {
      sliderRef?.scrollToIndex({ index: currentIndex });
    },
    onArrowPress: (direction, props) => {
      // Notes: This lib seems not working well with virtualizer
      // updateAllSpatialLayouts() => not work ???
      // patch: correct layout manually
      //   const focusableComponents = SpatialNavigation.focusableComponents;
      //   const focusableItems = Object.values(focusableComponents).filter(
      //     (n: any) =>
      //       n.parentFocusKey.startsWith("slider_") ||
      //       n.focusKey.startsWith("slider_")
      //   );
      //   focusableItems.forEach((focusableItem: any) => {
      //     const { x, y, width, height, left, top } =
      //       focusableItem.node.getBoundingClientRect();
      //     focusableItem.layout = {
      //       ...focusableItem.layout,
      //       x,
      //       y,
      //       width,
      //       height,
      //       left,
      //       top,
      //     };
      //   });
      updateAllLayouts();
      return true;
    },
  });

  return (
    <View ref={ref} className="grow shrink w-screen h-screen">
      <Feed feedEntity={item} active={item.ulid === activeIndexes.item?.ulid} />
    </View>
  );
};
