import { QueryKey } from "@tanstack/react-query";

/**
 * infinitizes autogenerated `useQuery.getKey()`
 *
 * this exists since the autogenerated `useInfiniteQuery` doesn't expose a
 * `.getKey()` method so we fallback to using the autogenerated `useQuery.getKey()`
 */
export const infinitizeGeneratedQueryKey = ([
  queryName,
  ...queryVars
]: QueryKey): QueryKey => [`${queryName}.infinite`, ...queryVars];
