import { useClient } from "./use-client";

/**
 * @deprecated meant to be used by generated code.
 */
export const useFetchFreshQuery = <TData, TVariables>(
  query: string,
  options?: RequestInit["headers"]
) => {
  const client = useClient();

  return async (variables?: TVariables) =>
    client.request<TData, TVariables>(query, variables, options);
};
