import { useNetworkSeries } from "./use-series";
import { SeriesItem } from "./Item";
import { ProfileRail } from "src/screens/Profile/components/Rail";
import { useIntl } from "react-intl";

interface Props {
  slug: string;
}

export const NetworkSeries: React.FC<Props> = ({ slug }) => {
  const { formatMessage } = useIntl();
  const {
    items: series,
    hasMore,
    isFetchingNextPage,
    fetchNextPage,
    isFetched,
  } = useNetworkSeries(slug);

  if (isFetched && !series.length) {
    return null;
  }

  return (
    <ProfileRail
      title={formatMessage({
        defaultMessage: "Series",
      })}
      canLoadMore={!!series.length && hasMore && !isFetchingNextPage}
      onLoadMore={fetchNextPage}
    >
      {!series.length && <SeriesItem series={null} loading />}
      {series.map((s) => (
        <SeriesItem key={`SeriesRailItem-${s.slug}`} series={s} />
      ))}
    </ProfileRail>
  );
};
