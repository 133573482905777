import { DateTime, Interval } from "luxon";
import { defineMessage } from "react-intl";

export function formatTime(date: Date) {
  return date.toLocaleString("en-us", {
    year: "numeric",
    month: "long",
    day: "numeric",
    timeZone: "utc",
    hour: "numeric",
    minute: "numeric",
  });
}

/**
 * Returns relative time I.E. "In 5 days"
 * @param date - ISO Format of time;
 * @returns string
 */
export function formatRelativeTime(date: string) {
  const dt = DateTime.fromISO(date);
  return dt.toRelativeCalendar();
}

/**
 * Returns a relative time if Time is tomorrow, else just returns time;
 * @param date - ISO Format of time.
 */
export function formatTomorrowOrTime(date: string) {
  const tomorrow = DateTime.now().plus({ day: 1 });
  const dt = DateTime.fromISO(date);

  if (dt.startOf("day").day === tomorrow.startOf("day").day) {
    return `${defineMessage({
      defaultMessage: "Tomorrow",
      id: "FormatTomorrowOrTime",
    })} ${dt.toLocaleString(DateTime.TIME_SIMPLE)}`;
  } else {
    return dt.toLocaleString(DateTime.DATETIME_SHORT);
  }
}
