import {
  GetNetworkReplayChatsQuery,
  GetNetworkReplayChatsQueryVariables,
  useInfiniteGetNetworkReplayChatsQuery,
} from "src/gql/generated/client-cached.graphql";
import {
  maxDisplayedItems,
  paginationQueryVariables,
} from "src/utils/pagination-constants";
import { Show } from "src/atoms/content-feed/types";
import { ulidExtractor } from "src/utils/ulid-extractor";
import { mapToShow } from "src/atoms/content-feed/mappers";
import { useInfiniteQuery } from "src/hooks/infinite-query/use-infinite-query";
import { RecentShowItem } from "./card";
import { ProfileRail } from "src/screens/Profile/components/Rail";
import { useIntl } from "react-intl";

interface Props {
  slug: string;
}

const NetworkRecentShows: React.FC<Props> = ({ slug }) => {
  const { formatMessage } = useIntl();
  const {
    items: shows,
    hasMore,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery<
    GetNetworkReplayChatsQuery,
    Show,
    GetNetworkReplayChatsQueryVariables
  >({
    hook: useInfiniteGetNetworkReplayChatsQuery,
    optionsProps: {
      keyExtractor: ulidExtractor,
      pageSize: paginationQueryVariables.limit,
      maxCount: maxDisplayedItems,
      normalizePageData: (page) =>
        page.presentable_relationships.reduce<Show[]>((acc, item) => {
          if (item.child?.presentable?.chats) {
            acc.push(
              mapToShow({
                chat: item.child.presentable.chats,
                overrideData: null,
              })
            );
          }

          return acc;
        }, []),
    },
    variables: {
      ...paginationQueryVariables,
      slug,
    },
  });

  return (
    <ProfileRail
      title={formatMessage({
        defaultMessage: "Recent Shows",
      })}
      canLoadMore={!!shows.length && hasMore && !isFetchingNextPage}
      onLoadMore={fetchNextPage}
    >
      {!shows.length && <RecentShowItem show={null} loading />}
      {shows.map((show) => (
        <RecentShowItem key={`RecentShowRail-${show.ulid}`} show={show} />
      ))}
    </ProfileRail>
  );
};

export default NetworkRecentShows;
