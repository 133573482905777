import { Fireside } from "src/components/Icons";
import {
  AuditoriumMode,
  useAuditorium,
} from "src/screens/Auditorium/hooks/use-auditorium";
import {
  Position,
  Display,
  AlignItems,
  CardSize,
  Aspect,
  FireImage,
  FireText,
  TextType,
  Color,
  Layout,
  Card,
  Background,
  JustifyContent,
} from "src/ui";

interface Props {
  icon: string;
  episodeTitle: string;
  seriesTitle?: string | null;
  description: string;
  active?: boolean;
}

export const ChannelCardContent: React.FC<Props> = ({
  icon,
  episodeTitle,
  seriesTitle,
  description,
  active,
}) => {
  const cardProps = {
    display: Display.Flex,
    background: Background.Alt,
    fullWidth: true,
    fullHeight: true,
    alignItems: AlignItems.Center,
    justifyContent: JustifyContent.Center,
  };
  const { mode } = useAuditorium();
  const embedded = mode === AuditoriumMode.Embedded;
  const visibility = active && embedded ? "opacity-100" : "opacity-0";

  return (
    <Layout
      padding={16}
      position={Position.Absolute}
      attachBottom
      attachLeft
      className={`w-2/3 ${visibility} transition-all duration-1000`}
    >
      <Layout
        display={Display.Flex}
        alignItems={AlignItems.Center}
        margin={{ b: 8 }}
      >
        <Card size={CardSize.Small} aspect={Aspect.Square}>
          <Layout {...cardProps}>
            <FireImage src={icon} fill>
              <Layout className="w-80">
                <Fireside fill="#7e7a81" />
              </Layout>
            </FireImage>
          </Layout>
        </Card>
        <Layout margin={{ l: 4 }} fullWidth>
          {seriesTitle && (
            <FireText type={TextType.Micro} color={Color.Alt}>
              {seriesTitle}
            </FireText>
          )}
          <FireText type={TextType.H3} lineClamp={1}>
            {episodeTitle}
          </FireText>
        </Layout>
      </Layout>
      <FireText color={Color.Alt} lineClamp={3}>
        {description}
      </FireText>
    </Layout>
  );
};
