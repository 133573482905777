import { useAtomValue } from "jotai";
import flatten from "lodash/flatten";
import { useEffect, useMemo } from "react";

import { useInfiniteGetVerticalFeedEntitiesQuery } from "../../../gql/generated/client-fresh.graphql";

import {
  mapQueryItemToFeedEntity,
  VideoFeedEntity,
} from "../../../atoms/content-feed/types";
import { useSession } from "src/core/auth/use-session";

type Props = {
  activeIndex: number;
  children: (childrenProps: { entities: VideoFeedEntity[] }) => JSX.Element;
};

export const ContentFeedVerticalDataProvider = ({
  activeIndex,
  children,
}: Props) => {
  const { deviceID } = useSession();

  const { data, fetchNextPage, hasNextPage, isFetching } =
    useInfiniteGetVerticalFeedEntitiesQuery(
      {
        device_id: deviceID,
      },
      {
        getNextPageParam: (fetchedData) =>
          fetchedData.feedGetClips && fetchedData.feedGetClips.length > 0
            ? { device_id: deviceID }
            : undefined,
        // @ts-ignore
        // TODO
        select: (fetchedData) => ({
          pageParams: fetchedData.pageParams,
          pages: fetchedData.pages.map(
            (page) =>
              (page.feedGetClips || [])
                .map((item) => mapQueryItemToFeedEntity(item))
                .filter(Boolean) as VideoFeedEntity[]
          ),
        }),
        onSuccess(data) {
          console.log("useInfiniteGetVerticalFeedEntitiesQuery", data);
        },
      }
    );

  const entities = useMemo(() => flatten(data?.pages || []), [data?.pages]);

  useEffect(() => {
    const delta = entities.length - activeIndex;

    if (delta < 3 && hasNextPage && !isFetching) {
      fetchNextPage();
    }
  }, [activeIndex, entities.length, fetchNextPage, hasNextPage, isFetching]);

  if (!entities || entities.length === 0) {
    return null;
  }

  return children({ entities });
};
