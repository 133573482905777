export interface LoadingProps {
  loading?: boolean;
}

export enum Aspect {
  Square = "square",
  Wide = "wide",
}

export enum BorderRadius {
  None = "none",
  Small = "small",
  Medium = "medium",
  Large = "large",
  XL = "xl",
  XXXL = "3xl",
  Full = "full",
}

export enum Color {
  Base = "white",
  Alt = "fs-gray-7",
  Overlay = "white",
}

export enum Overflow {
  Auto = "overflow-auto",
  Visible = "overflow-visible",
  Scroll = "overflow-scroll",
  ScrollX = "overflow-x-scroll",
  ScrollY = "overflow-y-scroll",
  Hidden = "overflow-hidden",
}

export type Opacity = 10 | 20 | 30 | 40 | 50 | 60 | 80 | 90 | 100;

export type SpacingOptions = 1 | 2 | 4 | 5 | 8 | 10 | 12 | 16 | 20 | 40 | 80;

export interface SpacingObj {
  x?: SpacingOptions;
  y?: SpacingOptions;
  t?: SpacingOptions;
  b?: SpacingOptions;
  l?: SpacingOptions;
  r?: SpacingOptions;
}

export type Spacing = SpacingOptions | SpacingObj;
