import { mapToShow } from "src/atoms/content-feed/mappers";
import { Show } from "src/atoms/content-feed/types";
import { useInfiniteGetNetworkUpcomingChatsQuery } from "src/gql/generated/client-cached.graphql";
import {
  GetNetworkUpcomingChatsQuery,
  GetNetworkUpcomingChatsQueryVariables,
} from "src/gql/generated/sdk-cached.graphql";
import { useInfiniteQuery } from "src/hooks/infinite-query/use-infinite-query";
import {
  paginationQueryVariables,
  maxDisplayedItems,
} from "src/utils/pagination-constants";
import { ulidExtractor } from "src/utils/ulid-extractor";

export function useNetworkUpcomingShows(slug: string) {
  return useInfiniteQuery<
    GetNetworkUpcomingChatsQuery,
    Show,
    GetNetworkUpcomingChatsQueryVariables
  >({
    hook: useInfiniteGetNetworkUpcomingChatsQuery,
    optionsProps: {
      keyExtractor: ulidExtractor,
      pageSize: paginationQueryVariables.limit,
      maxCount: maxDisplayedItems,
      normalizePageData: (page) =>
        page.presentable_relationships.reduce<Show[]>((acc, item) => {
          if (item.child?.presentable?.chats) {
            acc.push(
              mapToShow({
                chat: item.child.presentable.chats,
                overrideData: null,
              })
            );
          }

          return acc;
        }, []),
    },
    variables: {
      ...paginationQueryVariables,
      slug,
    },
  });
}
