import { DocumentNode, print } from "graphql";
import ky from "ky-universal";

import { getQueryName } from "../../get-query-name";

export const fetcher = async <V>(doc: DocumentNode, variables?: V, options?: RequestInit) => {
  const query = print(doc);
  const operationName = getQueryName(doc);

  const response = await ky.post("https://graph.firesidechat.com/v1/graphql", {
    json: {
      operationName,
      query,
      variables
    },
    ...options
  });

  const result = await response.json<{ data: any; errors: any }>();

  if (result.errors) {
    throw new Error(JSON.stringify(result.errors));
  }

  return result.data;
};
