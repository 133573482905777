import { Text as RNText } from "react-native";
import { PropsWithChildren } from "react";
import classNames from "classnames";
import { Color, TextTransform, TextType } from "src/ui";

export interface TextProps {
  color?: Color;
  type?: TextType;
  transform?: TextTransform;
  lineClamp?: number;
}

export const FireText: React.FC<PropsWithChildren<TextProps>> = ({
  children,
  color = Color.Base,
  type = TextType.Base,
  transform,
  lineClamp,
}) => {
  const heading =
    type === TextType.H1 ||
    type === TextType.H2 ||
    type === TextType.H3 ||
    type === TextType.H4;
  const classes = classNames({
    ["text-body-copy-gray"]: type === TextType.Base && color === Color.Base,
    [`text-${color}`]: true,
    //400
    ["font-normal"]: type === TextType.Base,
    //600
    ["font-semibold"]: type === TextType.H3 || type === TextType.H4,
    //700
    ["font-bold	"]: type === TextType.Micro || type === TextType.Subtitle,
    //18px
    ["text-lg"]: type === TextType.Micro,
    //24px
    ["text-2xl"]: type === TextType.Base || type == TextType.Subtitle,
    //30px
    ["text-3xl"]: type === TextType.H4 || type === TextType.Large,
    //48px
    ["text-5xl"]: type === TextType.H3,
    [transform || ""]: !!transform,
    ["font-work-sans"]: type === TextType.Base,
    ["font-open-sans"]: type !== TextType.Base,
    [`line-clamp-1`]: lineClamp === 1,
    [`line-clamp-2`]: lineClamp === 2,
    [`line-clamp-3`]: lineClamp === 3,
    [`line-clamp-4`]: lineClamp === 4,
    [`line-clamp-5`]: lineClamp === 5,
    [`line-clamp-6`]: lineClamp === 6,
    ["leading-tight"]: heading,
  });
  return <RNText className={classes}>{children}</RNText>;
};
