import React from "react";
import { ThemeProvider } from "../config";
import Navigation from "../navigation";
import { Provider } from "react-redux";
import store from "../store";
import { AuthProvider } from "../contexts/FirebaseAuthContext";
import { ToastProvider } from "react-native-toast-notifications";
import { Provider as PaperProvider } from "react-native-paper";
import theme from "../theme";
import { RecoilRoot } from "recoil";
import RecoilNexus from "recoil-nexus";
import { RecoilDevTools } from "recoil-gear";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ReactQueryDevtools from "../components/ReactQueryDevtools";
import { ModalManager, PortalProvider } from "src/ui";
import { init } from "@noriginmedia/norigin-spatial-navigation";
import { IntlProvider } from "react-intl";
import { ClientProvider } from "src/hooks/use-client";

const App = () => {
  init({
    debug: false,
    visualDebug: false,
  });

  return (
    <RecoilRoot>
      <RecoilNexus />
      <RecoilDevTools />
      <Provider store={store}>
        <IntlProvider locale="en-US" onError={() => {}}>
          <AuthProvider>
            <ThemeProvider>
              <PaperProvider theme={theme}>
                <ToastProvider>
                  <PortalProvider>
                    <ClientProvider>
                      <QueryClientProvider
                        client={
                          new QueryClient({
                            defaultOptions: {
                              queries: {
                                refetchOnWindowFocus: false,
                                staleTime: 60 * 1000,
                              },
                            },
                          })
                        }
                      >
                        <Navigation />
                        {/* <ReactQueryDevtools /> */}
                        <ModalManager />
                      </QueryClientProvider>
                    </ClientProvider>
                  </PortalProvider>
                </ToastProvider>
              </PaperProvider>
            </ThemeProvider>
          </AuthProvider>
        </IntlProvider>
      </Provider>
    </RecoilRoot>
  );
};

export default App;
