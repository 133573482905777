import { atom, useAtom } from "jotai";
import { Maybe } from "src/gql/generated/client-fresh.graphql";
import { hasHasuraClaim } from "./hasura-claim";
import { IdTokenResult, User } from "firebase/auth";
import { AuthUser } from "src/contexts/FirebaseAuthContext";
type AuthHeaders = {
  "x-hasura-user-id": string;
  authorization: string;
};

const localeAtom = atom("en-US");
const anonymousUidAtom = atom<string>("");
const deviceIdAtom = atom((get) => get(anonymousUidAtom));
const userAtom = atom<AuthUser | null>(null);

const internalFirebaseIdTokenAtom = atom<Maybe<IdTokenResult>>(null);
const previousFirebaseIdTokenAtom = atom<Maybe<IdTokenResult>>(null);
const firebaseIdTokenAtom = atom<
  Maybe<IdTokenResult>,
  [Maybe<IdTokenResult>],
  unknown
>(
  (get) => get(internalFirebaseIdTokenAtom),
  (get, set, newToken) => {
    const currentToken = get(internalFirebaseIdTokenAtom);

    set(previousFirebaseIdTokenAtom, currentToken);
    set(internalFirebaseIdTokenAtom, newToken);
  }
);

const headersCache = new Map<string, AuthHeaders>();
const getHeaders = (uid: string, token: string) => {
  const key = `${uid}-${token}`;

  const headers = headersCache.get(key);

  if (headers) {
    return headers;
  }

  const newHeaders = {
    "x-hasura-user-id": uid,
    authorization: `Bearer ${token}`,
  };

  headersCache.clear();
  headersCache.set(key, newHeaders);

  return newHeaders;
};

const authHeadersAtom = atom<Maybe<AuthHeaders>>((get) => {
  const idToken = get(firebaseIdTokenAtom);
  const user = get(userAtom);
  const uid = user?.id;
  const tokenHasHasuraClaim = hasHasuraClaim(idToken);

  if (!tokenHasHasuraClaim || !uid || !idToken) {
    return null;
  }

  return getHeaders(uid, idToken.token);
});

export function useSession() {
  const [_anonymousID, useSetAnonymousId] = useAtom(anonymousUidAtom);
  const [deviceID] = useAtom(deviceIdAtom);
  const [user, setUser] = useAtom(userAtom);
  const [locale] = useAtom(localeAtom);
  const [headers] = useAtom(authHeadersAtom);
  const [_token, setToken] = useAtom(firebaseIdTokenAtom);

  return {
    headers,
    deviceID,
    user,
    locale,
    setDeviceID: useSetAnonymousId,
    setUser,
    setToken,
  };
}
