/* eslint-disable no-param-reassign */
/* eslint-disable class-methods-use-this */
import { EventType } from "./types";
// import { captureException } from "@/common/exception/capture-exception";
// import { FsError } from "@/common/exception/fs-error";
import { EntityRole } from "./types";

import {
  ChatCreate,
  ChatMembershipJoin,
  ChatMembershipKick,
  ChatMembershipMicControl,
  ChatMembershipReaction,
  ChatMembershipRoleChange,
  ChatMembershipStageChange,
  ChatUpdate,
  ChatVideoStart,
  ChatVideoStop,
  Event
} from "./EventLog";
import { ReplayState } from "./types";

class ReplayEventHandler {
  applyEvent = (state: ReplayState, event: Event) => {
    try {
      switch (event.type) {
        case EventType.CHAT_MEMBERSHIP_JOIN: {
          this.handleChatMembershipJoin(state, event as ChatMembershipJoin);
          break;
        }

        case EventType.CHAT_MEMBERSHIP_MIC_CONTROL: {
          this.handleChatMembershipMicControl(state, event as ChatMembershipMicControl);
          break;
        }

        case EventType.CHAT_CREATE:

        // eslint-disable-next-line no-fallthrough
        case EventType.CHAT_UPDATE: {
          this.handleChatUpsert(state, event as ChatCreate | ChatUpdate);
          break;
        }

        case EventType.CHAT_MEMBERSHIP_REACTION: {
          this.handleChatMembershipReaction(state, event as ChatMembershipReaction);
          break;
        }

        case EventType.CHAT_MEMBERSHIP_SPOKE: {
          state.activeSpeakerUserId = event.acting_user_uid;
          break;
        }

        case EventType.CHAT_MEMBERSHIP_STAGE_CHANGE: {
          this.handleChatMembershipStageChange(state, event as ChatMembershipStageChange);
          break;
        }

        case EventType.CHAT_MEMBERSHIP_ROLE_CHANGE: {
          this.handleRoleChange(state, (event as ChatMembershipRoleChange).data.affecting_user_uid, (event as ChatMembershipRoleChange).data.role);
          break;
        }

        case EventType.CHAT_VIDEO_START: {
          state.videoSharingUserIds.add((event as ChatVideoStart).acting_user_uid);
          break;
        }

        case EventType.CHAT_VIDEO_STOP: {
          state.videoSharingUserIds.delete((event as ChatVideoStop).data.affecting_user_uid);
          break;
        }

        case EventType.CHAT_MEMBERSHIP_KICK: {
          this.removeUser(state, (event as ChatMembershipKick).data.affecting_user_uid);
          break;
        }

        case EventType.CHAT_MEMBERSHIP_LEAVE: {
          this.removeUser(state, event.acting_user_uid);
          break;
        }

        case EventType.CHAT_END: {
          break;
        }

        default: {
          //   captureException(new FsError("unhandled replay event"), {
          //     event
          //   });
        }
      }
    } catch (e) {
      //   captureException(new FsError(`error applying replay event ${event.type}`, e), { event });
    }
  };

  private handleChatMembershipJoin = (state: ReplayState, event: ChatMembershipJoin) => {
    const {
      acting_user_uid: uid,
      data: { role },
      annotations: { reaction_emoji_ulid: emojiUlid, is_on_stage: isOnStage }
    } = event;

    state.reactionsByUserId.set(uid, emojiUlid);

    this.prependParticipants(state, uid);

    if (isOnStage) {
      state.onStageUserIds.add(uid);
      state.mutedUserIds.delete(uid);
    }

    this.handleRoleChange(state, uid, role);
  };

  private handleChatMembershipMicControl = (state: ReplayState, event: ChatMembershipMicControl) => {
    const { affecting_user_uid: uid, is_muted: muted } = event.data;

    if (muted) {
      state.mutedUserIds.add(uid);
    } else {
      state.mutedUserIds.delete(uid);
    }

    if (state.activeSpeakerUserId === uid) {
      state.activeSpeakerUserId = null;
    }
  };

  private handleChatUpsert = (state: ReplayState, event: ChatCreate | ChatUpdate) => {
    const {
      acting_user_uid: uid,
      data: { headline, producer_uids: producerUids, cohost_uids: cohostUids }
    } = event;

    if (headline) {
      state.activeHeadline = headline;
    }

    producerUids?.forEach((producerUid) => {
      this.handleRoleChange(state, producerUid, EntityRole.MODERATOR);
    });

    cohostUids?.forEach((cohostUid) => {
      this.handleRoleChange(state, cohostUid, EntityRole.HOST);
    });

    if (event.type === "chatCreate") {
      this.prependParticipants(state, uid);

      state.mutedUserIds.delete(uid);

      state.onStageUserIds.add(uid);

      this.handleRoleChange(state, uid, EntityRole.HOST);
    }
  };

  private handleChatMembershipReaction = (state: ReplayState, event: ChatMembershipReaction) => {
    const { acting_user_uid: uid, annotations } = event;

    if (annotations?.reaction_emoji_ulid) {
      state.reactionsByUserId.set(uid, annotations.reaction_emoji_ulid);

      this.prependParticipants(state, uid);
    }
  };

  private handleChatMembershipStageChange = (state: ReplayState, event: ChatMembershipStageChange) => {
    const { affecting_user_uid: uid, is_on_stage: isOnStage } = event.data;

    if (isOnStage) {
      state.onStageUserIds.add(uid);

      state.mutedUserIds.delete(uid);
    } else {
      state.onStageUserIds.delete(uid);

      state.mutedUserIds.delete(uid);

      state.videoSharingUserIds.delete(uid);

      if (state.activeSpeakerUserId === uid) {
        state.activeSpeakerUserId = null;
      }
    }
  };

  private handleRoleChange = (state: ReplayState, uid: string, role: EntityRole | null) => {
    if (!role) {
      return;
    }

    state.rolesByUserId.set(uid, role);
  };

  private removeUser = (state: ReplayState, uid: string) => {
    state.mutedUserIds.delete(uid);
    state.onStageUserIds.delete(uid);
    state.participantUserIds = state.participantUserIds.filter((participantUid) => participantUid !== uid);
    state.reactionsByUserId.delete(uid);
    state.videoSharingUserIds.delete(uid);

    if (state.activeSpeakerUserId === uid) {
      state.activeSpeakerUserId = null;
    }
  };

  private prependParticipants = (state: ReplayState, uid: string) => {
    state.participantUserIds = [uid, ...state.participantUserIds.filter((participantUid) => participantUid !== uid)];
  };
}

export const replayEventHandler = new ReplayEventHandler();
