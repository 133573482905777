/* eslint-disable no-underscore-dangle */
import { addSeconds, differenceInMilliseconds, isAfter, isBefore } from "date-fns";
import findLast from "lodash/findLast";
import findLastIndex from "lodash/findLastIndex";
import { nanoid } from "nanoid";
import hash from "object-hash";

import { EventType } from "./types";
// import { captureException } from "@/common/exception/capture-exception";
// import { FsError } from "@/common/exception/fs-error";
import {
  GetReplayChatEventsWithSnapshotsDocument,
  GetReplayChatEventsWithSnapshotsQuery,
  GetReplayChatEventsWithSnapshotsQueryVariables,
  Maybe
} from "../../../../gql/generated/client-cached.graphql";
import { fetchCachedQuery } from "../../../../gql/fetch-cached-query";
import { EntityRole } from "./types";
import { sortByEntityRoleEnum } from "./types";

import { ReplayShowMember } from "./types";
import { Event, EventLogType, RawEvent, Snapshot } from "./EventLog";
import { replayEventHandler } from "./ReplayEventHandler";
import { ReplayState } from "./types";

const makeInitialState = (): ReplayState => ({
  activeHeadline: "",
  activeSpeakerUserId: null,
  rolesByUserId: new Map(),
  onStageUserIds: new Set(),
  participantUserIds: [],
  videoSharingUserIds: new Set(),
  reactionsByUserId: new Map(),
  mutedUserIds: new Set()
});

type Events = (Snapshot | Event)[];

export class ReplayEventManager {
  private readonly prefetchThresholdMilliseconds = 5000;

  private ulid = "";

  private recordingStartDate = new Date();

  private nextEventIndex = 0;

  private lastTickSeconds = 0;

  private didSeek = false;

  private events: Events = [];

  private seenHashes = new Set<string>();

  private state = makeInitialState();

  private pendingLoadId: Maybe<string> = null;

  private pendingPrefetchId: Maybe<string> = null;

  private didFailPrefetch = false;

  private prefetchCooldown = 0;

  private prefetchDisabled = false;

  private duration = Number.POSITIVE_INFINITY;

  constructor(
    private setAudience: (audience: ReplayShowMember[]) => void,
    private setFortuneCookie: (fortuneCookie: string) => void,
    private setSpeakers: (speakers: ReplayShowMember[]) => void,
    private setActiveSpeaker: (speakers: Maybe<string>) => void
  ) {}

  load = async (ulid: string, tickSeconds: number) => {
    this.ulid = ulid;

    this.lastTickSeconds = tickSeconds;

    if (this.pendingLoadId) {
      this.pendingLoadId = null;
    }

    if (this.pendingPrefetchId) {
      this.pendingPrefetchId = null;
    }

    this.prefetchCooldown = 0;
    this.didFailPrefetch = false;

    const requestId = nanoid();

    this.pendingLoadId = requestId;

    let chatEventsWithSnapshots: GetReplayChatEventsWithSnapshotsQuery["chatEventsWithSnapshots"] | null = null;
    let chatGet: GetReplayChatEventsWithSnapshotsQuery["chats_by_pk"] | null = null;

    const seekTimeMilis = Math.floor(tickSeconds * 1000).toString();

    try {
      const result = await fetchCachedQuery<GetReplayChatEventsWithSnapshotsQuery, GetReplayChatEventsWithSnapshotsQueryVariables>(GetReplayChatEventsWithSnapshotsDocument, {
        chat_ulid: this.ulid,
        fromPreviousSnapshot: true,
        seekTimeMilis
      })();

      chatEventsWithSnapshots = result.chatEventsWithSnapshots;
      chatGet = result.chats_by_pk;
    } catch (e) {
      //   captureException(new FsError("error fetching replay chat events with snapshots", e), { ulid, seekTimeMilis });
    }

    this.pendingLoadId = null;

    if (!chatEventsWithSnapshots || !chatGet) {
      return;
    }

    if (!chatGet.recording_started_at) {
      //   captureException(new FsError("falsy recording_started_at, replay audience & stage might not work"), {
      //     ulid,
      //     seekTimeMilis,
      //     chatGet
      //   });
    }

    this.recordingStartDate = new Date(chatGet.recording_started_at || chatGet.started_at || 0);

    const tickDate = addSeconds(this.recordingStartDate, tickSeconds);

    this.seenHashes.clear();
    this.events = this.queryToEvents(chatEventsWithSnapshots);

    const chatEventSnapshot = this.events.find((item) => item.__typename === "ChatEventSnapshot") as Maybe<Snapshot>;

    if (chatEventSnapshot?.snapshot) {
      this.setStateFromSnapshot(chatEventSnapshot.snapshot);
    } else {
      this.state = makeInitialState();
    }

    let skippedSnapshots = 0;

    const relevantEvents: Event[] = [];

    for (let i = 0; i < this.events.length; i += 1) {
      const item = this.events[i];

      if (item.__typename === "ChatEventSnapshot") {
        skippedSnapshots += 1;
      } else if (isBefore(item.eventDate, tickDate)) {
        relevantEvents.push(item);
      } else {
        break;
      }
    }

    relevantEvents.forEach((event) => {
      replayEventHandler.applyEvent(this.state, event);
    });

    this.nextEventIndex = relevantEvents.length + skippedSnapshots;

    this.updateAtoms();

    this.prefetchDisabled = false;
  };

  private setStateFromSnapshot = (snapshot: NonNullable<Snapshot["snapshot"]>) => {
    this.state = {
      activeHeadline: snapshot.activeHeadline || "",
      activeSpeakerUserId: snapshot.activeSpeakerUserId,
      onStageUserIds: new Set(snapshot.onStageUserIds),
      participantUserIds: [...new Set(snapshot.participantUserIds)],
      reactionsByUserId: new Map(),
      mutedUserIds: new Set(snapshot.mutedUserIds),
      rolesByUserId: new Map(),
      videoSharingUserIds: new Set(snapshot.videoSharingUserIds)
    };

    if (snapshot.reactionsByUserId) {
      Object.entries(snapshot.reactionsByUserId as Record<string, string>).forEach(([key, value]) => {
        this.state.reactionsByUserId.set(key, value);
      });
    }

    if (snapshot.rolesByUserId) {
      Object.entries(snapshot.rolesByUserId).forEach(([key, value]) => {
        this.state.rolesByUserId.set(key, value as EntityRole);
      });
    }
  };

  private updateAtoms = () => {
    const { activeHeadline, activeSpeakerUserId, mutedUserIds } = this.state;

    this.setFortuneCookie(activeHeadline);

    this.updateAudienceAtom();

    this.updateSpeakersAtom();

    this.setActiveSpeaker(mutedUserIds.has(activeSpeakerUserId!) ? null : activeSpeakerUserId);
  };

  private updateSpeakersAtom = () => {
    const newSpeakers = [...this.state.onStageUserIds].map(this.getUserInfoByUid).sort((a, b) => sortByEntityRoleEnum(a.role, b.role));

    this.setSpeakers(newSpeakers);
  };

  private updateAudienceAtom = () => {
    const newAudience = this.state.participantUserIds.map(this.getUserInfoByUid);

    this.setAudience(newAudience);
  };

  private getUserInfoByUid = (uid: string) => ({
    emojiUlid: this.state.reactionsByUserId.get(uid) ?? null,
    muted: this.state.mutedUserIds.has(uid),
    role: this.getUserRole(uid),
    sharingVideo: this.state.videoSharingUserIds.has(uid),
    uid
  });

  private getUserRole = (uid: string) => this.state.rolesByUserId.get(uid) ?? EntityRole.GUEST;

  tick = (tickSeconds: number) => {
    if (this.duration - tickSeconds < this.prefetchThresholdMilliseconds / 1000) {
      this.prefetchDisabled = true;
    }

    const tickDate = addSeconds(this.recordingStartDate, tickSeconds);

    if (!this.pendingLoadId) {
      if (this.didSeek) {
        this.seekTick(tickSeconds, tickDate);
      } else {
        this.doTick(tickSeconds, tickDate);
      }
    }

    this.lastTickSeconds = tickSeconds;
    this.didSeek = false;
  };

  private doTick = (tickSeconds: number, tickDate: Date) => {
    let didApplyEvent = false;

    for (let i = this.nextEventIndex; i < this.events.length; i += 1) {
      const event = this.events[i];

      if (event.__typename === "EventLog") {
        if (isAfter(event.eventDate, tickDate)) {
          this.nextEventIndex = i;

          break;
        }

        replayEventHandler.applyEvent(this.state, event);

        didApplyEvent = true;
      }

      if (i === this.events.length - 1) {
        this.nextEventIndex = this.events.length;
      }
    }

    if (didApplyEvent) {
      this.updateAtoms();
    }

    this.prefetchEvents(tickSeconds, tickDate);
  };

  private seekTick = (tickSeconds: number, tickDate: Date) => {
    if (this.lastTickSeconds < tickSeconds) {
      // seek forward
      const lastEvent = this.getLastEvent();

      if (lastEvent && isBefore(tickDate, lastEvent.eventDate)) {
        this.doTick(tickSeconds, tickDate);
      } else {
        this.load(this.ulid, tickSeconds);
      }
    } else {
      // seek back
      const lastEventBeforeTickIndex = findLastIndex(this.events, (item) => item.__typename === "EventLog" && isBefore(item.eventDate, tickDate));

      if (lastEventBeforeTickIndex === -1) {
        this.load(this.ulid, tickSeconds);
      } else {
        const lastSnapshotIndex = findLastIndex(this.events.slice(0, lastEventBeforeTickIndex), (item) => item.__typename === "ChatEventSnapshot");

        if (lastSnapshotIndex === -1) {
          this.load(this.ulid, tickSeconds);
        } else {
          const { snapshot } = this.events[lastSnapshotIndex] as Snapshot;

          if (snapshot) {
            this.setStateFromSnapshot(snapshot);

            this.nextEventIndex = lastSnapshotIndex + 1;

            this.doTick(tickSeconds, tickDate);
          } else {
            // captureException(new FsError("found snapshot with falsy inner snapshot"), {
            //   ulid: this.ulid,
            //   tickSeconds,
            //   snapshot
            // });

            this.load(this.ulid, tickSeconds);
          }
        }
      }
    }
  };

  setSeeking = (seeking: boolean) => {
    if (seeking) {
      this.didSeek = seeking;
    }
  };

  private prefetchEvents = async (tickSeconds: number, tickDate: Date) => {
    if (this.pendingLoadId || this.pendingPrefetchId || this.prefetchDisabled || this.prefetchCooldown > tickSeconds) {
      return;
    }

    const lastEvent = this.getLastEvent();

    if (!lastEvent) {
      return;
    }

    const millisecondsBeforeLastEvent = differenceInMilliseconds(lastEvent.eventDate, tickDate);

    if (millisecondsBeforeLastEvent > this.prefetchThresholdMilliseconds) {
      return;
    }

    if (this.didFailPrefetch && millisecondsBeforeLastEvent < 0) {
      //   captureException(new FsError("replay prefetch error - last event passed without a pending load or prefetch"), {
      //     millisecondsBeforeLastEvent,
      //     ulid: this.ulid,
      //     tickSeconds,
      //     tickDate,
      //     lastEvent
      //   });

      return;
    }

    const requestId = nanoid();

    this.pendingPrefetchId = requestId;

    let chatEvents: GetReplayChatEventsWithSnapshotsQuery["chatEventsWithSnapshots"] | null = null;

    const seekTimeMilis = Math.floor(tickSeconds * 1000 + Math.max(millisecondsBeforeLastEvent, 0) + 1).toString();

    try {
      const result = await fetchCachedQuery<GetReplayChatEventsWithSnapshotsQuery, GetReplayChatEventsWithSnapshotsQueryVariables>(GetReplayChatEventsWithSnapshotsDocument, {
        chat_ulid: this.ulid,
        fromPreviousSnapshot: false,
        seekTimeMilis
      })();

      const lastEventIndex = findLastIndex(result.chatEventsWithSnapshots, (item) => hash(item) === lastEvent.hash);

      const sliceStart = lastEventIndex === -1 ? 0 : lastEventIndex + 1;

      chatEvents = result.chatEventsWithSnapshots.slice(sliceStart).filter((item) => !this.seenHashes.has(hash(item)));

      this.didFailPrefetch = false;
    } catch (e) {
      //   captureException(new FsError("error prefetching replay events", e), {
      //     ulid: this.ulid,
      //     seekTimeMilis,
      //     tickSeconds,
      //     tickDate,
      //     lastEvent
      //   });

      this.didFailPrefetch = true;
    }

    this.pendingPrefetchId = null;
    this.prefetchCooldown = 0;

    if (!chatEvents) {
      return;
    }

    if (chatEvents.length === 0) {
      // we got no relevant events, try again later
      this.prefetchCooldown = tickSeconds + 3;

      return;
    }

    const fetchedChatEnd = findLastIndex(chatEvents, (event) => "type" in event && event.type === EventType.CHAT_END) !== -1;

    if (fetchedChatEnd) {
      this.prefetchDisabled = true;
    }

    this.events.push(...this.queryToEvents(chatEvents));
  };

  setDuration = (timeSeconds: number) => {
    this.duration = timeSeconds;
  };

  private queryToEvents = (payload: GetReplayChatEventsWithSnapshotsQuery["chatEventsWithSnapshots"]): Events =>
    (payload.filter((item) => "__typename" in item) as (Snapshot | RawEvent)[]).reduce((acc, item) => {
      if (item.__typename === "ChatEventSnapshot") {
        acc.push(item);
      } else {
        const itemHash = hash(item);

        if (!this.seenHashes.has(itemHash)) {
          this.seenHashes.add(itemHash);

          acc.push({
            hash: itemHash,
            ...item,
            type: item.type as EventLogType,
            eventDate: new Date(item.created_at)
          });
        }
      }

      return acc;
    }, [] as Events);

  private getLastEvent = () => findLast(this.events, (item) => item.__typename === "EventLog") as Maybe<Event>;
}
