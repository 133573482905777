import { ReplaySnapshotManager } from "./ReplaySnapshotManager";
import { useQuery } from "@tanstack/react-query";
import ky from "ky-universal";
import noop from "lodash/noop";
import { useEffect } from "react";

// import { captureException } from "@/common/exception/capture-exception";
// import { FsError } from "@/common/exception/fs-error";
import { Maybe, useGetReplaySnapshotsUrlQuery } from "../../../../gql/generated/client-cached.graphql";

import { ReplayEventManager } from "./ReplayEventManager";

type Input = {
  ulid: string;
  startSeconds: number;
  setAudience: ConstructorParameters<typeof ReplaySnapshotManager>[0];
  setFortuneCookie: ConstructorParameters<typeof ReplaySnapshotManager>[1];
  setSpeakers: ConstructorParameters<typeof ReplaySnapshotManager>[2];
  setActiveSpeaker: ConstructorParameters<typeof ReplaySnapshotManager>[3];
  resetAudience: () => void;
  resetFortuneCookie: () => void;
  resetSpeakers: () => void;
  resetActiveSpeaker: () => void;
  /**
   * in content feed, multiple auditorium managers co-exist in parallel
   *
   * normally this isn't an issue, but there is an edge case where
   * the same chat ulid has multiple clips associated to it
   *
   * useQuery (by design) returns the same result reference for the same query key
   *
   * this param prevents unintended internal state overwrites
   */
  additionalKeys?: string[];
};

enum SnapshotsStatus {
  MISSING,
  PRESENT
}

export const useReplayAuditoriumManager = ({
  ulid,
  startSeconds,
  resetActiveSpeaker,
  resetAudience,
  resetFortuneCookie,
  resetSpeakers,
  setActiveSpeaker,
  setAudience,
  setFortuneCookie,
  setSpeakers,
  additionalKeys = []
}: Input) => {
  const { data: snapshotsUrl, isError } = useGetReplaySnapshotsUrlQuery(
    { chat_ulid: ulid },
    {
      select: (data) => data.chatSnapshotURL.snapshot_url
    }
  );

  const { data: replayAuditoriumManager } = useQuery({
    queryKey: ["replay-auditorium-manager", snapshotsUrl, ...additionalKeys],
    queryFn: async () => {
      resetAudience();
      resetFortuneCookie();
      resetSpeakers();
      resetActiveSpeaker();

      let snapshotsStatus = SnapshotsStatus.MISSING;

      if (snapshotsUrl) {
        try {
          const res = await ky.head(`${snapshotsUrl}0.json`);

          if (res.ok) {
            snapshotsStatus = SnapshotsStatus.PRESENT;
          }
        } catch (e) {
          //   captureException(new FsError("error checking replay snapshot status", e), { ulid });
        }
      }

      const setters = [setAudience, setFortuneCookie, setSpeakers, setActiveSpeaker] as const;

      const manager =
        snapshotsStatus === SnapshotsStatus.MISSING
          ? new ReplayEventManager(...setters)
          : new ReplaySnapshotManager(...setters, (e) => {
              // captureException(new FsError("error loading replay snapshots", e), {
              //   ulid
              // });
            });

      return manager;
    },
    enabled: Boolean(snapshotsUrl) || isError,
    staleTime: 0,
    cacheTime: 0
  });

  useEffect(() => {
    if (replayAuditoriumManager) {
      replayAuditoriumManager.load(ulid, startSeconds);
    }

    return noop;
  }, [replayAuditoriumManager, startSeconds, ulid]);

  return { replayAuditoriumManager };
};
